import axios from "axios";
const AxiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

});


// Add a response interceptor
AxiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Handle token expiration
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      window.location.href = "/login"
    }

    return Promise.reject(error);
  }
);



export { AxiosConfig };
