import "./Modal.scss";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { notification } from "antd";
import "antd/dist/antd.css";
import { modal } from "../../../utils/enums";
// import { useState } from "react";

const AddModal = (props: { onClose: any; show: any; rowData: any, getData:any }) => {
  let token = getToken();

  // const [refresh, setRefresh] = useState(false);

  const deleteHandler = () => {
    let id = props.rowData;
    AxiosConfig.delete("invoice/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.status.code === 200) {
          const notify = () => {
            notification.open({
              message: res.data.status.message,
            });
          };
          {
            notify();
          }
          props.getData()
          props.onClose()
        }
      })
      .catch(function (error) {
        console.log(error.response.data);
        const notify = () => {
          notification.open({
            message: "",
            description: error.response.data.status.message,
            style: { color: "red" },
          });
        };
        {
          notify();
        }
      });
  };

  if (!props.show) {
    return null;
  }
  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-body">
          <p className="modal-text">{modal.textForDeleteWarning}</p>
          <div className="button-div">
            <button className="modal-body__button" onClick={deleteHandler}>
              {modal.buttonForDelete}
            </button>
            <button
              className="modal-body__button cancel--button"
              onClick={props.onClose}
            >
              {modal.buttonForCancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
