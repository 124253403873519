import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col,notification, Input } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import AddOverlay from "./AddExpenses";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import EditOverlay from "./EditExpenses";
import { ColumnType } from "antd/lib/table";
import { enumForExpenses } from "../../../utils/enums";
import { convertCurrency, replaceCurrencyString } from "../../../utils/util";
import { useSelector } from "react-redux";

type ExpenseItem = {
  id: number;
  reference_no: string;
  vendor_name: string;
  amount: number;
  unaccounted_amount: number;
  notes: string;
  bill_date: string;
  pending_amount: number;
};
const Expenses = () => {
  const [editVisible, setEditVisible] = useState(false);
  const [expensesDetail, setExpenseDetail] = useState<ExpenseItem>();

  const [visible, setVisible] = useState<boolean>(false);
  const [expensesData, setExpensesData] = useState<ExpenseItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount]:any = useState(0);
  const [totalUnaccountedAmount, setTotalUnaccountedAmount]:any = useState(0);
  const [ExepnseId, setExepnseId] = useState(0);
  const [allExpenseData, setAllExpenseData]= useState([]);
  const [nameSearch, setNameSearch] = useState("");

  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
  let token = getToken();

  useEffect(() => {
    fetchExpensesData();
  }, [currencyRate]);

  useEffect(() => {    
    const filteredData = allExpenseData.filter((expense:any) =>
      expense.vendor_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
      expense.reference_no.toLowerCase().includes(nameSearch.toLowerCase()) ||
      expense.notes.toLowerCase().includes(nameSearch.toLowerCase())
    );
    setExpensesData(filteredData || []);
  }, [nameSearch,allExpenseData, currencyRate]);

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  const getExpenseDetail = (expenses) => {
    setExpenseDetail(expenses);
  };

  const visibleHandler = (pageType?: string, expenseid?) => {
    if (pageType === "edit") {
      setEditVisible(true);
      setExepnseId(expenseid);
    } else {
      setVisible(true);
    }
  };

  const closeHandler = (pageType?: string) => {
    if (pageType === "edit") {
      setEditVisible(false);
    } else {
      setVisible(false);
    }
  };
  const fetchExpensesData = async () => {
    setLoading(true);
    try {
      const response = await AxiosConfig.get("expense", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const expenseData = response.data.data.expenses;
      const total = expenseData.reduce((acc, item) => acc + item.amount, 0);
      setTotalAmount(convertCurrency((total || 0),currencyRate));
      const unaccountedTotal = expenseData.reduce(
        (acc, item) => acc + (item.amount - item.unaccounted_amount),
        0
      );
      setTotalUnaccountedAmount(convertCurrency(unaccountedTotal,currencyRate));
      
      const transformedData = expenseData.map(
        (row: {
          id: number;
          reference_no: string;
          vendor_name: string;
          amount: number;
          notes: string;
          bill_date: string;
          unaccounted_amount: number;
          pending_amount: number;
        }) => {
          const date = new Date(row.bill_date);
          const formattedDate = date.toISOString().split("T")[0];
          return {
            id: row.id,
            reference_no: row.reference_no,
            vendor_name: row.vendor_name,
            amount: convertCurrency((row.amount || 0),currencyRate),
            notes: row.notes,
            unaccounted_amount: row.unaccounted_amount,
            bill_date: formattedDate,
            pending_amount: convertCurrency(((row.amount - row.unaccounted_amount) || 0),currencyRate),
          };
        }
      )
      setAllExpenseData(transformedData);
      setExpensesData(transformedData);
    } catch (error) {
      console.error("Error fetching expenses data:", error);
    } finally {
      setLoading(false);
    }
  };
  let cur_type = localStorage.getItem("cur_type")
  let isEditable = localStorage.getItem("isEditable")
  

 const columns: ColumnType<ExpenseItem>[] = [
   {
     title: "Reference No.",
     dataIndex: "reference_no",
     key: "reference_no",
   },
   {
     title: "Vendor Name",
     dataIndex: "vendor_name",
     key: "vendor_name",
     sorter: (a: ExpenseItem, b: ExpenseItem) => a.vendor_name.localeCompare(b.vendor_name)
   },
   {
     title: "Bill Date",
     dataIndex: "bill_date",
     key: "bill_date",
     sorter: (a, b) => {
       const dateA = new Date(a.bill_date).getTime();
       const dateB = new Date(b.bill_date).getTime();
       return dateA - dateB;
     },
   },
   {
     title: "Amount",
     dataIndex: "amount",
     key: "amount",
     sorter: (a, b) => replaceCurrencyString(a.amount) - replaceCurrencyString(b.amount),
     render: (text) => (
       <div style={{ textAlign: "right" }}>
         {text || 0}
       </div>
     ),
   },
   {
     title: "Unaccounted Amount",
     dataIndex: "pending_amount",
     key: "pending_amount",
     sorter: (a, b) => replaceCurrencyString(a.pending_amount) - replaceCurrencyString(b.pending_amount),
     render: (text) => (
       <div style={{ textAlign: "right" }}>
         {text || 0}
       </div>
     ),
   },
   {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
  },
   {
     title: "Edit",
     key: "actions",
     dataIndex: "id",

     render: (record: any) => (
       <>
         <div className="actionBtns" style={{ cursor: "pointer" }}>
           <EditTwoTone
             onClick={() => {
              checkCurrency(cur_type,record)
              
             }}
           />
         </div>
       </>
     ),
   },
 ];
 const checkCurrencyType = () => {
    
    if(isEditable == "true"){
      setVisible(true)
    }else{
      notification.open({
        message: "",
        description: "To edit/add values, please switch to base currency editable mode",
        style: { color: "red" },
      });
    }
 }

 const checkCurrency = (cur_type,record) => {
  let isEditable = localStorage.getItem("isEditable")
  
  if(isEditable == "true" ){
     getExpenseDetail(record);
     visibleHandler("edit", record);
  }else{
    notification.open({
      message: "",
      description: "To edit/add values, please switch to base currency editable mode",
      style: { color: "red" },
    });
  }
 }
  return (
    <div className="table-card">
      <Row>
        <Col md={8} xs={24} lg={8} xl={8}>
          <div className="searchForm">
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by Vendor Name / Reference No / Notes"
              />
          </div>
        </Col>
        <Col
          className="AddExpensesButton"
          md={16} xs={24} lg={16} xl={16}
          style={{ textAlign: "right" }}
        >
          <Button
            type="primary"
            className="primary-btn"
            onClick={() => checkCurrencyType()}
            style={{ marginBottom: "10px" }}
          >
            {enumForExpenses.buttonForAddExpense}
          </Button>
        </Col>
      </Row>
      {allExpenseData && 
        <AddOverlay
          visible={visible}
          onClose={() => setVisible(false)}
          onCancelButton={() => setVisible(false)}
          allExpenseData={allExpenseData}
        />
      }
      {editVisible &&
          (
          <EditOverlay
            visible={editVisible}
            onClose={() => closeHandler("edit")}
            onCancelButton={() => closeHandler("edit")}
            expenseId={ExepnseId}
            expensesDetail={expensesDetail}
            allExpenseData={allExpenseData}
          ></EditOverlay>
        )}
      <div className="tableOut noWordBreak">
        <Table
          columns={columns}
          dataSource={expensesData}
          loading={loading}
          bordered
          scroll={{ x: 300, y: 680 }}
          footer={() => (
            <div>
              <strong>{enumForExpenses.labelForTotalAmount}</strong>
              {totalAmount || 0}
              <div>
                <strong>
                  {enumForExpenses.labelForTotalUnaccountedAmount}
                </strong>
                {totalUnaccountedAmount || 0}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Expenses;
