import "antd/dist/antd.css";
import { Drawer } from "antd";
import Select from "react-select";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { useState, useEffect } from "react";
import { getToken } from "../../../auth";
import { notification } from "antd";
import { editUser } from "../../../utils/enums";
require("./EditUser.scss");
import { useSelector } from "react-redux";

const EditUser = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  userId: any;
}) => {
  const validationSchema = yup.object({
    first_name: yup.string().required("Please enter the first name"),
    last_name: yup.string().required("Please enter the last name"),
    email_id: yup
      .string()
      .email("Enter a valid Email")
      .required("Please enter the email Id"),
    password: yup.string().min(6),
  });

  let id = localStorage.getItem("UserId");

  let token = getToken();

  const [associatedBu, setAssociatedBu] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailID, setEmailId] = useState("");
  const [buArray, setBuArray] = useState([]);
  const [password, setPassword] = useState("");
  const departmentData = useSelector((state: any) => state.departments.departments);

  const onCancelHandler = () => {
    props.onCancelButton();
    props.onClose(); 
    setFirstName("");
    setLastName("");
    setEmailId("");
    setPassword("");
    localStorage.removeItem("UserId");
  };
  

  useEffect(() => {
    getData();
  }, [props.visible]);

  const getData = () => {
    if(id){
      AxiosConfig.get(`users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setEmailId(res.data.email);
        setBuArray(res.data.departments);
      });
    }
    
  };

  useEffect(() => {
    if(departmentData.length){
      setAssociatedBu(departmentData)
    }
     
  }, [departmentData]);
   
 

  const filteredBu = associatedBu.filter(function (associatedBu: {
    flag: any;
  }) {
    return associatedBu.flag === true;
  });

  const tempAssociatedBuOptions = filteredBu.map(function (associatedBus: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedBus.id,
      label: associatedBus.name,
    };
  });

  const associatedBuOptions = tempAssociatedBuOptions.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <Drawer
        title="Edit User"
        width={500}
        onClose={onCancelHandler}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="edit_project"
        closable={false}
        maskClosable={false}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name: firstName,
            last_name: lastName,
            email_id: emailID,
            password: password,
            associated_bu_array: buArray.map((row: { name: any; id: any }) => {
              return {
                label: row.name,
                value: row.id,
              };
            }),
          }}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            let diffValues: any = {};
            let id_array: any = [];
            for (const element in values) {
              if (values[element].value && !Array.isArray(values[element])) {
                diffValues[element] = values[element].value;
              } else if (Array.isArray(values[element])) {
                values[element].forEach((item: any) => {
                  id_array.push(item.value);
                });
                diffValues[element] = id_array;
              } else {
                diffValues[element] = values[element];
              }
            }
            AxiosConfig.put(`users/${props.userId}`, diffValues, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((response) => {
                if (response.status == 200) {
                  const notify = () => {
                    notification.destroy();
                    notification.open({
                      message: response.data.status.message,
                    });
                  };
                  {
                    notify();
                  }
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch(function (error) {
                const notify = () => {
                  notification.destroy();
                  notification.open({
                    message: "",
                    description: error.response.data.status.message,
                    style: { color: "red" },
                  });
                };
                {
                  notify();
                }
              });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className="edit_user_form">
              <div className="EmailLable">
                <label>{emailID}</label>
              </div>
              <label>{editUser.labelForFirstName} </label>
              <InputField
                name="first_name"
                onChange={handleChange}
                value={values.first_name}
                className="input--field"
              ></InputField>
              <p className="display_error">{errors.first_name}</p>
              <label>{editUser.labelForLastName}</label>
              <InputField
                name="last_name"
                onChange={handleChange}
                value={values.last_name}
                className="input--field"
              ></InputField>
              <p className="display_error">{errors.last_name}</p>
              <label>{editUser.labelForPassword}</label>
              <InputField
                name="password"
                onChange={handleChange}
                className="input--field"
                value={values.password}
                placeholder="To change the password, enter new value. 
                            Else leave blank to retain old passwordlect department(s) to provide access"
                // placeholder for password
              ></InputField>
              <p>
                {editUser.labelForPlaceHolder}
              </p>
              {/* label for password */}
              <p className="display_error">{errors.password}</p>
              <label>{editUser.labelForDepartments}</label>
              <Select
                isMulti
                isClearable
                options={associatedBuOptions}
                name="associated_bu_array"
                placeholder="Select department(s) to provide access"
                value={values.associated_bu_array}
                onChange={(selectedValue: any) =>
                  setFieldValue(
                    "associated_bu_array",
                    selectedValue.map((row: any) => {
                      return {
                        label: row.label,
                        value: row.value,
                      };
                    })
                  )
                }
              />
              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Save"
                ></ButtonReuse>
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={() => {
                    onCancelHandler();
                    resetForm();
                    setFieldValue("password", "");
                  }}
                ></ButtonReuse>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default EditUser;
