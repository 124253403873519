import { Col, Image, Table, notification } from "antd";
import deleteImage from "../../../assets/images/delete.png";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";

require("../ProjectDetails/EditAttachments.scss");

const DeleteExpenseAttachment = (props: { images: any }) => {
  let token = getToken();

  const deleteHandler = async (record: any) => {
    try {
      const res = await AxiosConfig.delete(`expense/attachments/${record}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.data.status.code === 200) {
        const notify = () => {
          notification.open({
            message: res.data.status.message,
          });
        };
        notify();
      }
    } catch (error) {
      const notify = () => {
        notification.open({
          message: "",
          description:
            (
              error as {
                response?: { data?: { status?: { message?: string } } };
              }
            )?.response?.data?.status?.message || "Error deleting attachment.",
          style: { color: "red" },
        });
      };
      notify();
    }
  };

  const generateAttachmentJSX = (
    record: any,
    fileName: string,
    fileType: string
  ) => {
    let attachmentContent;

    if (
      fileType === "pdf" ||
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "png" ||
      fileType === "xlsx" ||
      fileType === "csv" ||
      fileType === "docx"
    ) {
      if (fileType === "pdf") {
        attachmentContent = (
          <div className="pdf-placeholder">
            <img
              src={pdfPlaceholderImage}
              alt="PDF Placeholder"
              className="pdf-placeholder-image"
            />
          </div>
        );
      } else if (fileType === "xlsx") {
        attachmentContent = (
          <div className="xlsx-placeholder">
            <img
              src={xlsxPlaceholderImage}
              alt="XLSX Placeholder"
              className="xlsx-placeholder-image"
            />
          </div>
        );
      } else if (fileType === "docx") {
        attachmentContent = (
          <div className="docx-placeholder">
            <img
              src={docxPlaceholderImage}
              alt="DOCX Placeholder"
              className="docx-placeholder-image"
            />
          </div>
        );
      } else {
        attachmentContent = (
          <Col key={record} span={12}>
            <Image
              style={{ border: "1px solid #989898", borderRadius: "4px" }}
              width={62}
              height={62}
              src={record}
              alt="Attachment"
            />
          </Col>
        );
      }

      return (
        <div className="attachment">
          {attachmentContent}
          <text>{fileName}</text>
        </div>
      );
    } else {
      return (
        <div className="attachment" key={record.key}>
          <Image
            style={{ border: "1px solid #989898", borderRadius: "4px" }}
            width={62}
            height={62}
            src={record.image_url}
            alt="Attachment"
          />
        </div>
      );
    }
  };

  const POImageData = props.images.map((row: { id: number; url: string }) => ({
    image_id: row.id,
    image_url: row.url,
    key: row.id,
  }));

  const columns = [
    {
      title: "Attachments",
      dataIndex: "image_url",
      key: "image_url",
      render: (record: any, row: any) => {
        const fileType = row?.image_id.split(".").pop();
        const fileName = row?.image_id.split("^").pop().split(".")[0];
        return generateAttachmentJSX(record, fileName, fileType);
      },
    },
    {
      title: "Action",
      dataIndex: "image_id",
      key: "image_id",
      render: (record: any) => (
        <div className="actionBtnAttachment">
          <span>
            <img src={deleteImage} onClick={() => deleteHandler(record)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        className="AttachmentsTable"
        columns={columns}
        dataSource={POImageData}
        pagination={{ pageSize: 2 }}
      />
    </>
  );
};

export default DeleteExpenseAttachment;
