import React, { ChangeEvent, useState, useEffect } from "react";
import { Modal, Select, notification, Image, Button, Upload } from "antd";
import edit from "../../assets/images/edit.png";
import { InputField } from "../../components";
import { Axios } from "../../utils/axios";
import { errorNotification } from "../../utils/util";
import "./SessionAudit.scss";
import ButtonReuse from "../../components/Buttons/Button";
import TextArea from "antd/lib/input/TextArea";
import { AxiosConfig } from "../../ApiConfig";
import { getToken } from "../../auth";
import { PlusOutlined, DownloadOutlined  } from "@ant-design/icons";
import { AddProject } from "../../utils/enums";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import deleteImage from "../../assets/images/delete.png";
import xlsxPlaceholderImage from "../../assets/images/xlsx_thumbnail.png";
import pdfPlaceholderImage from "../../assets/images/pdfPlaceHolder.png";
import docxPlaceholderImage from "../../assets/images/docximage.png";
import Loader from "../Loader/Loader";

const token = getToken();

const { Option } = Select;

interface SessionAuditProps {
  group_id: any;
  project_id: any;
  year: any;
  week_no: any;
  func_type: any;
  response_group_id?: any;
  onClose: () => void;
  project_name: any;
  accessToAll: any;
  is_month_report: boolean;
  month_no: any;
  month_name: any;
  project_code?: any;
  recorded_by?: string | null;
  is_quarterly_report: boolean;
  quarterly_type: any;
  customer_id: any;
  entity: any;
  customer_name: any;
}
/* eslint-disable no-unused-vars */
const SessionAudit: React.FC<SessionAuditProps> = ({
  response_group_id,
  group_id,
  project_id,
  year,
  week_no,
  func_type,
  project_name,
  project_code,
  recorded_by,
  onClose,
  accessToAll,
  is_month_report,
  month_no,
  month_name,
  is_quarterly_report,
  quarterly_type,
  customer_id,
  entity,
  customer_name,
}) => {
  const [review_type, setReviewType] = useState("");
  const [recordedDate, setRecordedDate] = useState();
  const [scoring_color_code, setScoringColorCode] = useState("#FFFFFF");
  const [minRating, setminRating] = useState(1);
  const [maxRating, setmaxRating] = useState(5);
  const [disableMode, setDisableMode] = useState(true);
  const [isRated, setIsRated] = useState(false);
  const [statusColorData, setStatusColorData] = useState([]);
  const [avgScore, setAvgScore]: any = useState(0);
  const [resGrpId, setResGrpId]: any = useState();
  const [loading, setLoading] = useState(true);

  const [questionnaires, setQuestionnaires] = useState<
    {
      id: number;
      question_id: number;
      question: string;
      is_active: boolean;
      response: string;
      date_of_response: string;
      comments_enabled: any;
      rating: number;
      colorName: string;
      colorCode: string;
      response_score: string;
    }[]
  >([]);
  interface Attachment {
    attachment_id: string;
  }
  interface AttachmentData {
    attachment_id: string;
  }

  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const fetchFileUrl = async (attachment_id: string) => {
    try {
      const response = await AxiosConfig.get(
        `audit/attachment/${attachment_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      const fileData = arrayBufferToBase64(response.data);
      return `data:${contentType};base64,${fileData}`;
    } catch (error) {
      console.error(`Error fetching file URL for ${attachment_id}:`, error);
      return null;
    }
  };

  const [attachmentsData, setAttachmentsData] = useState<AttachmentData[]>([]);

  const getResponseData = async () => {
    try {
      setLoading(true);
      const res = await AxiosConfig.get(`reviewSettings/${group_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const reviewData = res.data.data.reviewData;
      setStatusColorData(reviewData[0]?.group_scorings || []);
      let questionArray: any = [];
      setReviewType(reviewData[0].review_type);
      setminRating(reviewData[0].min_rating);
      setmaxRating(reviewData[0].max_rating);
  

      if (reviewData[0].questions && reviewData[0].questions.length) {
        let quesObj = reviewData[0].questions;
        quesObj.map((questions: any) => {
          questionArray.push({
            question_id: questions.id,
            question: questions.question,
            is_active: questions.is_active,
            comments_enabled: questions.comments_enabled,
          });
        });
        if (func_type == "update") {
          if (is_month_report) {
            let res;
            if (entity == "Project") {
              res = await AxiosConfig.get(
                `audit/response/${project_id}?group_id=${group_id}&month_no=${month_no}&year=${year}&is_month_report=${is_month_report}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
            } else if (entity == "Customer") {
              res = await AxiosConfig.get(
                `audit/response/customer/${customer_id}?group_id=${group_id}&month_no=${month_no}&year=${year}&is_month_report=${is_month_report}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
            }

            const responseData = res.data.data.responseData;
            setResGrpId(responseData[0].group_id);
            const recorded_date = res.data.data.recorded_date;
            const formatedRecDate: any = getFormatedData(recorded_date);
            setRecordedDate(formatedRecDate);
            const scoreColorCode = responseData[0].color_code || "#808080";
            setScoringColorCode(scoreColorCode);
            setAvgScore(responseData[0]?.average_score || 0);
            responseData.flatMap((questions: any) => {
              const questionIndex = questionArray.findIndex(
                (question) => question.question_id === questions.question_id
              );

              if (questionIndex !== -1) {
                const correspondingColor = getRangeColorDetails(
                  questions.response_score,
                  reviewData[0]?.group_scorings
                );
                questionArray[questionIndex]["id"] = questions.id;
                questionArray[questionIndex]["response"] = questions.response;
                questionArray[questionIndex]["date_of_response"] =
                  questions.date_of_response;
                questionArray[questionIndex]["response_score"] =
                  questions.response_score;
                questionArray[questionIndex]["colorName"] =
                  correspondingColor?.rating_color || "No Color";
                questionArray[questionIndex]["colorCode"] =
                  correspondingColor?.color_code || "#808080";
              }
            });
          } else if (is_quarterly_report) {
            let res;
            if (entity == "Customer") {
              res = await AxiosConfig.get(
                `audit/response/customer/${customer_id}?group_id=${group_id}&quarterly_type=${quarterly_type}&year=${year}&is_quarterly_report=${is_quarterly_report}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
            } else {
              res = await AxiosConfig.get(
                `audit/response/${project_id}?group_id=${group_id}&quarterly_type=${quarterly_type}&year=${year}&is_quarterly_report=${is_quarterly_report}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
            }
            const responseData = res.data.data.responseData;
            setResGrpId(responseData[0].group_id);
            const recorded_date = res.data.data.recorded_date;
            const formatedRecDate: any = getFormatedData(recorded_date);
            setRecordedDate(formatedRecDate);
            const scoreColorCode = responseData[0].color_code || "#808080";
            setScoringColorCode(scoreColorCode);
            setAvgScore(responseData[0]?.average_score || 0);
            responseData.flatMap((questions: any) => {
              const questionIndex = questionArray.findIndex(
                (question) => question.question_id === questions.question_id
              );

              if (questionIndex !== -1) {
                const correspondingColor = getRangeColorDetails(
                  questions.response_score,
                  reviewData[0]?.group_scorings
                );
                questionArray[questionIndex]["id"] = questions.id;
                questionArray[questionIndex]["response"] = questions.response;
                questionArray[questionIndex]["date_of_response"] =
                  questions.date_of_response;
                questionArray[questionIndex]["response_score"] =
                  questions.response_score;
                questionArray[questionIndex]["colorName"] =
                  correspondingColor?.rating_color || "No Color";
                questionArray[questionIndex]["colorCode"] =
                  correspondingColor?.color_code || "#808080";
              }
            });
          } else {
            let res;
            if (entity == "Project") {
              res = await AxiosConfig.get(
                `audit/response/${project_id}?group_id=${group_id}&week_no=${week_no}&year=${year}&is_month_report=${is_month_report}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
            } else if (entity == "Customer") {
              res = await AxiosConfig.get(
                `audit/response/customer/${customer_id}?group_id=${group_id}&week_no=${week_no}&year=${year}&is_month_report=${is_month_report}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
            }
            const responseData = res.data.data.responseData;
            setResGrpId(responseData[0].group_id);
            const recorded_date = res.data.data.recorded_date;
            const formatedRecDate: any = getFormatedData(recorded_date);
            setRecordedDate(formatedRecDate);

            setAvgScore(responseData[0]?.average_score || 0);
            const scoreColorCode = responseData[0].color_code || "#808080";
            setScoringColorCode(scoreColorCode);
            responseData.flatMap((questions: any) => {
              const questionIndex = questionArray.findIndex(
                (question) => question.question_id === questions.question_id
              );

              if (questionIndex !== -1) {
                const correspondingColor = getRangeColorDetails(
                  questions.response_score,
                  reviewData[0]?.group_scorings
                );
                questionArray[questionIndex]["id"] = questions.id;
                questionArray[questionIndex]["response"] = questions.response;
                questionArray[questionIndex]["date_of_response"] =
                  questions.date_of_response;
                questionArray[questionIndex]["response_score"] =
                  questions.response_score;
                questionArray[questionIndex]["colorName"] =
                  correspondingColor?.rating_color || "No Color";
                questionArray[questionIndex]["colorCode"] =
                  correspondingColor?.color_code || "#808080";
              }
            });
          }
        }
      }

      setQuestionnaires(questionArray);

      let filesResponse;
      if (is_month_report) {
        if (entity == "Customer") {
          filesResponse = await AxiosConfig.get(
            `audit/attachments/customer/${customer_id}?month_no=${month_no}&year=${year}&group_id=${group_id}&is_month_report=${is_month_report}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        } else {
          filesResponse = await AxiosConfig.get(
            `audit/attachments/${project_id}?month_no=${month_no}&year=${year}&group_id=${group_id}&is_month_report=${is_month_report}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }
      } else if (is_quarterly_report) {
        if (entity == "Customer") {
          filesResponse = await AxiosConfig.get(
            `audit/attachments/customer/${customer_id}?quarterly_type=${quarterly_type}&year=${year}&group_id=${group_id}&is_quarterly_report=${is_quarterly_report}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        } else {
          filesResponse = await AxiosConfig.get(
            `audit/attachments/${project_id}?quarterly_type=${quarterly_type}&year=${year}&group_id=${group_id}&is_quarterly_report=${is_quarterly_report}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }
      } else {
        if (entity == "Customer") {
          filesResponse = await AxiosConfig.get(
            `audit/attachments/customer/${customer_id}?week_no=${week_no}&year=${year}&group_id=${group_id}&is_month_report=${is_month_report}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        } else {
          filesResponse = await AxiosConfig.get(
            `audit/attachments/${project_id}?week_no=${week_no}&year=${year}&group_id=${group_id}&is_month_report=${is_month_report}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }
      }

      if (filesResponse) {
       
        setAttachmentsData(filesResponse.data.data.attachments);

        const attachmentsData = filesResponse.data.data.attachments;
        console.log(attachmentsData, "aa");

        const fileUrlPromises = attachmentsData.map(
          async (attachment: Attachment) => {
            const fileUrl = await fetchFileUrl(attachment.attachment_id);
            return fileUrl;
          }
        );

        const fileUrls = await Promise.all(fileUrlPromises);

        setFileUrls(fileUrls as string[]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error fetching Response data:", error);
      setLoading(false);
    }
  };
  const [maskedValue, setMaskedValue] = useState('');
  const [realValue, setRealValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialFormState, setInitialFormState] = useState<any>({
    supersec_pwd: "",
    status: "",
  });

  const handleOk = async () => {
    try {
      const response = await Axios.put(
        "auth/verifySuperSecretPassword",
        initialFormState
      );

      if (response) {
        setDisableMode(false);
        setIsModalVisible(false);
        setIsAttachFileVisible(true);
        setDeleteButtonVisible(true);
      } else {
        errorNotification("");
      }
    } catch (error: any) {
      errorNotification("Invalid Super secret password");
    }
  };
  const getFormatedData = (recDate) => {
    const dateObject = new Date(recDate);

    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();

   
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };
  const handleChangeQuestionnaire = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const updatedQuestionnaires = [...questionnaires];
    updatedQuestionnaires[index] = {
      ...updatedQuestionnaires[index],
      response: e.target.value,
    };
    setQuestionnaires(updatedQuestionnaires);
  };

  const handleSave = async () => {
    const activeQuestionsList = questionnaires.filter((item) => item.is_active);
    const questionsToValidate = activeQuestionsList.filter(
      (item) => item.comments_enabled
    );
    const isEmptyQuestion = questionsToValidate.some(
      (item) => !item.response && item.response_score !== "NA"
    );
    const isEmptyRating = activeQuestionsList.some(
      (item) =>
        (item.response_score === undefined || item.response_score === null) &&
        item.response_score !== "NA"
    );

    if (isEmptyQuestion || isEmptyRating) {
      errorNotification(
        "Please fill in all questions and ratings before saving."
      );
      return;
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj as File);
    });

    try {
      if (fileList.length > 0) {
        let fileUploadResponse;
        if (is_month_report) {
          if (entity == "Customer") {
            fileUploadResponse = await AxiosConfig.post(
              `audit/attachments/upload/customer/${customer_id}?month_no=${month_no}&year=${year}&group_id=${group_id}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } else {
            fileUploadResponse = await AxiosConfig.post(
              `audit/attachments/upload/${project_id}?month_no=${month_no}&year=${year}&group_id=${group_id}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
        } else if (is_quarterly_report) {
          if (entity == "Customer") {
            fileUploadResponse = await AxiosConfig.post(
              `audit/attachments/upload/customer/${customer_id}?quarterly_type=${quarterly_type}&year=${year}&group_id=${group_id}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } else {
            fileUploadResponse = await AxiosConfig.post(
              `audit/attachments/upload/${project_id}?quarterly_type=${quarterly_type}&year=${year}&group_id=${group_id}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
        } else {
          if (entity == "Customer") {
            fileUploadResponse = await AxiosConfig.post(
              `audit/attachments/upload/customer/${customer_id}?week_no=${week_no}&year=${year}&group_id=${group_id}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } else {
            fileUploadResponse = await AxiosConfig.post(
              `audit/attachments/upload/${project_id}?week_no=${week_no}&year=${year}&group_id=${group_id}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
        }
        if (fileUploadResponse.status === 200) {
          notification.success({
            message: "Files uploaded successfully!",
          });
        } else {
          console.error("Unexpected response:", fileUploadResponse);
        }
      }
    } catch (error) {
      console.error("File upload error:", error);
    }

    try {
      if (filesToDelete.length > 0) {
        for (const attachment_id of filesToDelete) {
          await AxiosConfig.delete(`audit/attachments/${attachment_id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        const updatedFileUrls = fileUrls.filter(
          (url) => !filesToDelete.includes(url)
        );
        setFileUrls(updatedFileUrls);

        notification.success({
          message: "Files deleted successfully!",
        });
      }
    } catch (error) {
      console.error("Error deleting files:", error);
    }

    let responseArray = questionnaires.map((item) => {
      if (item.id) {
        console.log("Editing existing response");
        return {
          id: item.id,
          question_id: item.question_id,
          response: item.response,
          response_score:
            item.response_score !== "NA" ? parseInt(item.response_score) : "NA",
        };
      } else {
        console.log("Adding new response");
        return {
          question_id: item.question_id,
          response: item.response,
          response_score:
            item.response_score !== "NA" ? parseInt(item.response_score) : "NA",
        };
      }
    });

    const activeQuestions = questionnaires.filter(
      (question) =>
        question.is_active &&
        question.response_score !== null &&
        question.response_score !== "NA"
    );
    const sumOfScores = activeQuestions.reduce(
      (sum, item) => sum + (Number(item.response_score) || 0),
      0
    );
    const average_score =
      activeQuestions.length > 0 ? sumOfScores / activeQuestions.length : "NA";

    let color_code = "#808080";
    let color_name = "#grey";

    if (average_score !== "NA") {
      const roundedAverageScore = Math.floor(Number(average_score));
      color_code =
        getRangeColorDetails(roundedAverageScore, statusColorData)
          ?.color_code || "#808080";
      color_name =
        getRangeColorDetails(roundedAverageScore, statusColorData)
          ?.rating_color || "#grey";
    }
    let requestData,
      isCustomerEntity = false;
    if (entity == "Customer") {
      isCustomerEntity = true;
    }

    const userId = localStorage.getItem("userId");
    if (is_month_report) {
      requestData = {
        id: resGrpId,
        question_group_id: parseInt(group_id),
        customer_id: isCustomerEntity ? customer_id : null,
        project_id: !isCustomerEntity ? project_id : null,
        date_of_response: getFormatedData(new Date()),
        month_no: month_no,
        year: year,
        average_score: average_score,
        color_code: color_code,
        color_name: color_name,
        responseArray: responseArray,
        formData: formData,
        user_id: userId ? parseInt(userId) : null,
      };
    } else if (is_quarterly_report) {
      requestData = {
        id: resGrpId,
        question_group_id: parseInt(group_id),
        customer_id: isCustomerEntity ? customer_id : null,
        project_id: !isCustomerEntity ? project_id : null,
        date_of_response: getFormatedData(new Date()),
        quarterly: quarterly_type,
        year: year,
        average_score: average_score,
        color_code: color_code,
        color_name: color_name,
        responseArray: responseArray,
        formData: formData,
        user_id: userId ? parseInt(userId) : null,
      };
    } else {
      requestData = {
        id: resGrpId,
        question_group_id: parseInt(group_id),
        customer_id: isCustomerEntity ? customer_id : null,
        project_id: !isCustomerEntity ? project_id : null,
        date_of_response: getFormatedData(new Date()),
        week_no: week_no,
        year: year,
        average_score: average_score,
        color_code: color_code,
        color_name: color_name,
        responseArray: responseArray,
        formData: formData,
        user_id: userId ? parseInt(userId) : null,
      };
    }

    try {
      let response;
      if (is_quarterly_report) {
        response = await AxiosConfig.post(
          `audit/response?is_quarterly_report=${is_quarterly_report}&entity=${entity}`,
          requestData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        response = await AxiosConfig.post(
          `audit/response?is_month_report=${is_month_report}&entity=${entity}`,
          requestData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }

      if (response.status === 200) {
        notification.success({
          message: "Audit response saved successfully!",
        });
      }
    } catch (error) {
      console.error("Audit response save error:", error);
    }
    onClose();
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{AddProject.ButtonForUpload}</div>
    </div>
  );

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();

  const handleCancell = () => {
    setPreviewVisible(false);
  };
  const handlePreview = async (file) => {
    try {
      if (file.thumbUrl) {
        setPreviewImage(file.thumbUrl);
        setPreviewVisible(true);
        setPreviewTitle(file.name || "Untitled");
      } else {
        throw new Error("Thumb URL not available.");
      }
    } catch (error) {
      console.error("Error while handling preview:", error);
    }
  };

  const [fileList, setFileList] = useState<any[]>([]);

  const handleAttachmentChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = info;
    setFileList(fileList);
  };

  const handleCancel = () => {
    onClose();
  };
  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  
    setMaskedValue('');
    setRealValue(''); 
  
    setInitialFormState({
      supersec_pwd: "",
      status: "",
    });
    setIsModalVisible(true);
  };
  const handleCancelVerifyModel = () => {
    setIsModalVisible(false);
  };

  const handleChangeRatingQuestion = (value, index) => {
    const questionnairesCopy = [...questionnaires];
    if (value === "NA") {
      questionnairesCopy[index].response_score = "NA";
    } else {
      questionnairesCopy[index].response_score = value.toString();
    }
    const corresPondingColorDetails = getRangeColorDetails(
      questionnairesCopy[index].response_score,
      statusColorData
    );
    questionnairesCopy[index]["colorName"] =
      corresPondingColorDetails?.rating_color || "";
    questionnairesCopy[index]["colorCode"] =
      corresPondingColorDetails?.color_code || "";
    setQuestionnaires(questionnairesCopy);

    const activeQuestions = questionnairesCopy.filter(
      (question) => question.is_active && question.response_score !== "NA"
    );
    const sumOfScores = activeQuestions.reduce(
      (sum, item) => sum + (Number(item.response_score) || 0),
      0
    );
    const avgScore =
      activeQuestions.length > 0 ? sumOfScores / activeQuestions.length : "NA";

    setAvgScore(avgScore);

    if (typeof avgScore === "number") {
      if (statusColorData) {
        const changedStatusColor: any = statusColorData.find((item: any) => {
          const roundedScore = Math.floor(avgScore);
          return (
            roundedScore >= item.score_from && roundedScore <= item.score_to
          );
        });
        if (changedStatusColor) {
          setScoringColorCode(changedStatusColor.color_code);
        }
      }
    }
    setIsRated(true);
    console.log(isRated);
  };

  const ratingOptions = [
    <Option key="NA" value="NA">
      NA
    </Option>,
    ...Array.from({ length: maxRating - minRating + 1 }, (_, index) => (
      <Option key={index} value={`${minRating + index}`}>
        {minRating + index}
      </Option>
    )),
  ];

  const getTodayDate = () => {
    const currentDate = new Date();

    const thisyear = currentDate.getFullYear();
    const thismonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const today = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate: any = `${thisyear}-${thismonth}-${today}`;
    setRecordedDate(formattedDate);
  };
  useEffect(() => {
    getResponseData();
    getTodayDate();
    setDisableMode(accessToAll != 1 ? true : false);
    setIsRated(false);
  }, [accessToAll, week_no, month_name, year, quarterly_type]);

  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
  const [isAttachFileVisible, setIsAttachFileVisible] = useState(false);
  const [isDeleteButtonVisible, setDeleteButtonVisible] = useState(false);

  const handleDeleteImage = (attachment_id: string, index: number) => {
    const updatedFileUrls = fileUrls.filter((url, i) => i !== index);
    const updatedAttachmentsData = attachmentsData.filter(
      (data) => data.attachment_id !== attachment_id
    );

    setFileUrls(updatedFileUrls);
    setAttachmentsData(updatedAttachmentsData);

    setFilesToDelete((prevFilesToDelete) => [
      ...prevFilesToDelete,
      attachment_id,
    ]);
  };

  /* This function will check the score of the question and return the color and color name in which it belongs to in the range of rating and its colors */
  const getRangeColorDetails = (questionScore, statusColors) => {
    let obtainedColorDetails: any;
    questionScore = questionScore.toString();
    if (questionScore) {
      const obtainedDetails = statusColors?.filter(
        (color: any) =>
          questionScore >= color?.score_from && questionScore <= color?.score_to
      );
      obtainedColorDetails = obtainedDetails[0];
    }
    return obtainedColorDetails;
  };

  const getFileThumbnail = (fileUrl) => {
    if (!fileUrl) {
      console.log("no file url");
      return null;
    }
    const fileType = getFileType(fileUrl);

    if (
      fileType === "pdf" ||
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "png" ||
      fileType === "xlsx" ||
      fileType === "csv" ||
      fileType === "docx"
    ) {
      switch (fileType) {
        case "pdf":
          return (
            <div className="pdf-placeholder">
              <img
                src={pdfPlaceholderImage}
                alt="PDF Placeholder"
                className="pdf-placeholder-image"
              />
            </div>
          );
        case "xlsx":
          return (
            <div className="xlsx-placeholder">
              <img
                src={xlsxPlaceholderImage}
                alt="XLSX Placeholder"
                className="xlsx-placeholder-image"
              />
            </div>
          );
        case "docx":
          return (
            <div className="docx-placeholder">
              <img
                src={docxPlaceholderImage}
                alt="DOCX Placeholder"
                className="docx-placeholder-image"
              />
            </div>
          );

        default:
          return (
            <Image
              style={{ border: "1px solid #989898", borderRadius: "4px" }}
              width={62}
              height={62}
              src={fileUrl}
              alt="Attachment"
            />
          );
      }
    } else {
      return (
        <div className="generic-placeholder">
          <img
            src={fileUrl}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "100px",
              height: "100px",
            }}
            alt="Attachment"
          />
        </div>
      );
    }
  };

  const getFileType = (fileUrl) => {
    if (fileUrl.startsWith("data:")) {
      if (fileUrl.includes("document;base64")) {
        return "docx";
      }

      if (fileUrl.includes("sheet;base64")) {
        return "xlsx";
      }
      const matches = fileUrl.match(/^data:([A-Za-z-+/]+);base64,/);

      if (matches && matches.length > 1) {
        const mimeType = matches[1];

        if (mimeType && mimeType.includes("/")) {
          return mimeType.split("/")[1].toLowerCase() || "unknown";
        }
      }
    }

    const fileExtension = fileUrl.split(".").pop()?.toLowerCase();
    const fileTypeMap = {
      pdf: "pdf",
      jpg: "image",
      jpeg: "image",
      png: "image",
      xlsx: "excel",
      csv: "csv",
      docx: "word",
    };

    return fileTypeMap[fileExtension] || fileExtension || "unknown";
  };
  function generateHeaderText() {
    if (is_month_report && month_name) {
      return <span> {`${review_type} - ${year} - MONTH ${month_name}`} </span>;
    } else if (is_quarterly_report) {
      return <span> {`${review_type} - ${year} - ${quarterly_type}`}</span>;
    } else {
      return <span> {`${review_type} - ${year} - WEEK ${week_no}`}</span>;
    }
  }
  function generateTitleText() {
    if (entity == "Customer") {
      return <span>{`${customer_name}`}</span>;
    } else if (entity == "Project") {
      return <span> {`${project_name} - ${project_code}`}</span>;
    } else {
      return <span>{`${project_name}  - ${project_code}`}</span>;
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleOk()
     
    }
  };

  function getFileName(attachmentId) {
    const fileNameMatch = attachmentId.match(/^(.+)_[^_]+$/);

    return fileNameMatch ? fileNameMatch[1] : attachmentId;
  }
  const handleChange = (event: any) => {
    const value = event.target.value;
    const inputLength = value.length;
    const realLength = realValue.length;
  
    if (inputLength > realLength) {
      // Adding a new character
      const newChar = value[realLength];
      setRealValue(realValue + newChar);
      setMaskedValue(maskedValue + '*');
  
      // Update the form state immediately with the new value
      setInitialFormState({
        ...initialFormState,
        supersec_pwd: realValue + newChar,
      });
    } else if (inputLength < realLength) {
      // Removing a character (handling backspace or delete)
      const newRealValue = realValue.slice(0, -1);
      setRealValue(newRealValue);
      setMaskedValue(maskedValue.slice(0, -1));
  
      // Update the form state immediately with the new value
      setInitialFormState({
        ...initialFormState,
        supersec_pwd: newRealValue,
      });
    }
  };
  
  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancelVerifyModel}
        className="password-modal"
      >
        <label>{"Enter the super secret password to continue"}</label>
        <InputField
          name="supersec_pwd"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={maskedValue}
          className="input--field"
          placeholder="Enter your password"
        />
      </Modal>

      <Modal
        title={
          <div className="modal-header">
            <div className="left-section">{generateTitleText()}</div>
            <div className="right-section">
              {generateHeaderText()}
              {/* <span>{is_month_report&&month_name ?` ${review_type} - ${year} - MONTH ${month_name}` :`${review_type} - ${year} - WEEK ${week_no}`}</span> */}
            </div>

            {accessToAll != 1 && (
              <img
                src={edit}
                alt="Edit Icon"
                className="edit-icon"
                onClick={handleClick}
              />
            )}
          </div>
        }
        visible={true}
        onCancel={onClose}
        footer={[
          <div className="button-container" key="e">
            <ButtonReuse
              key="save"
              type="primary"
              className="primary-btn"
              style={{ marginLeft: "40px" }}
              htmlType="submit"
              value="Save"
              onClick={handleSave}
            />

            <ButtonReuse
              key="cancel"
              type="primary"
              className="primary-btn cancel--btn"
              value="Cancel"
              onClick={handleCancel}
            />
          </div>,
        ]}
        className="sessionModal"
        bodyStyle={{ minHeight: "400px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="session-audit">
            <div className="section-heading">
              <label className="bold">Audit Template</label>
            </div>
            <div>{review_type}</div>

            <div className="date-picker-container">
              <label htmlFor="recorded-date" className="bold">
                Recorded Date
              </label>
              <div>{recordedDate}</div>
            </div>
            <div className="recorded-by">
              <label className="bold">Recorded By</label>
              <div>{recorded_by}</div>
            </div>
            {/* <div className="recorded-at" >
            <label className="bold">Recorded On</label>
            <div>{createdOn}</div>
          </div> */}
            <div className="question">
              <label className="q-heading">
                Please answer the following review questions
              </label>
            </div>
            {questionnaires.filter((questionnaire) => questionnaire.is_active)
              .length === 0 && (
              <div className="no-active-questions">No active questions</div>
            )}
            {questionnaires.map(
              (questionnaire, index) =>
                /* Inactive questions should not be visible in Add Session Audit */
                (questionnaire?.is_active
                  ? true
                  : func_type === "update"
                  ? true
                  : false) && (
                  <div className="questionnaire" key={index}>
                    <label className="questionnaire-label">
                      {questionnaire.question}
                    </label>
                    <div
                      className="flex-color-align color-box-input"
                      style={{
                        backgroundColor:
                          questionnaire.response_score === null
                            ? "grey"
                            : questionnaire.colorCode,
                      }}
                    ></div>
                    <div className="rating-container">
                      <Select
                        id={`rating-${index}`}
                        disabled={
                          disableMode == true ||
                          questionnaire.is_active == false
                            ? true
                            : false
                        }
                        value={
                          questionnaire.response_score === "NA" ||
                          questionnaire.response_score === null
                            ? "NA"
                            : questionnaire.response_score != null
                            ? questionnaire.response_score.toString()
                            : "Rating"
                        }
                        onChange={(value) =>
                          handleChangeRatingQuestion(value, index)
                        }
                        className="rating-dropdown"
                      >
                        {ratingOptions}
                      </Select>
                    </div>
                    {questionnaire.comments_enabled && (
                      <TextArea
                        className="description"
                        value={questionnaire.response}
                        disabled={
                          disableMode == true ||
                          questionnaire.is_active == false
                            ? true
                            : false
                        }
                        onChange={(e) => handleChangeQuestionnaire(e, index)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            const textarea = e.target as HTMLTextAreaElement;
                            const cursorPosition = textarea.selectionStart ?? 0;
                            const newValue =
                              textarea.value.substring(0, cursorPosition) +
                              "\n" +
                              textarea.value.substring(textarea.selectionEnd);

                            const syntheticEvent = {
                              target: {
                                value: newValue,
                              },
                            } as React.ChangeEvent<HTMLTextAreaElement>;
                            handleChangeQuestionnaire(syntheticEvent, index);
                          }
                        }}
                      />
                    )}
                  </div>
                )
            )}

            {questionnaires.filter((questionnaire) => questionnaire.is_active)
              .length > 0 && accessToAll ? (
              <div className="attach-file-section">
                <div className="attach-file">
                  <label className="bold">Attach File</label>
                </div>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleAttachmentChange}
                  beforeUpload={() => false}
                >
                  {uploadButton}
                </Upload>
              </div>
            ) : null}

            {isAttachFileVisible &&
              questionnaires.filter((questionnaire) => questionnaire.is_active)
                .length > 0 && (
                <div className="attach-file-section">
                  <div>
                    <label className="bold">Attach File</label>
                  </div>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleAttachmentChange}
                    beforeUpload={() => false}
                  >
                    {uploadButton}
                  </Upload>
                </div>
              )}

            {fileUrls.length > 0 && (
              <>
                <div className="uploaded-files">
                  <label className="bold">Uploaded Files</label>
                </div>
                <div className="uploaded-files-section">
                  <div className="image-gallery">
                    {fileUrls.map((fileUrl, index) => (
                      <div key={index} className="uploaded-file-entry">
                        <div className="image-container">
                          {getFileThumbnail(fileUrl)}
                        </div>
                        <div className="file-details">
                          {attachmentsData[index] ? (
                            <div className="file-name-container">
                              <div className="file-name limited-space">
                                {getFileName(
                                  attachmentsData[index].attachment_id
                                )}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <Button
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size="small"
                            onClick={() =>
                              handleDownload(
                                fileUrl,
                                getFileName(
                                  attachmentsData[index].attachment_id
                                )
                              )
                            }
                          />
                        </div>
                        <div
                          className={
                            accessToAll || isDeleteButtonVisible
                              ? "delete-button-container"
                              : "delete-button-container hidden"
                          }
                        >
                          <span
                            onClick={() =>
                              handleDeleteImage(
                                attachmentsData[index].attachment_id,
                                index
                              )
                            }
                          >
                            <img
                              src={deleteImage}
                              style={{ cursor: "pointer" }}
                              alt="Delete"
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancell}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
            {(isRated == true || func_type == "update") && (
              <div className="final-status-section">
                <label className="bold">{"Overall Rating"}</label>
                <div
                  className="color-box-input ml-10"
                  style={{
                    backgroundColor:
                      avgScore === "NA" ? "#808080" : scoring_color_code,
                  }}
                ></div>
                <h3 className="bold">
                  {avgScore === "NA" ? "NA" : avgScore.toFixed(2)}
                </h3>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default SessionAudit;
