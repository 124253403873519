import "antd/dist/antd.css";
import {
  Drawer,
  Row,
  Col,
  Table,
  Input,
  Button,
  notification,
  InputNumber,
  Switch,
  Form
} from "antd";
import ReactSwitch  from "react-switch";
import { Select as AntSelect } from "antd";
import Select from "react-select";
import { FormikProps, Formik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import ButtonReuse from "../../../components/Buttons/Button";
import * as Yup from "yup";
import React, { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useEffect } from "react";
import "./AddReviews.scss"
import deleteImage from "../../../assets/images/delete.png";



const token = getToken();

const validationSchema = Yup.object().shape({
  review_type: Yup.string().required("Review Type is required"),
  interval: Yup.string().required("Interval is required"),
  entity: Yup.string().required("Entity is required"),
  description: Yup.string().required("Description is required"),
  min_rating: Yup.number().required("Min Rating is required"),
  project_ids: Yup.array().when('entity', {
    is: 'Project',
    then: Yup.array().min(1, 'Project is required').required('Project is required'),
    otherwise: Yup.array().notRequired(),
  }),
  customer_ids: Yup.array().when('entity', {
    is: 'Customer',
    then: Yup.array().min(1, 'Customer is required').required('Customer is required'),
    otherwise: Yup.array().notRequired(),
  }),
  max_rating: Yup.number()
    .required("Max Rating is required")
    .moreThan(Yup.ref('min_rating'), 'Max Rating must be greater than Min Rating'),
});

type MyFormValues = {
  id: number;
  review_type: string;
  interval: string;
  entity: string;
  description: string;
  min_rating: number;
  max_rating: number;
  customer_ids: any;
  project_ids: any;
  access_to_all:boolean;
  questions: {
    id?: number
    question: string;
    is_active: boolean;
    comments_enabled: boolean;
  }[];
  review_scoring: {
    id?: number
    rating_color: string;
    color_code: string;
    score_from: number;
    score_to: number;
  }[];
   group_scorings?: {  
    id?: number;
    rating_color: string;
    color_code: string;
    score_from: number;
    score_to: number;
  }[];
};


const AddReviewOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  editMode?: boolean;
  editItem?: MyFormValues | null;
  auditData: any[]; 
}) => {
  const formikRef = React.useRef<FormikProps<MyFormValues>>(null);

  const [projectList, setProjectList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    if (props.editMode && props.editItem?.id !== undefined) {
      formikRef.current?.setValues((prevValues) => {
        const newQuestions = (props.editItem?.questions || []).map((question, index) => ({
          ...question,
          id: question.id || index + 1,
        }));
  
        const newScoring = (props.editItem?.group_scorings || []).map((scoring, index) => ({
          ...scoring,
          id: scoring?.id || index + 1,
        }));
  
        setTableData(newQuestions.map(({ id, question, comments_enabled, is_active }) => ({
          id: id || 0,
          question,
          comments_enabled,
          is_active,
        })));
  
        setScoringTableData(newScoring.map(({ id, rating_color, color_code, score_from, score_to }) => ({
          id: id || 0,
          ratingColor: rating_color,
          color_code,
          score_from: score_from.toString(),
          score_to: score_to.toString(),
        })));
  
        setAccessToAll(props?.editItem?.access_to_all || false);
  
        const selectedProjectIdsArray = JSON.parse(props.editItem?.project_ids || "[]");
        const filteredSelectedProjects = projectList.filter((project:any) => selectedProjectIdsArray.includes(project.value));
        const projectIdsForSelect = filteredSelectedProjects.map((project:any) => ({ label: project.label, value: project.value }));
  
        const selectedCustomerIdsArray = JSON.parse(props.editItem?.customer_ids || "[]");
        const filteredSelectedCustomers = customerList.filter((customer:any) => selectedCustomerIdsArray.includes(customer.value));
        const customerIdsForSelect = filteredSelectedCustomers.map((customer:any) => ({ label: customer.label, value: customer.value }));
        return {
          ...prevValues,
          id: props.editItem?.id || 0,
          review_type: props.editItem?.review_type || "",
          interval: props.editItem?.interval || "",
          entity: props.editItem?.entity || "",
          description: props.editItem?.description || "",
          min_rating: props.editItem?.min_rating || 0,
          max_rating: props.editItem?.max_rating || 0,
          access_to_all: props.editItem?.access_to_all || false,
          questions: newQuestions,
          review_scoring: newScoring,
          project_ids: projectIdsForSelect || [],
          customer_ids: customerIdsForSelect || []
        };
      });
    }
  }, [props.editMode, props.editItem, projectList, customerList]);
  

  const [reviewTypeExists, setReviewTypeExists] = useState<boolean>(false);
  const [accessToAll,setAccessToAll] = useState<boolean>(false);

  const [tableData, setTableData] = useState<
  {  id?: number | string; question: string; comments_enabled: boolean;is_active:boolean }[]
>([
  { question: "", comments_enabled: false,is_active:true },
]);

  const handleToggleCaptureComments = (record) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => record.id === item.id);
    const item = newData[index];

    item.comments_enabled = !item.comments_enabled;

    setTableData(newData);
  };

  const handleInputChange = (value, record, field) => {
    setTableData((prevTableData) =>
      prevTableData.map((item) =>
        item.id === record.id
          ? { ...item, [field]: value }
          : item
      )
    );
  };
  const [rangeError, setRangeError] = useState<null | string>(null);
  const [scoreError, setscoreError] = useState<null | string>(null);

  const handleInputChangeColor = (value, record, field) => {
    const newData = [...scoringTableData];
    const index = newData.findIndex((item) => record.id === item.id);
    const item = newData[index];
  
    if (field === 'color_code') {

      item[field] = value;
    } else if (field === 'score_from' || field === 'score_to') {
      
      const isNumeric = /^-?[0-9]*$/.test(value);
  
      if (isNumeric || value === "") {
        item[field] = value;
      } else {
        notification.error({message:"Invalid input. Please enter only numbers for Range from and Range to."})
        return;
      }
  
      const scoreFrom = parseFloat(item.score_from);
      const scoreTo = parseFloat(item.score_to);
  
      if (!isNaN(scoreFrom) && !isNaN(scoreTo) && scoreTo < scoreFrom) {
        console.error("Range to should be greater than Range from");
        setRangeError("Range to should be greater than Range from");
        return;
      } else {
        setRangeError(null);
      }
  
     
      const hasOverlap = newData.some((existingItem) => {
        if (existingItem.id !== item.id) {
          const existingScoreFrom = parseFloat(existingItem.score_from);
          const existingScoreTo = parseFloat(existingItem.score_to);
  
          return (
            (scoreFrom >= existingScoreFrom && scoreFrom <= existingScoreTo) ||
            (scoreTo >= existingScoreFrom && scoreTo <= existingScoreTo) ||
            (existingScoreFrom >= scoreFrom && existingScoreFrom <= scoreTo) ||
            (existingScoreTo >= scoreFrom && existingScoreTo <= scoreTo)
          );
        }
  
        return false;
      });
  
      if (hasOverlap) {
        console.error("Score ranges should not overlap");
        setRangeError("Score ranges should not overlap");
        return;
      } else {
        setRangeError(null);
      }
    }
    setscoreError(null); 
    setScoringTableData(newData);
  };

  useEffect(() =>{
    getProjectDetails();
    getCustomersList();
  },[])

  const getProjectDetails = async () => {
    const userid = localStorage.getItem("userId");
    try {
      if(userid){
        const res = await AxiosConfig.get("/projects/projectList/names?user_id=" + userid + "&archive=false", {
          headers: { Authorization: `Bearer ${token}` },
        });
        let projects = res?.data?.data?.projects;
        projects = projects.sort((a: any, b: any) => {
          let a1 = a.project_code.replace("STP_", "");
          let b1 = b.project_code.replace("STP_", "");
          return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
        });
        const projectsData = projects.map((proj:any) =>  { return { value: proj.id, label: proj.name , key: proj.id }})
        const sortedProjectsData = projectsData?.sort((a, b) => a.label.trim()?.toLowerCase()?.localeCompare(b.label.trim()?.toLowerCase()));
        setProjectList(sortedProjectsData || []);
     }

    } catch (err) {
      console.error(err);
    }
  };

  const getCustomersList = async () => {
    try {
        const res = await AxiosConfig.get("/customers/AllCustomers", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const transformedCustomersList = res?.data?.data.customerData?.map((cust:any) =>{return{label: cust.name,
          value: cust.id}})
        const sortedCustomersList =  transformedCustomersList?.sort((a, b) => a.label.trim()?.toLowerCase()?.localeCompare(b.label.trim()?.toLowerCase()));
        setCustomerList(sortedCustomersList || []);
    } catch (err) {
      console.error(err);
    }
  };
  
  const [questionCounter, setQuestionCounter] = useState<number>(0);
  const handleAddRow = () => {
    setTableData((prevTableData) => {
      const newQuestion = {
        id: props.editMode ? `new_${questionCounter}` : Date.now(),
        question: "",
        comments_enabled: false,
        is_active:true
      };
  
      return [...prevTableData, newQuestion];
    });
    setQuestionsError(null);
    setQuestionValidation(true);

    if (props.editMode) {
      setQuestionCounter((prevCounter) => prevCounter + 1);
    }
  };

  const [questionToDelete, setQuestionToDelete] = useState<number[] | null>(null);

  const handleDeleteQuestion = async (record) => {
    
    setQuestionToDelete((prev) => [...(prev || []), record.id]);
    setTableData((prevTableData) =>
      prevTableData.filter((item) => item.id !== record.id)
    );
  };
  const handleSwitchChange = (record) => {
    // Update the is_active value when the switch is toggled
    const newData = [...tableData];
    const index = newData.findIndex((item) => record.id === item.id);
    const item = newData[index];

    item.is_active = !item.is_active;

    setTableData(newData);
  };

  const columns = [
    {
      title: "Questionnaire",
      dataIndex: "question",
      key: "question",
      render: (text, record) => (
        <Input
          value={record.question} 
          onChange={(e) =>
            handleInputChange(e.target.value, record, "question")
          }
          required
        />
      ),
    },
    {
      title: "Capture comments",
      dataIndex: "comments_enabled",
      key: "comments_enabled",
      render: (text, record) => (
        <Col span={24} style={{ textAlign: 'center' }}>
        <Button
          type="link"
          style={{ color: record.comments_enabled ? '#1890ff' : 'inherit', fontSize: '24px' }}
          onClick={() => handleToggleCaptureComments(record)}
        >
          <CheckCircleOutlined />
        </Button>
      </Col>
      ),
    },
    {
  title: props.editMode ? "Is Active" : "Action",      
  dataIndex: "is_active",
  key: "action",
  render: (text, record) => (
    <Col span={24} style={{ textAlign: 'center' }}>
      {props.editMode ? (
        
        <Switch
          checked={record.is_active} 
          onChange={() => handleSwitchChange(record)}           
        />
      ) : (
        <span onClick={() => handleDeleteQuestion(record)}>
          <img src={deleteImage} style={{ cursor: "pointer" }} />
        </span>
      )}
    </Col>
  ),
},
  ];

  const intervalOptions = [
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Yearly", label: "Yearly" },
  ];
  const dropdownOptions = [
    { value: "Project", label: "Project" },
    { value: "Customer", label: "Customer" },
    { value: "Employee", label: "Employee" },
  ];

  const handleDrawerClose = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm();
    }
    setTableData([{ question: "", comments_enabled: false,is_active:true }]);
    setScoringTableData([{ ratingColor: "", color_code: "", score_from: "", score_to: "" }]);
    props.onClose();   
    setscoreError(null); 
  };

  const colorOptions = [
    { value: "#FF0000", label: "Red" },
    { value: "#FF6666", label: "Light Red" },
    { value: "#FFCC00", label: "Medium Red-Yellow" },
    { value: "#FFFF66", label: "Light Yellow" },
    { value: "#FFFF00", label: "Yellow" },
    { value: "#CCFF66", label: "Light Yellow-Green" },
    { value: "#99FF00", label: "Medium Yellow-Green" },
    { value: "#66FF33", label: "Green-Yellow" },
    { value: "#00FF00", label: "Green" },
    { value: "#006600", label: "Dark Green" },
  ];


  const [colorCodeError, setColorCodeError] = useState<string | null>(null);
  const scoringTableColumns = [
    {
      title: "Rating Color",
      dataIndex: "color_code",
      key: "color_code",
      render: (text, record) => (
        <AntSelect
          value={record.color_code || undefined}
          onChange={(value) => {handleInputChangeColor(value, record, "color_code");setColorCodeError(null); }}
          style={{ width: 260 }}
          placeholder="Select Rating Color"
        >
          {colorOptions.map((option) => (
            <AntSelect.Option key={option.value} value={option.value}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    backgroundColor: option.value,
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    marginRight: "8px",
                    display: "inline-block",
                  }}
                />
                {option.label}
              </div>
            </AntSelect.Option>
          ))}
        </AntSelect>
      ),
    },    

    {
      title: "Range from",
      dataIndex: "score_from",
      key: "score_from",
      render: (text, record) => (
          <Input
            value={text}
            onChange={(e) =>
              handleInputChangeColor(e.target.value, record, "score_from")
            }
            required
          />
      ),
    },
    {
      title: "Range to",
      dataIndex: "score_to",
      key: "score_to",
      render: (text, record) => (
          <Input
            value={text}
            onChange={(e) => handleInputChangeColor(e.target.value, record, "score_to")}
            required
          />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Col span={24} style={{ textAlign: 'center' }}>
        <span onClick={() => handleRemoveScoringRow(record)}>
        <img src={deleteImage} style={{ cursor: "pointer" }} />
      </span>
      </Col>
      ),
    },
  ];

  const [scoringTableData, setScoringTableData] = useState<
{  id?: number | string; ratingColor: string; color_code: string; score_from: string; score_to: string }[]
>([
{ ratingColor: "", color_code: "", score_from: "", score_to: "" },
]);

  const handleAddScoringRow = () => {
    setScoringTableData((prevScoringTableData) => [
      ...prevScoringTableData,
      {
        id: props.editMode ? `new_${Date.now()}` : Date.now(),
        ratingColor: "",
        score_from: "",
        score_to: "",
        color_code: "",
      },
    ]);
  };
  const [scoringRowToDelete, setScoringRowToDelete] = useState<number[]>([]);
    const handleRemoveScoringRow = (record) => {
      setScoringTableData(
        scoringTableData.filter((item) => item.id !== record.id)
      );
      setScoringRowToDelete((prev) => [...prev, record.id]);
    };

    const [questionValidation, setQuestionValidation] = useState<boolean>(true);
    const [containsOnlySpaces, setContainsOnlySpaces] = useState<boolean>(false);
    const [questionsError, setQuestionsError] = useState<string | null>(null);

    const validateQuestions = () => {
      const containsOnlySpaces = tableData.some((item) => /^\s+$/.test(item.question));
      const isValid = !containsOnlySpaces && tableData.length > 0;
      setQuestionValidation(isValid);
      setContainsOnlySpaces(containsOnlySpaces);

      
      setQuestionsError(containsOnlySpaces ? "Questions should not contain only spaces" : null);

      return isValid;
    };
    
  return (
    <>
        <Drawer
        title={props.editMode ? "Edit Review Form" : "Add Review Form"}
        width={800}
        onClose={handleDrawerClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_creditNote"
      >
                <Formik
                  initialValues={{
                    id: 0, 
                    review_type: "",
                    interval: "",
                    entity: "",
                    description: "",
                    project_ids: props.editMode && props.editItem?.project_ids ? JSON.parse(props.editItem.project_ids).map((id: number) => {
                      const project:any = projectList.find((proj:any) => proj.value === id);
                      return project ? { label: project.label, value: project.value } : { label: "", value: id };
                    }) : [],
                    customer_ids: props.editMode && props.editItem?.customer_ids ? JSON.parse(props.editItem.customer_ids).map((id: number) => {
                      const customer:any = customerList.find((cust:any) => cust.value === id);
                      return customer ? { label: customer.label, value: customer.value } : { label: "", value: id };
                    }) : [],
                    min_rating: 0,
                    max_rating: 0,
                    access_to_all:false,
                    questions: props.editMode
                    ? (props.editItem?.questions || []).map((question) => ({
                        ...question,
                        
                      }))
                    : [
                        {
                          question: "",
                          is_active: false,
                          comments_enabled: false,
                        },
                      ],
                    review_scoring: props.editMode
                    ? (props.editItem?.review_scoring || []).map((scoring) => ({
                        ...scoring,
                        
                      }))
                    : [
                        {
                          rating_color: "",
                          color_code: "",
                          score_from: 0,
                          score_to: 0,
                        },
                      ],
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { resetForm }) => {
                    if(scoringTableData?.length === 0){
                       notification.error({message:"Please add atleast one score color..."})
                       return;
                    }
                    if (rangeError) {
                      console.error("Validation error: Score ranges should not overlap");
                      return;
                    }
                

                    const { auditData } = props; 
                    if (!validateQuestions()) {
                      
                      console.error("Questions should not contain only spaces");
                      return;
                    }
                   
                    const inputReviewType = values.review_type.toLowerCase();

                    const reviewTypeAlreadyExists = auditData.some(
                      (item) => item.review_type.toLowerCase() === inputReviewType && item.id !== values.id
                    );
                
                    if (reviewTypeAlreadyExists) {
                      setReviewTypeExists(true);
                      return;
                    } else {
                      setReviewTypeExists(false);
                    }
                   

                    const hasEmptyColorCode = scoringTableData.some((row) => !row.color_code);

                    if (hasEmptyColorCode) {
                      setColorCodeError("Please select a color code for all scoring rows.");
                      return;
                    }  else {
                      setColorCodeError(null); 
                    }
                    try {
                     
                      if (questionToDelete) {
                        for (const questionId of questionToDelete) {
                          const deleteResponse = await AxiosConfig.delete(`audit/response/question/${questionId}`, {
                            headers: { Authorization: `Bearer ${token}` },
                          });
                        
                          if (deleteResponse.status === 200) {
                            console.log(`Question ${questionId} deleted successfully`);
                          } else {
                            console.error(`Unsuccessful question deletion for question ${questionId}:`, deleteResponse);
                          }
                        }
                      }
                      setQuestionToDelete([]);

                      if (scoringRowToDelete.length > 0) {
                        for (const scoringId of scoringRowToDelete) {
                          const deleteScoringResponse = await AxiosConfig.delete(
                            `audit/response/score/${scoringId}`,
                            {
                              headers: { Authorization: `Bearer ${token}` },
                            }
                          );
                      
                          if (deleteScoringResponse.status === 200) {
                            console.log(`Scoring row ${scoringId} deleted successfully`);
                          } else {
                            console.error(
                              `Unsuccessful scoring row deletion for scoring ${scoringId}:`,
                              deleteScoringResponse
                            );
                          }
                        }
                        setScoringRowToDelete([]);
                      }

                const minRating = parseFloat(String(values.min_rating));
                const maxRating = parseFloat(String(values.max_rating));
                  
                const hasInvalidRanges = scoringTableData.some((row) => {
                const scoreFrom = parseFloat(row.score_from);
                const scoreTo = parseFloat(row.score_to);
                
                return scoreFrom < minRating || scoreTo > maxRating;
              });
                
              if (hasInvalidRanges) {
               
              const errorMessage = "Score ranges should be within the specified minimum and maximum rating.";
              console.error("Validation error:", errorMessage);
            
              setTimeout(function () {
                 
                setscoreError(errorMessage); 
              }, 500);
              return; 
            } else {
            setscoreError(null); 
        }

                    let requestData;
                    const selectedProjectIdsArray = values?.project_ids?.map((proj:any) => proj?.value);
                    const selectedCustomersIdsArray = values?.customer_ids?.map((cust:any) => cust?.value);
                    if (values.id) {
                      requestData = {
                        id: values.id,
                        review_type: values.review_type,
                        interval: values.interval,
                        entity: values.entity,
                        description: values.description,
                        min_rating: values.min_rating,
                        project_ids: selectedProjectIdsArray?.length > 0 ? selectedProjectIdsArray : [],
                        customer_ids: selectedCustomersIdsArray?.length > 0 ?  selectedCustomersIdsArray : [],
                        access_to_all:accessToAll,
                        max_rating: values.max_rating,
                        questions: tableData.map((item, index) => {
                          if (item.id && typeof item.id === 'string' && item.id.startsWith('new_')) {
                            // Newly added question in edit mode
                            return {
                              question: item.question,
                              is_active: item.is_active,
                              comments_enabled: item.comments_enabled,
                              order_index: index + 1, // Increment index to start from 1
                            };
                          } else if (!item.id) {
                            // New question in add mode
                            return {
                              question: item.question,
                              is_active: item.is_active,
                              comments_enabled: item.comments_enabled,
                              order_index: index + 1, // Increment index to start from 1
                            };
                          } else {
                            // Editing existing question
                            return {
                              id: item.id,
                              question: item.question,
                              is_active: item.is_active,
                              comments_enabled: item.comments_enabled,
                            };
                          }
                        }),
                        review_scoring: scoringTableData.map((item) => {
                          if (item.id && typeof item.id === 'string' && item.id.startsWith('new_')) {
                            // Newly added scoring in edit mode
                            return {
                              rating_color: colorOptions.find((option) => option.value === item.color_code)?.label,
                              color_code: item.color_code,
                              score_from: item.score_from,
                              score_to: item.score_to,
                            };
                          } else if (!item.id) {
                            // New scoring in add mode
                            return {
                              rating_color: colorOptions.find((option) => option.value === item.color_code)?.label,
                              color_code: item.color_code,
                              score_from: item.score_from,
                              score_to: item.score_to,
                            };
                          } else {
                            return {
                              id: item.id,
                              rating_color: colorOptions.find((option) => option.value === item.color_code)?.label,
                              color_code: item.color_code,
                              score_from: item.score_from,
                              score_to: item.score_to,
                            };
                          }
                        }),
                      };
                    } else {
                      requestData = {
                        review_type: values.review_type,
                        access_to_all:accessToAll,
                        project_ids: selectedProjectIdsArray?.length > 0 ? selectedProjectIdsArray : [],
                        customer_ids: selectedCustomersIdsArray?.length > 0 ?  selectedCustomersIdsArray : [],
                        interval: values.interval,
                        entity: values.entity,
                        description: values.description,
                        min_rating: values.min_rating,
                        max_rating: values.max_rating,
                        questions: tableData.map((item, index) => ({
                          question: item.question,
                          is_active: item.is_active,
                          comments_enabled: item.comments_enabled,
                          order_index: index + 1, // Increment index to start from 1
                        })),
                        review_scoring: scoringTableData.map((item) => ({
                          rating_color: colorOptions.find((option) => option.value === item.color_code)?.label,
                          color_code:item.color_code,
                          score_from: item.score_from,
                          score_to: item.score_to,
                        })),
                      };
                    }
                    try {
                      const response = await AxiosConfig.post("reviewSettings", requestData, {
                        headers: { Authorization: `Bearer ${token}` },
                      });
                      if (response.status === 200) {
                        notification.success({
                          message: values.id
                            ? "Audit Review updated successfully!"
                            : "Audit Review added successfully!",
                        });
                        setTimeout(function () {
                          window.location.reload();
                        }, 1000);
                      }
                    } catch (error) {
                      console.error("Audit submission error:", error);
                    }
                  
                    resetForm();
                  }catch (error) {
                    console.error("Error:", error);
                  }
                  }}

                 
                  
          innerRef={formikRef}

          
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
             <Row>
              <Col span={12}>
              <div style={{ marginBottom: '16px', marginRight: '40px' }}>
                  <label style={{ paddingBottom: 10, display: 'block' }}>{"Review Title"}</label>
                  <Input
                  
                    className="review-type-input"
                    name="review_type"
                    placeholder="Enter Review Type"
                    onChange={(e) => {
                      handleChange(e);
                      setReviewTypeExists(false); 
                    }}
                    value={values.review_type}
                  />
                  <p className="display_error">{errors.review_type}</p>
                  {reviewTypeExists && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  Review type already exists.
                </div>
              )}
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <label>{"Interval"}</label>
                  {props.editMode ? (
                      <Input
                        value={values.interval}
                        disabled
                        style={{ marginTop: '10px' }}
                      />
                    ) : (
                  
                  <Select
                    name="interval"
                    placeholder="Select Interval"
                    options={intervalOptions}
                    onChange={(selectedOption) => {
                      setFieldValue("interval", selectedOption?.value);
                    }}
                    value={intervalOptions.find((option) => option.value === values.interval)}
                  />
                     )}
                  <p className="display_error">{errors.interval}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div>
                  <Row>
                    <Col span={12}>
                        <label> {"Access to All"}</label>
                        <span >
                          <ReactSwitch
                            onChange={(e)=>{setAccessToAll(e)}}
                            checked={accessToAll}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={36}
                            height={20}
                            handleDiameter={16}
                            onColor="#5389F8"
                            offColor="#BFCDDA"
                            className="apply_to_all"
                          />
                        </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
              <Row>
                <Col span={12}>
                <div style={{ marginTop: "30px", marginRight: "40px" }}>
                <label style={{ position: "relative", top: "-10px", zIndex: 1 }}>
                  {"Description"}
                </label>
                <TextArea
                  placeholder="Enter the Description"
                  rows={5}
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  style={{ zIndex: 0 }}
                />
                <p className="display_error">{errors.description}</p>
              </div>
                </Col>
                <Col span={12}>
                  <div style={{ marginTop: "20px" }}>
                    <label>{"Entity"}</label>
                    {props.editMode ? (
                      <Input
                        value={values.entity}
                        disabled
                        style={{  marginTop: '10px' }}
                      />
                    ) : (
                    <Select
                      name="entity"
                      placeholder="Select Entity"
                      options={dropdownOptions}
                      onChange={(selectedOption) => {
                        setFieldValue("entity", selectedOption?.value);
                      }}
                    value={dropdownOptions.find((option) => option.value === values.entity)}

                    />
                    )}
                    <p className="display_error">{errors.entity}</p>
                  </div>
                </Col>
              </Row>

              {values.entity ==="Project" && (
                <div className="mt-20">
                  <Row>
                    <Col span={12}>
                      <div className="mt-40">
                        <label>
                          {"Projects"}
                        </label>
                        <Select
                          isMulti
                          isClearable
                          options={projectList}
                          placeholder="Select Projects"
                          name="project_ids"
                          value={values.project_ids}
                          onChange={(selectedValue) => {
                            setFieldValue(
                              "project_ids",
                              selectedValue.map((row) => ({
                                label: row.label,
                                value: row.value,
                              }))
                            );
                          }}
                        />
                        <p className="display_error">{errors.project_ids}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {values.entity ==="Customer" && (
                <div className="mt-20">
                  <Row>
                    <Col span={12}>
                      <div className="mt-40">
                        <label>
                          {"Customer"}
                        </label>
                        <Select
                          isMulti
                          isClearable
                          options={customerList}
                          placeholder="Select Customer"
                          name="customer_ids"
                          value={values.customer_ids}
                          onChange={(selectedValue) => {
                            setFieldValue(
                              "customer_ids",
                              selectedValue.map((row) => ({
                                label: row.label,
                                value: row.value,
                              }))
                            );
                          }}
                        />
                        <p className="display_error">{errors.customer_ids}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <div style={{ marginTop: "40px" }}>
                <Row justify="space-between">
                  <Col span={12}>
                    <div className="sub-heading">
                      <h2>Review Questions & Ratings</h2>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div style={{ textAlign: "right" }}>
                    <ButtonReuse
                      type="primary"
                      className="primary-btn"
                      onClick={handleAddRow}
                      value="Add More"
                      style={{ marginBottom: "20px" }}
                    />
                  </div>
                  </Col>
                </Row>
              </div>

              <Form.Item
                validateStatus={questionValidation && !containsOnlySpaces ? 'success' : 'error'}
                help={
                  !questionValidation
                    ? 'Question field can not be empty'
                    : containsOnlySpaces
                    ? 'Questions should not contain only spaces'
                    : ''
                }
              >


              <Table dataSource={tableData} columns={columns} bordered pagination={false} />

                  {questionsError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {questionsError}
                </div>
              )}
                </Form.Item>
              <Row style={{ paddingTop: "20px" }}>
                <Col span={12}>
                  <div style={{ marginRight: "40px" }}>
                    <label style={{ display: "block", marginBottom: "8px" }}>
                      {"Min Rating"}
                    </label>
                    <InputNumber
                      style={{ width: "300px", height: "40px" }}
                      name="min_rating"
                      placeholder="Min Rating"
                      onChange={(value) => setFieldValue("min_rating", value)}
                      value={values.min_rating}
                      disabled={props.editMode}
                    />
                     <p className="display_error">{errors.min_rating}</p>
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <label style={{ display: "block", marginBottom: "8px" }}>
                      {"Max Rating"}
                    </label>
                    <InputNumber
                      style={{ width: "300px", height: "40px" }}
                      name="max_rating"
                      placeholder="Max Rating"
                      onChange={(value) => setFieldValue("max_rating", value)}
                      value={values.max_rating}
                      disabled={props.editMode}
                    />
                     <p className="display_error">{errors.max_rating}</p>
                  </div>
                </Col>
              </Row>

              <div style={{ marginTop: "40px" }}>
                <Row justify="space-between">
                  <Col span={12}>
                    <div className="sub-heading">
                      <h2>Review Scoring</h2>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ textAlign: "right" }}>
                      <ButtonReuse
                        type="primary"
                        className="primary-btn"
                        onClick={handleAddScoringRow}
                        value="Add More"
                        style={{ marginBottom: "20px" }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <Table
                dataSource={scoringTableData}
                columns={scoringTableColumns}
                bordered
                pagination={false} 
                className="reviewTab"
              />
                {colorCodeError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {colorCodeError}
                </div>
              )}
              {rangeError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {rangeError}
                </div>
              )}
                {scoreError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  {scoreError}
                </div>
              )}

               <Row style={{ marginTop: "20px" }}>
                  <Col span={7}>
                    <ButtonReuse
                      type="primary"
                      className="primary-btn"
                      htmlType="submit"
                      value={props.editMode ? "Save Review Form" : "Add Review Form"}
                    ></ButtonReuse>
                  </Col>
                  <Col span={4}>
                    <ButtonReuse
                      type="primary"
                      className="cancel-button"
                      value="Cancel"
                      onClick={handleDrawerClose}
                      style={{ marginLeft: "10px" }}
                    ></ButtonReuse>
                  </Col>
                </Row>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddReviewOverlay;
