import { useState, useEffect, useRef } from "react";
import { notification, Typography, Select as AntSelect, Input } from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import Select from "react-select";
import moment from "moment";
import ButtonReuse from "../../../components/Buttons/Button";
import { Formik } from "formik";
import dayjs from "dayjs";
import edit from "../../../assets/images/edit.png";
import ViewAttachment from "./ViewAttachment";
import EditPOAttachments from "./EditPOAttachments";
import UploadPOAttachment from "./UploadPOAttachments";
import { Row, Col, Modal, Image, Button, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { InputField } from "../../../components";
import "./PO.scss";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import { Axios } from "../../../utils/axios";
import {
  convertCurrency,
  convertNumber,
  errorNotification,
  // formatCurrencyValue,
  formatCurrencyValueDecimal,
  formatCurrencyValueinPO,
} from "../../../utils/util";
import { useSelector } from "react-redux";
import { calculateSoldPerStu } from "../../../utils/util";
import { po } from "../../../utils/enums";
import { preventMinus } from "../../../utils/util";
// import { getCurrencyRates } from "../../../stores/actions/getCurrencyAction";


const PO = (props: {
  poDetails?: any;
  newitems?: any;
  setNewItems?: any;
  POid?: any;
  poAttachmentIds?: any;
  onClose?: any;
  currencyName?: any;
  currencySymbol?: any;
  currencyRate?: any;
  mastercurrencies?: any;
  archiveStatus?: any;
  props?: any;
  addEditAccess:any;
}) => {
  let token = getToken();
  const dateFormat = "YYYY/MM/DD";

  const fileData: any = useRef({});
  const { addEditAccess }= props
  const { Text } = Typography;
  const { Option } = AntSelect;
  const [isDisabledIput, setIsDisabledInput] = useState(true);
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleDelete, setisModalVisibleDelete] = useState(false);
  const [isModalVisibleUpdate, setisModalVisibleUpdate] = useState(false);
  const [image, setImage] = useState<any>([]);
  const [editImage, setEditImage] = useState<any>([]);
  const [poType, setPoType] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState(0);
  const [currencyList, setCurrencyList] = useState();
  const [valueAmt, setValueAmt] = useState(0);
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  let image_arr: any = [];
  let edit_image_arr: any = [];
  const poStatusList = useRef([
    { value: "Verbal commit", label: "Verbal commit" },
    { value: "Email commit", label: "Email commit" },
    { value: "Letter of Intent", label: "Letter of Intent" },
    { value: "PO received", label: "PO received" },
  ]);
  const poTypeOptions = poType.map(function (poType: {
    type: any;
    label: any;
    id: any;
  }) {
    return {
      value: poType.id,
      label: poType.type,
      key: poType.id,
    };
  });


const isEditableString = localStorage.getItem("isEditable");
const isEditable = isEditableString === 'true'; 
  const handleCancel = () => {
    setIsDisabledInput(true);
  };
  const deleteData = () => {
    AxiosConfig.delete("/purchaseorders/" + props.POid, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data.status.code == 200) {
        const notify = () => {
          notification.open({
            message: res.data.status.message,
          });
        };
        {
          notify();
        }
        window.setTimeout(function () {
          location.reload();
        }, 1000);
      }
    });
  };
  useEffect(() => {
    getPoType();
  }, []);

  const getPoType = async () => {
    let res = await AxiosConfig.get("potypes", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setPoType(res.data.data.poTypes);
  };

  const convertPoType = (po_type_id: any) => {
    if (po_type_id) {
      if (po_type_id === 1) {
        return "Fixed Price";
      } else if (po_type_id === 2) {
        return "Time & Material";
      } else if (po_type_id === 3) {
        return "Retainer";
      }
    } else {
      return "NA";
    }
  };

  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const showViewModalImage = () => {
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };
  const showModalDelete = () => {
    setisModalVisibleDelete(true);
  };

  const showModalUpdate = () => {
    setisModalVisibleUpdate(true);
  };
  const handleCancelUpdateModal = () => {
    setisModalVisibleUpdate(false);
  };
  const handleCancelDeleteModal = () => {
    setisModalVisibleDelete(false);
  };
  const images = image.map((img_url: any, index: any) => {
    const fileNameWithExtension = fileData["current"][img_url]["attachment_id"];
    const fileName = fileNameWithExtension.split("_").slice(0, -1).join("_");

    const fileType = fileNameWithExtension.split(".").pop();
    {
      return (
        <div className="attachment" key={index}>
          {fileType.toLowerCase() === "pdf" ? (
            <div className="pdf-placeholder">
              <img
                src={pdfPlaceholderImage}
                alt="PDF Placeholder"
                className="pdf-placeholder-image"
              />
            </div>
          ) : fileType.toLowerCase() === "xlsx" ? (
            <div className="xlsx-placeholder">
              <img
                src={xlsxPlaceholderImage}
                alt="XLSX Placeholder"
                className="xlsx-placeholder-image"
              />
            </div>
          ) : fileType.toLowerCase() === "docx" ? (
            <div className="docx-placeholder">
              <img
                src={docxPlaceholderImage}
                alt="DOCX Placeholder"
                className="docx-placeholder-image"
              />
            </div>
          ) : (
            <Image
              style={{
                border: "1px solid #989898",
                borderRadius: "4px",
              }}
              width={62}
              height={62}
              src={img_url}
              alt="Attachment"
            />
          )}
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size="small"
            onClick={() => handleDownload(img_url)}
          />
          <text>{fileName}</text>
        </div>
      );
    }
  });

  useEffect(() => {
    props.poAttachmentIds.forEach(function (row: any) {
      const attachment_id = row.attachment_id;
      getAttachments(attachment_id);
    });
  }, [props.poAttachmentIds]);

  const getAttachments = async (attachment_id: any) => {
    AxiosConfig.get("purchaseorders/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      let image_obj: any = {};
      const url: any = URL.createObjectURL(res.data);
      fileData["current"][url] = { data: res.data, attachment_id };
      image_obj["id"] = attachment_id;
      image_obj["url"] = url;
      edit_image_arr.push(image_obj);
      image_arr.push(url);
      setEditImage(edit_image_arr);
      setImage(image_arr);
    });
  };
  const handleDownload = (file_id: any) => {
    saveAs(
      fileData["current"][file_id]["data"],
      fileData["current"][file_id]["attachment_id"].split("^").pop()
    );
  };
  const handleChangeEvent = (event: any) => {
    props.setNewItems({
      ...props.newitems,

      [event.target.name]: event.target.value,
    });
  };

  const handleChangeEventDate = (val: any) => {
    props.setNewItems({
      ...props.newitems,
      sale_date: val,
    });
  };
  const getCurrencyDropdown = async () => {
    const tempList: any = (
      <AntSelect
        defaultValue={props.poDetails.currency_id}
        className="select-before"
        onChange={(value: any) => {
          setSelectedCurrency(value);
          setSelectedCurrencyRate(props.currencyRate[value]);
        }}
      >
        {props.mastercurrencies.map((currency: any, index: any) => (
          <Option key={index} value={currency.currencyrates.currency_id}>
            {currency.currency_code}
          </Option>
        ))}
      </AntSelect>
    );
    setCurrencyList(tempList);
  };

  useEffect(() => {
    setSelectedCurrencyRate(props.poDetails.currency_rate);
    setValueAmt(props.poDetails.value);
    getCurrencyDropdown();
  }, [props.poDetails]);

  const [initialFormState, setInitialFormState] = useState<any>({
    supersec_pwd: "",
    status: "",
  });

  const showEditModal = () => {
    setIsModalVisibleEdit(true);
  };

  const handleClick = () => {
    if (isEditable) {
      setIsModalVisible(true);
    } else {
      notification.open({
        message: "",
        description: "To edit/add values, please switch to base currency editable mode",
        style: { color: "red" },
      });
    }
  };
  const handleCancelVerifyModel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const response = await Axios.put(
        "auth/verifySuperSecretPassword",
        initialFormState
      );

      if (response) {
        setIsModalVisible(false);
        setIsDisabledInput(false);
      } else {
        errorNotification("");
      }
    } catch (error: any) {
      errorNotification("Invalid Super secret password");
    }
  };

  const refreshPage = () => {
    props.onClose();
  };


  const updateData = () => {
    let data:any = { ...props.newitems }; 
    delete data.sales_allocation;
    delete data.customer_relation;
    delete data.customer_name;
    if (props.poDetails.currency_change_status) {
      data.currency_id = selectedCurrency;
      data.currency_rate = selectedCurrencyRate;
    }
    data.value_in_base = data.value * selectedCurrencyRate;

    AxiosConfig.put(`purchaseorders/${props.POid}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status == 200) {
          setIsDisabledInput(true);
          refreshPage()
          const notify = () => {
            notification.open({
              message: (
                <span style={{ color: "green" }}>
                  {response.data.status.message}
                </span>
              ),
            });
          };
          {
            notify(); 
          }
        }
      })
      .catch(function (error) {
        const notify = () => {
          notification.open({
            message: (
              <span style={{ color: "red" }}>
                {error.response.data.status.message}
              </span>
            ),
          });
        };
        {
          notify();
        }
      });
    props.onClose();
  };
  const customerRelationTitle = props.newitems.customer_relation
    ? props.newitems.customer_relation.title
    : null;

    const SoldbyStuvalue =  Number(calculateSoldPerStu(
      props.newitems.value_in_base,
      props.newitems.projected_expenses,
      props.newitems.stus_sold
    ))

  return (
    <>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancelVerifyModel}
        className="password-modal"
      >
        <label>{po.labelForSuperSecretPass}</label>
        <p>{po.textNote}</p>
        <InputField
          placeholder="Enter password"
          name="supersec_pwd"
          onChange={(event: any) => {
            setInitialFormState({
              ...initialFormState,
              supersec_pwd: event.target.value,
            });
          }}
          className="input--field"
          type="password"
        ></InputField>
      </Modal>
      {addEditAccess && <img src={edit} onClick={handleClick} />}
      <Formik
        initialValues={
          props.poDetails.currency_change_status
            ? {
                po_number: props.poDetails.number,
                po_name: props.poDetails.name,
                po_status: props.poDetails.po_status,
                desc: props.poDetails.desc,
                value: props.poDetails.value,
                value_in_base: props.poDetails.value_in_base,
                expense: props.poDetails.expense,
                stus_sold: props.poDetails.stus_sold,
                sale_date: props.poDetails.sale_date,
                po_type_id: props.poDetails.po_type_id,
                currency_id: props.poDetails.currency_id,
                currency_rate: props.poDetails.currency_rate,
                projected_expenses: props.poDetails.projected_expenses,
              }
            : {
                po_number: props.poDetails.number,
                po_name: props.poDetails.name,
                po_status: props.poDetails.po_status,
                desc: props.poDetails.desc,
                value: props.poDetails.value,
                expense: props.poDetails.expense,
                value_in_base: props.poDetails.value_in_base,
                stus_sold: props.poDetails.stus_sold,
                sale_date: props.poDetails.sale_date,
                po_type_id: props.poDetails.po_type_id,
                projected_expenses: props.poDetails.projected_expenses,
              }
        }
        onSubmit={() => {
          showModalUpdate();
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {isDisabledIput && (
              <>
                <Row>
                  <Col span={6} className="column-with-border">
                    <label>{po.labelForPoNumber} </label>
                    <p className="display_project">
                      {props.newitems.po_number}
                    </p>
                    <label>{po.labelForPoName}</label>
                    <p className="display_project">{props.newitems.po_name}</p>
                    <label>{po.labelForPoTypes} </label>
                    <p className="display_project">
                      {convertPoType(props.newitems.po_type_id)}
                    </p>
                    <label>{po.labelForPoStatus}</label>
                    <p className="display_project">
                      {props.newitems.po_status ?? "Not Applicable"}
                    </p>
                    <label>{po.labelForSaleDate}</label>
                    <p className="display_project">
                      {dayjs(props.newitems.sale_date).format("YYYY/MM/DD")}
                    </p>
                  </Col>
                  <Col span={6} className="column-with-border">
                    <label>{po.labelForValueSold}</label>
                    <p className="display_project" style={{ display: "grid" }}>
                     
                    <Text>
  {isEditable ? (
    <>
      {formatCurrencyValueinPO(
        currencySymb?.currency_code,
        props.poDetails.value,
        props.currencySymbol[props.poDetails.currency_id]
      )}
      <br />
      {formatCurrencyValueinPO(
        currencySymb?.currency_code,
        props.poDetails.value,
        props.currencySymbol[props.poDetails.currency_id]
      ) !== formatCurrencyValueDecimal(
        currencySymb?.currency_code,
        props.poDetails.value_in_base
      ) && (
        <Text type="secondary">
          {formatCurrencyValueDecimal(
            currencySymb?.currency_code,
            props.poDetails.value_in_base
          )}
        </Text>
      )}
    </>
  ) : (
    convertCurrency(props.poDetails.value_in_base, currencyRate)
  )}
</Text>

                    </p>
                    <label>{po.labelForDirectandNonbyStuExpense}</label>
                    <p className="display_project" style={{ display: "grid" }}>
                      {convertCurrency(
                        
                        props.poDetails.expense,
                        currencyRate
                      )}
                    </p>
                    <label>{po.labelForProjectedDirectExpense}</label>
                    <p className="display_project">
                      {/* {`₹${(props.poDetails.projected_expenses ?? 0).toFixed(
                        2
                      )}`} */}
                      {convertCurrency(
                        
                        props.poDetails.projected_expenses,
                        currencyRate
                      )}
                      
                    </p>
                    <label>{po.labelForStusold}</label>
                    <p className="display_project">
                      {convertNumber(Number(props.newitems.stus_sold), 2)}
                    </p>
                    <label>{po.labelForSoldbyStu}</label>
                    <p className="display_project">
                   
                      {convertCurrency(
                        
                        SoldbyStuvalue,
                        currencyRate
                      )}
                    </p>
                  </Col>
                  <Col span={12}>
                    <label>{po.labelForCustomer}</label>
                    <p className="display_project">
                      {props.newitems.customer_name}
                    </p>
                    <label>{po.labelForCustomerRelation}</label>
                    <p className="display_project">{customerRelationTitle}</p>
                    <label>{po.labelForPoDesc}</label>
                    <p className="display_project_customer_relation">
                      {props.newitems.desc}
                    </p>
                  </Col>
                  <Col span={6}>
                    <label>{po.labelForAttachments}</label>
                    <Row>
                      {addEditAccess &&
                        <ButtonReuse
                          value="View"
                          type="submit"
                          className="edit-image-button"
                          onClick={showViewModalImage}
                        />
                      }
                      <div className="attachments-container">
                        <Modal
                          title="Attachments"
                          className="AttachmentsModal"
                          visible={isModalVisibleImage}
                          onCancel={handleCancelModalImage}
                        >
                          <ViewAttachment images={images} />
                        </Modal>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <div className="po-form">
              {!isDisabledIput && (
                <>
                  <Row>
                    <Col span={8} className="column-with-border">
                      <label>{po.labelForPoNumber}</label>
                      <input
                        name="po_number"
                        onChange={handleChangeEvent}
                        className="input--field"
                        value={props.newitems.po_number}
                        required
                      ></input>
                      <p className="display_project"></p>
                      <label> {po.labelForPoName} </label>
                      <input
                        name="po_name"
                        onChange={handleChangeEvent}
                        className="input--field"
                        value={props.newitems.po_name}
                        required
                      ></input>
                      <p className="display_project"></p>
                      <label>{po.labelForPoTypes} </label>
                      <Select
                        placeholder="Select Project Types"
                        options={poTypeOptions}
                        onChange={(value: { value: any }) => {
                          props.setNewItems({
                            ...props.newitems,
                            po_type_id: value.value,
                          });
                        }}
                        value={{
                          label: convertPoType(props.newitems.po_type_id),
                        }}
                        name="po_type_id"
                        className="input--field"
                        isDisabled={
                          localStorage.getItem("isEarning") === "1"
                            ? true
                            : false
                        }
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      <p className="display_project"></p>
                      <label>{po.labelForPoStatus}</label>
                      <Select
                        placeholder="Select PO Status"
                        options={poStatusList.current}
                        onChange={(value: { value: any }) => {
                          props.setNewItems({
                            ...props.newitems,
                            po_status: value.value,
                          });
                        }}
                        value={{ label: props.newitems.po_status }}
                        name="po_status"
                        className="input--field"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      <p className="display_project"></p>
                      <label>{po.labelForSaleDate}</label>
                      <DatePicker
                        format={dateFormat}
                        placeholder="Enter the Sales Date"
                        name="sale_date"
                        onChange={(date) => {
                          handleChangeEventDate(date);
                        }}
                        value={
                          props.newitems.sale_date
                            ? moment(props.newitems.sale_date, dateFormat)
                            : null
                        }
                        className="input--field"
                      />
                      <p className="display_project"></p>
                    </Col>
                    <Col span={6} className="column-with-border">
                      <label>{po.labelForValueSold} </label>
                      <Input
                        onChange={(event: any) => {
                          setValueAmt(event.target.value);
                          return handleChangeEvent(event);
                        }}
                        name="value"
                        className="input--field"
                        addonBefore={
                          props.poDetails.currency_change_status
                            ? currencyList
                            : props.currencySymbol[props.poDetails.currency_id]
                        }
                        value={props.newitems.value}
                        required
                      ></Input>
                      <p className="display_project"></p>
                      <Text type="secondary">
                        {po.labelForConversionRate}
                        {currencySymb?.currency_symbol}
                        {selectedCurrencyRate} | {currencySymb?.currency_code}:
                        {currencySymb?.currency_symbol}
                        {(selectedCurrencyRate * valueAmt).toFixed(2)}
                      </Text>
                      <p className="display_project"></p>
                      <p className="display_project"></p>
                      <label>{po.labelForProjectedDirectExpense}</label>
                      <Input
                        type="number"
                        onKeyPress={preventMinus}
                        onChange={handleChangeEvent}
                        addonBefore={"₹"}
                        name="projected_expenses"
                        className="input--field"
                        value={props.newitems.projected_expenses || 0}
                      ></Input>
                      <p className="display_project"></p>
                      <label> {po.labelForStusold} </label>
                      <Input
                        name="stus_sold"
                        onChange={handleChangeEvent}
                        className="input--field"
                        value={props.newitems.stus_sold}
                        required
                      ></Input>
                      <p className="display_project"></p>
                      <label>{po.labelForSoldbyStu}</label>
                      <Input
                        name="sold_by_stus"
                        className="input--field"
                        value={formatCurrencyValueDecimal(
                          currencySymb?.currency_code,
                          calculateSoldPerStu(
                            valueAmt ? (selectedCurrencyRate * valueAmt).toFixed(2) : props.poDetails.value_in_base ,
                            props.newitems.projected_expenses,
                            props.newitems.stus_sold
                          )
                        )}
                        disabled
                      ></Input>
                      <p className="display_project"></p>
                    </Col>

                    <Col span={8}>
                      <label>{po.labelForCustomer}</label>
                      <Input
                        name="customer"
                        className="input--field"
                        value={props.newitems.customer_name}
                        disabled
                      ></Input>
                      <p className="display_project"></p>

                      <label>{po.labelForCustomerRelation}</label>
                      <Input
                        name="customer_relation"
                        className="input--field"
                        value={customerRelationTitle}
                        disabled
                      ></Input>
                      <p className="display_project"></p>

                      <label>{po.labelForPoDesc}</label>
                      <textarea
                        name="desc"
                        rows={3}
                        onChange={handleChangeEvent}
                        className="input--field"
                        value={props.newitems.desc}
                        required
                      ></textarea>
                    </Col>
                    <Col span={6}>
                      <label>{po.labelForAttachments}</label>
                      <Row>
                      {(addEditAccess && isEditable) && (
                          <ButtonReuse
                            value="Edit"
                            type="submit"
                            className="edit-image-button"
                            onClick={showEditModal}
                          />
                        )} 
                        <Modal
                          title="Attachments"
                          className="AttachmentsModal"
                          visible={isModalVisibleEdit}
                          onCancel={handleCancelModalEdit}
                        >
                          <UploadPOAttachment poId={props.POid} />
                          <EditPOAttachments images={editImage} />
                        </Modal>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <div className="edit_button_div">
                        {addEditAccess &&
                          <ButtonReuse
                            value="Update"
                            type="primary"
                            htmlType="submit"
                            className="update-button"
                          />
                        }
                        <Modal
                          title="Update Purchase Order"
                          visible={isModalVisibleUpdate}
                          onCancel={handleCancelUpdateModal}
                          cancelText="Cancel"
                          footer={false}
                          className="modal-deleteCancelBtn"
                        >
                          <p>{po.textForNote}</p>
                          <Row style={{ margin: " auto 100px" }}>
                            <Col span={12}>
                              <ButtonReuse
                                value="Update"
                                type="primary"
                                htmlType="submit"
                                onClick={updateData}
                              />
                            </Col>
                            <Col span={12}>
                              <ButtonReuse
                                value="Cancel"
                                type="submit"
                                className="modal-deleteCancelBtn"
                                onClick={handleCancelUpdateModal}
                              />
                            </Col>
                          </Row>
                        </Modal>
                        <ButtonReuse
                          value="Cancel"
                          type="primary"
                          onClick={handleCancel}
                          className="cancel-button"
                        ></ButtonReuse>
                        {addEditAccess && <ButtonReuse
                          value="Delete"
                          type="primary"
                          className="delete-button"
                          onClick={() => {
                            showModalDelete();
                          }}
                        />}
                        <Modal
                          title="Delete Purchase Order"
                          visible={isModalVisibleDelete}
                          onCancel={handleCancelDeleteModal}
                          cancelText="Cancel"
                          footer={false}
                          className="deleteModalMain"
                        >
                          <p>{po.textForDeleteModal}</p>
                          <Row>
                            <Col span={12}>
                              <ButtonReuse
                                value="Delete"
                                type="submit"
                                className="modal-deleteBtn"
                                onClick={deleteData}
                              />
                            </Col>
                            <Col span={12}>
                              <ButtonReuse
                                value="Cancel"
                                type="submit"
                                className="modal-deleteCancelBtn"
                                onClick={handleCancelDeleteModal}
                              />
                            </Col>
                          </Row>
                        </Modal>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PO;
