import React from "react";
import { Table, Row, Col, Input, Typography } from "antd";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Button } from "antd";
import AddOverlay from "./AddProject";
import deleteImage from "../../../assets/images/delete.png";
import UnarchiveImage_t from "../../../assets/images/unarchive_t.png";
import archiveImage_t from "../../../assets/images/archive_t.png";
import archive from "../../../assets/images/archive.png";
import unarchive from "../../../assets/images/unarchive.png";
import AddModal from "./Modal";
import { useHistory } from "react-router-dom";

import { ExportOutlined } from "@ant-design/icons";
import Switch from "react-switch";
import type { ColumnsType } from "antd/es/table";
import {
  decimalCheck,
  convertCurrency,
 
} from "../../../utils/util";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getLocation } from "../../../stores/actions/getLocationAction";
import { useDispatch, useSelector } from "react-redux";
import { getBaseCurrency } from "../../../stores/actions/getBaseCurrencyAction";
import Loader from "../../../components/Loader/Loader";
import { project } from "../../../utils/enums";
require("./Project.scss");
import axios,{ CancelTokenSource } from 'axios';
const Project = () => {
  const [data, setData] = useState([]);
  const [dataWithoutSorting, setDataWithoutSorting] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [getAction, setAction] = useState("");
  const [pageLoaded, setpageLoaded] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState();
  const [getTotalSold, setTotalSold] = useState(0);
  const [getTotalGain, setTotalGain] = useState(0);
  const [getTotalRecognized, setTotalRecognized] = useState(0);
  const [getTotalPlanned, setTotlPlanned] = useState(0);
  const [getTotalRevUnacc, setTotalRevUnacc] = useState(0);
  const [getTotalPlanInvoice, setTotalPlanInvoice] = useState(0);
  const [getTotalInvoiced, setTotalInvoiced] = useState(0);
  const [getTotalInvoiceUnacc, setTotalInvoiceUnacc] = useState(0);
  const [getTotalLeadTrail, setTotalLeadTrail] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [userid, getuserid] = useState();

  const [getMonthId, setMonthId] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [projectCount, setProjectCount] = useState(0);
  const [sortingOptions, setSortingOptions] = useState([]);


  const currentTableData = useRef<any>([]);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  useEffect(() => {
    !getMonthId && getMonthsId();
  }, []);

  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      setMonthId(currentMonth[0].id);
    });
  };


  const dispatch: any = useDispatch();
  const geoLocationData = useSelector((store: any) => store.locations.location);
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
  
  const [locationData, setLocationData] = useState([]);
  const baseCurrencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const [currencySymb, setCurrencySymb] = useState<any>();

  useEffect(() => {
    !geoLocationData && dispatch(getLocation());
    !baseCurrencySymb && dispatch(getBaseCurrency());
  }, [dispatch]);

  useEffect(() => {
    if (geoLocationData) {
      setLocationData(geoLocationData);
    }
  }, [geoLocationData]);

  useEffect(() => {
    if (baseCurrencySymb) {
      setCurrencySymb(baseCurrencySymb);
    }
  }, [baseCurrencySymb]);


  const locationOptions = locationData.map(function (Locations: {
    location: any;
    id: any;
  }) {
    return {
      value: Locations.id,
      label: Locations.location,
    };
  });

  let history = useHistory();
  let token = getToken();

  const [nameSearch, setNameSearch] = useState("");
  
  const [isDepartment, setIsDepartment] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const { Text } = Typography;

 
 

  let totalInvoiceUnacc = 0;
  let totalLeadTrail = 0;

  useEffect(() => {
    const addProjectAccess = sessionStorage.getItem("isDepartment");
    if (addProjectAccess === "1") {
      setIsDepartment(true);
    }
  }, []);

  useEffect(() => {    
    if(nameSearch.length >= 2){
      getData([],page,pageSize,filterOptions,sortingOptions);
    }else if(nameSearch.length === 0){
      getData([],page,pageSize,filterOptions,sortingOptions);
    }
    
  }, [nameSearch,currencyRate]);

  

  useEffect(()=>{
    let totalSold = 0;
    let totalGain = 0;
    let totalRecognized = 0;
    let totalPlanned = 0;
    let totalRevUnacc = 0;
    let totalPlanInvoice = 0;
    let totalInvoiced = 0;
    if (dataWithoutSorting) {
      if(nameSearch){
        setFilterTotalCount(projectCount)
       
      }else{
        setFilterTotalCount(projectCount)
      }
      setFilterData(data);
      
    
      dataWithoutSorting.forEach((res:any) => {
        totalSold =
          Number(totalSold) +
          Number(res.Sold_value_base != null ? res.Sold_value_base : 0);

        totalGain =
          Number(totalGain) +
          (Number(res.Gained_base_forTotal) -
            Number(res.Dropped_base_forTotal));
        totalRecognized = Number(totalRecognized) + Number(res.Recognized_base);
        totalPlanned =
          Number(totalPlanned) + Number(res.Planned_recognition_base);
        totalRevUnacc = Number(totalRevUnacc) + Number(res.UnaccountedRevenue);
        totalPlanInvoice =
          Number(totalPlanInvoice) + Number(res.Planned_invoice_base);
        totalInvoiced = Number(totalInvoiced) + Number(res.FinalInvoice_base);
        totalInvoiceUnacc =
          Number(totalInvoiceUnacc) + Number(res.UnaccountedInvoice);
        totalLeadTrail = Number(totalLeadTrail) + Number(res.Lead_Lag);
      });
      setTotalSold(totalSold);
      setTotalGain(totalGain);
      setTotalRecognized(totalRecognized);
      setTotlPlanned(totalPlanned);
      setTotalRevUnacc(totalRevUnacc);
      setTotalPlanInvoice(totalPlanInvoice);
      setTotalInvoiced(totalInvoiced);
      setTotalInvoiceUnacc(totalInvoiceUnacc);
      setTotalLeadTrail(totalLeadTrail);
    }

  },[dataWithoutSorting,currencyRate])

  const user_id: any = localStorage.getItem("userId");

  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const user_id = response.data.data.user.id;
        getuserid(user_id);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getData([],page,pageSize,filterOptions,sortingOptions);
      setpageLoaded(true);
    }
  }, [currencySymb?.currency_code]);

  useEffect(() => {
    if (pageLoaded) {
      getUser();
      getData([],page,pageSize,filterOptions,sortingOptions);
    }
  }, [isArchive, pageLoaded,currencyRate]);

  useEffect(() => {
    getData([],page,pageSize,filterOptions,sortingOptions);
    getProjectDetails()
  }, [userid,currencyRate, isArchive]);

  const exportProjects = async () => {
    try {

      let paramsData:any =  {
        archive: isArchive,
        user_id: user_id,
      };
      if (nameSearch) {
        paramsData.search_params = nameSearch;
    }
      if (filterOptions && filterOptions.length > 0) {
           paramsData.filter_value = JSON.stringify(filterOptions);
      }
     
      const response = await AxiosConfig.get('projects/export/projectsData', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params:paramsData
      });
      if (response.status === 200 && response.data.status.success) {
        const projects = response.data.data.projects;
        const processedProjects = projects.map((project: any) => ({
          ...project,
          'Gain_Dropped_base': project.gained_base - project.dropped_base,
          'name': project.name ? project.name.replace(/\t/g, '') : '',
          'Customer': project.customer.name,
          'Department': project.department.name
        }));
        
        downloadCSV(processedProjects);
      } else {
        console.error('Error fetching projects:', response.data.status.message);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };
  const downloadCSV = (data: any[]) => {
   
    let csvContent = projectHeaders.map(header => header.label).join(',') + '\n';
    csvContent += data.map(row =>
      projectHeaders.map(header => {
       
        let field = row[header.key];
        if (typeof field === 'string' && (field.includes(',') || field.includes('"'))) {
          field = `"${field.replace(/"/g, '""')}"`; 
        }
        return field;
      }).join(',')
    ).join('\n');
   
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'projects.csv');
    document.body.appendChild(link);
    link.click();
   
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getData = async (geoIds?: number[],page?:any,pageSize?:any,filteredObject?:any,sortedObject?:any) => {
    if(userid){
    let paramsData:any =  {
      page:parseInt(page),
      pageSize:parseInt(pageSize),
      archive: isArchive,
      geoIds: geoIds,
      user_id: userid,
    };
    if (nameSearch) {
      paramsData.search_params = nameSearch;
  }


    if (filteredObject && filteredObject.length > 0) {
         paramsData.filter_value = JSON.stringify(filteredObject);
    }  
    if (sortedObject && sortedObject.length > 0) {
      paramsData.sorting_value = JSON.stringify(sortedObject);
    }    
      // Cancel the previous request if it exists
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Operation canceled due to new request.');
      }
      // Create a new CancelToken
      cancelTokenRef.current = axios.CancelToken.source();
        try {
          await AxiosConfig.get(`projects`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            
            params:paramsData,
            cancelToken: cancelTokenRef.current.token,
          }).then((res) => {
            let proj = res.data.data.projects;
            setProjectCount(res.data.data.projectCount)
            setFilterTotalCount(res.data.data.projectCount)
            if (proj.length >= 0) {
              setIsLoading(false);
            }
           
           
            const tableData = res.data.data.projects.map(
              (row: {
                name: any;
                id: any;
                customer: any;
                department: any;
                stus_sold: any;
                project_code: any;
                $Value: any;
                revenue: any;
                stus_expended: any;
                
                future_offset: any; 
                total_final_amt: any;
                archive_status: any;
                recognized_tilldate: any;
                Recognized: any;
                accounted: any;
                total_sold_base: any;
                lead_lag: any;
                final_invoice_base: any;
                unaccounted: any;
                invoice_unaccounted: any;
                planned_invoice_base: any;
                this_month_value: any;
                accounted_base: any;
                dropped: any;
                dropped_base: any;
                gained: any;
                gained_base: any;
                Planned_recognition: any;
                Po_type_id: any;
                RevForex: any;
                Rev_Forex: any;
                InvForex: any;
                Inv_Forex: any;
                RevForexTilldate: any;
                Sold_value_base: any;
                Geography: any;
                UnaccountedRevenue: any;
                UnaccountedInvoice: any;
                totalLeadLag: any;
                created_at: any;
              }) => ({
                created_at: row?.created_at,
                Project_code: row?.project_code,
                Project_name: row?.name,
                Customer_name: row?.customer.name,
                Associated_bu: row?.department.name,
               
               
                sold: convertCurrency(row?.total_sold_base,currencyRate),
                Sold_value_base: row?.total_sold_base ,
                Stus_sold: decimalCheck(
                  row?.stus_sold,
                  2,
                  currencySymb?.currency_symbol
                ),
                Sold_value: decimalCheck(
                  row?.total_sold_base + row?.gained_base,
                  2,
                  currencySymb?.currency_symbol
                ),
                Sold_value_in_base: row?.total_sold_base + row?.gained_base,
                Delete_Action: row?.id,
                key: row?.id,
                Earned_value: decimalCheck(row?.revenue ? row?.revenue : null, 2),
                Stus_expended: decimalCheck(row?.stus_expended, 2),
                Pending: decimalCheck(row?.$Value - row?.future_offset, 2),
                final_invoice_base: convertCurrency(row?.total_final_amt,currencyRate),
                FinalInvoice_base: row?.total_final_amt,
                lead_lag: convertCurrency(row?.lead_lag,currencyRate),
                totalLeadLag: row?.lead_lag,
                recognized_tilldate: decimalCheck(
                  row?.recognized_tilldate,
                  2,
                  currencySymb?.currency_symbol
                ),
                Recognized_tilldate_base: row?.recognized_tilldate,
                Recognized: convertCurrency(row?.Recognized,currencyRate),
                Recognized_base: row?.Recognized,
                this_month_value: decimalCheck(
                  row?.this_month_value,
                  2,
                  currencySymb?.currency_symbol
                ),
                accounted: decimalCheck(row?.accounted, 2),
                accounted_base: decimalCheck(row?.accounted_base, 2),
                Accounted_base: row?.accounted_base,
                unaccounted: convertCurrency(row?.UnaccountedRevenue,currencyRate),
                planned_invoice_base: convertCurrency(row?.planned_invoice_base,currencyRate),
                Planned_invoice_base: row?.planned_invoice_base,
                invoice_unaccounted:convertCurrency(row?.UnaccountedInvoice,currencyRate),
                Unaccounted_Format: row?.UnaccountedRevenue,
                invoice_Uncc_format: row?.UnaccountedInvoice,
                Gained: decimalCheck(row?.gained, 2),
                Gained_base_forTotal: row?.gained_base,
                Dropped_base_forTotal: row?.dropped_base,
                Gained_base: decimalCheck(row?.gained_base, 2),
                Gain_Dropped: decimalCheck(
                  row?.gained - row?.dropped,
                  2,
                  currencySymb?.currency_symbol
                ),
               
               
                 Gain_Dropped_base:  convertCurrency(row?.gained_base - row?.dropped_base,currencyRate),
                
                Planned_recognition:convertCurrency(row?.Planned_recognition,currencyRate),
                Planned_recognition_base: row?.Planned_recognition,
                archive_status: {
                  id: row?.id,
                  status: row?.archive_status,
                },
                RevForex: decimalCheck(
                  row?.RevForex,
                  2,
                  currencySymb?.currency_symbol
                ),
                Rev_Forex: row?.RevForex,
                RevForexTilldate: row?.RevForexTilldate,
                InvForex: decimalCheck(
                  row?.InvForex,
                  2,
                  currencySymb?.currency_symbol
                ),
                Inv_Forex: row?.InvForex,
                UnaccountedRevenue: row?.UnaccountedRevenue,
                UnaccountedInvoice: row?.UnaccountedInvoice,
                Lead_Lag: row?.lead_lag,
              })
            );
      
            const tableDataWithoutSorting = res.data.data.projectsWithoutSorting?res.data.data.projectsWithoutSorting.map(
              (row: {
                name: any;
                id: any;
                customer: any;
                department: any;
                stus_sold: any;
                project_code: any;
                $Value: any;
                revenue: any;
                stus_expended: any;
                
                future_offset: any; 
                total_final_amt: any;
                archive_status: any;
                recognized_tilldate: any;
                Recognized: any;
                accounted: any;
                total_sold_base: any;
                lead_lag: any;
                final_invoice_base: any;
                unaccounted: any;
                invoice_unaccounted: any;
                planned_invoice_base: any;
                this_month_value: any;
                accounted_base: any;
                dropped: any;
                dropped_base: any;
                gained: any;
                gained_base: any;
                Planned_recognition: any;
                Po_type_id: any;
                RevForex: any;
                Rev_Forex: any;
                InvForex: any;
                Inv_Forex: any;
                RevForexTilldate: any;
                Sold_value_base: any;
                Geography: any;
                UnaccountedRevenue: any;
                UnaccountedInvoice: any;
                totalLeadLag: any;
                created_at: any;
              }) => ({
                created_at: row?.created_at,
                Project_code: row?.project_code,
                Project_name: row?.name,
                Customer_name: row?.customer.name,
                Associated_bu: row?.department.name,
               
               
                sold: convertCurrency(row?.total_sold_base,currencyRate),
                Sold_value_base: row?.total_sold_base ,
                Stus_sold: decimalCheck(
                  row?.stus_sold,
                  2,
                  currencySymb?.currency_symbol
                ),
                Sold_value: decimalCheck(
                  row?.total_sold_base + row?.gained_base,
                  2,
                  currencySymb?.currency_symbol
                ),
                Sold_value_in_base: row?.total_sold_base + row?.gained_base,
                Delete_Action: row?.id,
                key: row?.id,
                Earned_value: decimalCheck(row?.revenue ? row?.revenue : null, 2),
                Stus_expended: decimalCheck(row?.stus_expended, 2),
                Pending: decimalCheck(row?.$Value - row?.future_offset, 2),
                final_invoice_base: convertCurrency(row?.total_final_amt,currencyRate),
                FinalInvoice_base: row?.total_final_amt,
                lead_lag: convertCurrency(row?.lead_lag,currencyRate),
                totalLeadLag: row?.lead_lag,
                recognized_tilldate: decimalCheck(
                  row?.recognized_tilldate,
                  2,
                  currencySymb?.currency_symbol
                ),
                Recognized_tilldate_base: row?.recognized_tilldate,
                Recognized: convertCurrency(row?.Recognized,currencyRate),
                Recognized_base: row?.Recognized,
                this_month_value: decimalCheck(
                  row?.this_month_value,
                  2,
                  currencySymb?.currency_symbol
                ),
                accounted: decimalCheck(row?.accounted, 2),
                accounted_base: decimalCheck(row?.accounted_base, 2),
                Accounted_base: row?.accounted_base,
                unaccounted: convertCurrency(row?.UnaccountedRevenue,currencyRate),
                planned_invoice_base: convertCurrency(row?.planned_invoice_base,currencyRate),
                Planned_invoice_base: row?.planned_invoice_base,
                invoice_unaccounted:convertCurrency(row?.UnaccountedInvoice,currencyRate),
                Unaccounted_Format: row?.UnaccountedRevenue,
                invoice_Uncc_format: row?.UnaccountedInvoice,
                Gained: decimalCheck(row?.gained, 2),
                Gained_base_forTotal: row?.gained_base,
                Dropped_base_forTotal: row?.dropped_base,
                Gained_base: decimalCheck(row?.gained_base, 2),
                Gain_Dropped: decimalCheck(
                  row?.gained - row?.dropped,
                  2,
                  currencySymb?.currency_symbol
                ),
               
               
                 Gain_Dropped_base:  convertCurrency(row?.gained_base - row?.dropped_base,currencyRate),
                
                Planned_recognition:convertCurrency(row?.Planned_recognition,currencyRate),
                Planned_recognition_base: row?.Planned_recognition,
                archive_status: {
                  id: row?.id,
                  status: row?.archive_status,
                },
                RevForex: decimalCheck(
                  row?.RevForex,
                  2,
                  currencySymb?.currency_symbol
                ),
                Rev_Forex: row?.RevForex,
                RevForexTilldate: row?.RevForexTilldate,
                InvForex: decimalCheck(
                  row?.InvForex,
                  2,
                  currencySymb?.currency_symbol
                ),
                Inv_Forex: row?.InvForex,
                UnaccountedRevenue: row?.UnaccountedRevenue,
                UnaccountedInvoice: row?.UnaccountedInvoice,
                Lead_Lag: row?.lead_lag,
              })
            ):[];
            setData(tableData);
            setDataWithoutSorting(tableDataWithoutSorting)
            setFilterData(tableDataWithoutSorting);
           
            let totalSold = 0;
            let totalGain = 0;
            let totalRecognized = 0;
            let totalPlanned = 0;
            let totalRevUnacc = 0;
            let totalPlanInvoice = 0;
            let totalInvoiced = 0;
            if (res.data.data.projectsWithoutSorting) {
              res.data.data.projectsWithoutSorting.forEach((res) => {
              totalSold = totalSold + res.total_sold_base;
              totalGain = totalGain + (res.gained_base - res.dropped_base);
              totalRecognized = totalRecognized + res.Recognized;
              totalPlanned = totalPlanned + res.Planned_recognition;
              totalRevUnacc = totalRevUnacc + res.UnaccountedRevenue;
              totalPlanInvoice = totalPlanInvoice + res.planned_invoice_base;
              totalInvoiced = totalInvoiced + res.final_invoice_base;
              totalInvoiceUnacc = totalInvoiceUnacc + res.UnaccountedInvoice;
              totalLeadTrail = totalLeadTrail + res.lead_lag;
              });
            }
              setTotalSold(totalSold);
              setTotalGain(totalGain);
              setTotalRecognized(totalRecognized);
              setTotlPlanned(totalPlanned);
              setTotalRevUnacc(totalRevUnacc);
              setTotalPlanInvoice(totalPlanInvoice);
              setTotalInvoiced(totalInvoiced);
              setTotalInvoiceUnacc(totalInvoiceUnacc);
              setTotalLeadTrail(totalLeadTrail);
          });
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }
          console.error("An error occurred:", error);
        }
    
   }
  };
  const getProjectDetails = async () => {
    try {
      if(userid){
        const res = await AxiosConfig.get(`/projects/projectList/names?user_id=${userid}&archive=${isArchive}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        let projects = res?.data?.data?.projects;
      
        setProjectName(
          projects.map((row: { name: any; id: any }) => ({
            text: row.name,
            value: row.name,
          }))
          
        );
        let departments=projects.map(item=>item.department)
        let customers=projects.map(item=>item.customer)
        let customeruniqueData = filterUniqueById(customers);
        customeruniqueData = customeruniqueData.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setCustomers(
        customeruniqueData.map((row: { name: string; id: number }) => ({
          text: row?.name,
          value: row?.name,
        }))
      );
        // Filter the array to get unique objects
          let uniqueData = filterUniqueById(departments);
        
          const departmentOptions = uniqueData.map(function (associatedBus: {
            name: string;
            id: number;
          }) {
            return {
              text: associatedBus?.name,
              value: associatedBus?.name,
            };
          });
          setDepartmentOptions(departmentOptions)
        
     }

    } catch (err) {
      console.error(err);
    }
  };
  // Function to filter unique objects based on id
  function filterUniqueById(array) {
    let uniqueIds = {};
    return array.filter(obj => {
        if (!uniqueIds[obj.id]) {
            uniqueIds[obj.id] = true;
            return true;
        }
        return false;
    });
  }
  const handleDelete = (id: any) => {
    setRowId(id);
    setShow(true);
    setAction("delete");
  };

  const handleArchive = async (data: any) => {
    setRowId(data.id);
    setShow(true);
    setAction("archive");
    setArchiveStatus(data.status);
  };

  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
    localStorage.setItem("projectsId", record);
  };

  const projectHeaders = [
    { label: "CODE", key: "project_code" },
    { label: "NAME", key: "name" },
    { label: "CUSTOMER", key: "Customer" },
    { label: "DEPARTMENT", key: "Department" },
    { label: "REVENUE - SOLD", key: "total_sold_base" },
    { label: "REVENUE - Gain/(Dropped)", key: "Gain_Dropped_base" },
    { label: "REVENUE - RECOGNIZED", key: "Recognized" },
    { label: "REVENUE - PLANNED", key: "Planned_recognition" },
    { label: "REVENUE - UNACCOUNTED", key: "UnaccountedRevenue" },
    { label: "INVOICING - PLANNED", key: "planned_invoice_base" },
    { label: "INVOICING - INVOICED", key: "final_invoice_base" },
    { label: "INVOICING - UNACCOUNTED", key: "UnaccountedInvoice" },
    { label: "INVOICING - Lead/Trail", key: "lead_lag" },
    { label: "RevForex", key: "RevForex" },
  ];

  interface DataType {}

  const columns: ColumnsType<DataType> = [
    {
      title: "CODE",
      fixed: "left",
      dataIndex: ["Project_code", "Delete_Action"],
      key: "key",
      render: (text: any, record: any) => (
        <a
          onClick={() => {
            onClickRow(record["Delete_Action"]);
          }}
        >
          {record["Project_code"]}
        </a>
      ),
      sorter:true
    },
    {
      title: "NAME",
      fixed: "left",
      dataIndex: "Project_name",
      key: "name",
      filters: projectName,
      
      onFilter: (value: any, record: any) =>{
        return record.Project_name.includes(value)
      },
      sorter:true
    },
    {
      title: "BU",
      fixed: "left",
      dataIndex: "Associated_bu",
      key: "Associated_bu",
      filters: departmentOptions,
      onFilter: (value: any, record: any) => {
        return record.Associated_bu.includes(value);
      },
      sorter:true
    },
    {
      title: "CUSTOMER",
      fixed: "left",
      dataIndex: "Customer_name",
      key: "Customer_name",
      filters: customers,
      onFilter: (value: any, record: any) =>
        record.Customer_name.includes(value),
      sorter:true
    },
    {
      title: "REVENUE",
      className: "Revenue",
      children: [
        {
          title: "Sold",
          align: "right",
          dataIndex: "sold",
          key: "sold",
          className: "Revenue",
          width: "8%",
          sorter:true
        },
        {
          title: "Gain/(Dropped)",
          align: "right",
          dataIndex: "Gain_Dropped_base",
          key: "Gain_Dropped_base",
          className: "Revenue",
          width: "8%",
          sorter:true
        },
        {
          title: "Earned",
          align: "right",
          dataIndex: "Recognized",
          key: "Recognized",
          className: "Revenue",
          sorter:true,
        },
        {
          title: "Planned",
          align: "right",
          dataIndex: "Planned_recognition",
          key: "Planned_recognition",
          className: "Revenue",
          sorter:true,
        },
        {
          title: "Unaccounted",
          align: "right",
          dataIndex: "unaccounted",
          
          key: "unaccounted",
          className: "Revenue",
          sorter:true,
          
          render: (text: any, record: any) => (
            <Col
              style={{
                color: record["Unaccounted_Format"] > 0 ? "red" : "green",
              }}
            >
              <p className="showVarienceOfUnaccounted">
                {record["unaccounted"]}
              </p>
            </Col>
          ),
        },
      ],
    },
    {
      title: "INVOICING",
      className: "Invoice",
      children: [
        {
          title: "Planned",
          align: "right",
          dataIndex: "planned_invoice_base",
          key: "planned_invoice_base",
          className: "Invoice",
          sorter:true
        },
        {
          title: "Invoiced",
          align: "right",
          dataIndex: "final_invoice_base",
          key: "final_invoice_base",
          className: "Invoice",
          sorter:true
        },
        {
          title: "Unaccounted",
          align: "right",
          dataIndex: "invoice_unaccounted",
          key: "invoice_unaccounted",
          className: "Invoice",
          sorter:true,
          render: (text: any, record: any) => (
            <Col
              style={{
                color: record["invoice_Uncc_format"] > 0 ? "red" : "green",
              }}
            >
              <p className="showVarienceOfUnaccountedInvoicing">
                {record["invoice_unaccounted"]}
              </p>
            </Col>
          ),
        },
        {
          title: "Lead/Trail",
          align: "right",
          dataIndex: "lead_lag",
          key: "lead_lag",
          className: "Invoice",
          sorter:true
        },
      ],
    },
    {
      title: "ACTION",
      dataIndex: "Delete_Action",
      key: "id",
      render: (record: any) => (
        <>
          <div className="actionBtns">
            <span onClick={() => handleDelete(record)}>
              <img src={deleteImage} />
            </span>
          </div>
        </>
      ),
    },
    {
      title: !isArchive ? "ARCHIVE" : "UNARCHIVE",
      dataIndex: "archive_status",
      key: "archive_status",
      render: (record: any) => (
        <>
          {isArchive ? (
            <div className="actionBtns">
              <span onClick={() => handleArchive(record)}>
                <img src={unarchive} style={{ height: 20, width: 20 }} />
              </span>
            </div>
          ) : (
            <div className="actionBtns">
              <span onClick={() => handleArchive(record)}>
                <img src={archive} style={{ height: 20, width: 20 }} />
              </span>
            </div>
          )}
        </>
      ),
    },
  ];

  const [visible, setVisible] = useState(false);

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  const handleOnChange = (event) => {
    const searchValue = event.target.value;
    setNameSearch(searchValue);
  
    setPage(1);
  
    if (searchValue) {
      getData([], 1, pageSize, filterOptions, sortingOptions);
    }
  };


  const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const searchValue = event.clipboardData.getData('text');
    setNameSearch(searchValue);
    setPage(1);
     if (searchValue) {
      getData([], 1, pageSize, filterOptions, sortingOptions);
    }
  };


 

  const handleSwitchChange = () => {
    setIsArchive(!isArchive);
    
  };

  function CustomMultiSelectContainer({ children }) {
    return <div className="custom-container">{children}</div>;
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selected) => {
    const geoIds = selected?.map((val) => val.value);
    setSelectedOptions(selected);
    getData(geoIds,page,pageSize,filterOptions,sortingOptions);
  };

  return (
    <>
      <div className="table-card">
        <Row>
          <Col md={8} xs={24} lg={8} xl={5}>
            <div className="searchForm">
              {/* <form> */}
                <Input.Search
                  allowClear
                  onChange={handleOnChange}
                  onPaste={handleOnPaste} 
                  placeholder="Search by Code / Name / Customer / BU"
                />
              {/* </form> */}
            </div>
          </Col>
          <Col md={2} xs={24} lg={2} xl={2}>
            <label className="totalLabel">
              {project?.labelForTotal} {filterTotalCount}
            </label>
          </Col>
          <Col md={2} xs={24} lg={4} xl={3}>
            <label className="geography"> {project?.labelForGeography} </label>
          </Col>
          <Col md={3} xs={3} lg={3} xl={4} className="locationDropdown">
            <CustomMultiSelectContainer>
              <ReactMultiSelectCheckboxes
                options={locationOptions}
                placeholderButtonLabel="Select"
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            </CustomMultiSelectContainer>
          </Col>

          <Col md={8} xs={24} lg={8} xl={9} className="column">
            <Switch
              onChange={handleSwitchChange}
              checked={isArchive}
              checkedIcon={
                <img
                  src={UnarchiveImage_t}
                  style={{ height: "15px", marginLeft: ".50rem" }}
                />
              }
              onColor="#d0021b"
              uncheckedIcon={
                <img
                  src={archiveImage_t}
                  style={{ height: "15px", marginLeft: ".35rem" }}
                />
              }
              offColor="#1b9558"
            />
            {isDepartment && (
              <div className="addProjectBtn">
                <Button
                  type="primary"
                  onClick={visibleHandler}
                  className="primary-btn"
                >
                  {project?.buttonForAddProject}
                </Button>
              </div>
            )}
             <div  className="exportTableButton">
          <ExportOutlined className="exportOultineIcon" onClick={exportProjects} />
          </div>
          </Col>
        </Row>

        <AddOverlay
          visible={visible}
          onClose={closeHandler}
          onCancelButton={closeHandler}
        ></AddOverlay>
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="tableOut noWordBreak">
            <Table
              columns={columns}
              dataSource={filterData}
              bordered
              className="projectsTable"
              scroll={{ x: 2400, y: 680 }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                current: page,
                pageSize: pageSize,
                total: projectCount,
                pageSizeOptions: ["10", "20", "30", "40", "50"],
                
              }}
              onChange={async (pagination:any, filters, sorter:any, extra) => {
                let totalSold = 0;
                let totalGain = 0;
                let totalRecognized = 0;
                let totalPlanned = 0;
                let totalRevUnacc = 0;
                let totalPlanInvoice = 0;
                let totalInvoiced = 0;
                setPage(pagination.current);
                setPageSize(pagination.pageSize);
                let filteredObject:any = [filters];
                let sortingObject:any = sorter.order != undefined ? [{field:Array.isArray(sorter.field) ? sorter.field[0] : sorter.field,order:sorter.order}]:[]
                setSortingOptions(sortingObject)
                setFilterOptions(filteredObject)
                
                await getData([],pagination.current,pagination.pageSize,filteredObject,sortingObject)
               
                const filteredData: any = extra.currentDataSource;
                //setDataWithoutSorting(filterData)
                filteredData.map((res) => {
                  totalSold =
                    Number(totalSold) +
                    Number(
                      res.Sold_value_base != null ? res.Sold_value_base : 0
                    );

                  totalGain =
                    Number(totalGain) +
                    (Number(res.Gained_base_forTotal) -
                      Number(res.Dropped_base_forTotal));
                  totalRecognized =
                    Number(totalRecognized) + Number(res.Recognized_base);
                  totalPlanned =
                    Number(totalPlanned) + Number(res.Planned_recognition_base);
                  totalRevUnacc =
                    Number(totalRevUnacc) + Number(res.UnaccountedRevenue);
                  totalPlanInvoice =
                    Number(totalPlanInvoice) + Number(res.Planned_invoice_base);
                  totalInvoiced =
                    Number(totalInvoiced) + Number(res.FinalInvoice_base);
                  totalInvoiceUnacc =
                    Number(totalInvoiceUnacc) + Number(res.UnaccountedInvoice);
                  totalLeadTrail =
                    Number(totalLeadTrail) + Number(res.Lead_Lag);
                });
                //setTotalSold(totalSold);
                //setTotalGain(totalGain);
                //setTotalRecognized(totalRecognized);
                //setTotlPlanned(totalPlanned);
                //setTotalRevUnacc(totalRevUnacc);
                //setTotalPlanInvoice(totalPlanInvoice);
                //setTotalInvoiced(totalInvoiced);
                //setTotalInvoiceUnacc(totalInvoiceUnacc);
                //setTotalLeadTrail(totalLeadTrail);

                
              }}
              summary={(currentData) => {
              
                currentTableData.current = currentData;
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="totalProject">
                        {project?.textForTotal}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        className="projSummary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="projSummary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="projSummary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="projSummary1">
                        <Text>
                          {/* {formatCurrencyValue(
                            currencySymb?.currency_code,
                            getTotalSold
                          )} */}
                          {convertCurrency(getTotalSold,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="projSummary1">
                        <Text>
                           {convertCurrency(getTotalGain,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className="projSummary1">
                        <Text>
                          {convertCurrency(getTotalRecognized,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="projSummary1">
                        <Text>
                          {convertCurrency(getTotalPlanned,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} className="projSummary1">
                        <Text>
                          {convertCurrency(getTotalRevUnacc,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9} className="projSummary2">
                        <Text>
                          {convertCurrency(getTotalPlanInvoice,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} className="projSummary2">
                        <Text>
                          {convertCurrency(getTotalInvoiced,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} className="projSummary2">
                        <Text>
                          {convertCurrency(getTotalInvoiceUnacc,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12} className="projSummary2">
                        <Text>
                          {convertCurrency(getTotalLeadTrail,currencyRate)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </div>
        )}
      </div>
      <AddModal
        onClose={() => setShow(false)}
        show={show}
        rowData={rowId}
        action={getAction}
        archive_status={archiveStatus}
        monthid={getMonthId}
      ></AddModal>
    </>
  );
};

export default Project;
