import React, { useState, useEffect } from "react";
import "./ExpensesDrawer.scss";
import { enumForExpensesDrawer } from "../../../utils/enums";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Divider, Table } from "antd";
import { FileExclamationOutlined } from "@ant-design/icons";
import Loader from "../../../components/Loader/Loader";
import dayjs from "dayjs";
import { convertCurrency } from "../../../utils/util";
import { useSelector } from "react-redux";

interface Expense {
  id: number;
  amount: number;
  expense: {
    reference_no: string;
    vendor_name: string;
    expense_type: string;
    bill_date: string;
    notes: string;
  };
  financialmonth: {
    month: string;
    year: string;
  };
}

interface ExpensesData {
  [key: string]: {
    [key: string]: Expense[];
  };
}

const ExpensesInfo = (props: { poDetails?: any; activeKey?: string}) => {
  let token = getToken();

  const [expenseData, setExpensesData] = useState<{
    expenseData: { [key: string]: any[] };
    totalAmt: number | null;
  }>({
    expenseData: {},
    totalAmt: null,
  });
  const [loading, isLoading] = useState(true);
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  const transformData = (data: ExpensesData) => {
    const transformed: { [key: string]: any[] } = {};
    Object.entries(data).forEach(([monthYear, expenseTypes]) => {
      Object.entries(expenseTypes).forEach(([expenseType, expenses]) => {
        if (!transformed[expenseType]) {
          transformed[expenseType] = [];
        }

        expenses.forEach((expense: Expense) => {
          transformed[expenseType].push({
            key: `${expenseType}_${monthYear}`,
            monthYear: monthYear,
            adjustedAmount: convertCurrency(expense.amount, currencyRate),
            billDate: dayjs(expense.expense.bill_date).format("YYYY/MM/DD"),
            referenceNo: expense.expense.reference_no,
            notes: expense.expense.notes,
            vendorName: expense.expense.vendor_name,
          });
        });
      });
    });
    return transformed;
  };

  useEffect(() => {
    const fetchExpensesData = async () => {
      try {
        const response = await AxiosConfig.get(
          `purchaseorders/expense/${props.poDetails?.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const poExpense: any = response.data.data;
        let newObj: ExpensesData = {};
        for (let [key, value] of Object.entries(poExpense)) {
          if (key !== "total_amount") {
            // @ts-ignore
            newObj[key] = value;
          }
        }
        
        const transformedData = transformData(newObj);
        isLoading(false);
        setExpensesData({
          expenseData: transformedData,
          totalAmt: poExpense.total_amount,
        });
      } catch (error) {
        console.error("Error fetching expenses data:", error);
      }
    };

    fetchExpensesData();
  }, [props.poDetails]);

  const columns = [
    {
      title: enumForExpensesDrawer.labelForMonthYear,
      dataIndex: "monthYear",
      key: "monthYear",
    },
    {
      title: enumForExpensesDrawer.labelForVendorName,
      dataIndex: "vendorName",
      key: "vendorName",
    },
    {
      title: enumForExpensesDrawer.labelForRefernceNo,
      dataIndex: "referenceNo",
      key: "referenceNo",
    },
    {
      title: enumForExpensesDrawer.labelForAdjustedAmount,
      dataIndex: "adjustedAmount",
      key: "adjustedAmount",
    },
    {
      title: enumForExpensesDrawer.labelForBillDate,
      dataIndex: "billDate",
      key: "billDate",
    },
   
    {
      title: enumForExpensesDrawer.labelForNotes,
      dataIndex: "notes",
      key: "notes",
    },
   
  ];

  return (
    <div>
      <h2 className="expense-text">{enumForExpensesDrawer.labelForExpense}</h2>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {Object.keys(expenseData.expenseData).length === 0 ? (
              <div className="no-expense-div">
                <FileExclamationOutlined className="no-expense-image" />
                <p>{enumForExpensesDrawer.labelForNoExpenses}</p>
              </div>
            ) : (
              Object.entries(expenseData.expenseData)?.map(
                ([expenseType, expenses]) => (
                  <div key={expenseType} className="expense-type-section">
                    <Divider orientation="left">
                      <h3>{expenseType}</h3>
                    </Divider>
                    <Table
                      columns={columns}
                      dataSource={expenses}
                      pagination={false}
                      rowKey="key"
                    />
                  </div>
                )
              )
            )}
            <h2 className="total-adjusted-text">
              {enumForExpensesDrawer.labelForTotalAdjusted} &nbsp;
              {convertCurrency(Number(expenseData?.totalAmt), currencyRate)}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpensesInfo;
