import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosConfig } from "../../ApiConfig";
import { getToken } from "../../auth";

const CapacityReport = () => {
  const [labelData, setlabelData] = useState([]);
  const [stusData, setstusData] = useState([]);

  let token = getToken();
  const { id }: any = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const tempArray1: any = [];
    const tempArray2: any = [];

    AxiosConfig.get(`talents/capacity/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      res.data.data.capacities.map((item: any) => {
        tempArray1.push(
          `${item.month.slice(0, 3).toUpperCase()} ${String(
            item.year
          ).substring(2, 4)}`
        );
      });
      res.data.data.capacities.map((item: any) => {
        item.capacity.map((ele: any) => {
          tempArray2.push(ele.stus);
        });
      });
      setlabelData(tempArray1);
      setstusData(tempArray2);
    });
  };

  const options = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Y text",
          },
        },
      ],
    },
  };

  return (
    <>
      <Bar
        data={{
          labels: labelData,
          datasets: [
            {
              label: "Capacity",
              data: stusData,
              backgroundColor: "#5C67E5",
            },
          ],
          // options: {
          //   scales: {
          //     yAxes: [
          //       {
          //         scaleLabel: {
          //           display: true,
          //           labelString: "Y text",
          //           text: "Your Title",
          //         },
          //       },
          //     ],
          //   },
          // },
        }}
        options={options}
      />
    </>
  );
};

export default CapacityReport;
