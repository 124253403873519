
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

require("./Dashboard.scss");


const Dashboard = () => {


  let location = useLocation();
  useEffect(() => {
    console.log("pageview", location.pathname);
  }, [location]);


  return (
  
    <div></div>
  );
};

export default Dashboard;
