import { useRef } from "react";
import "antd/dist/antd.css";
import {
  notification,
  Button,
  Table,
  Select,
  InputNumber,
  Progress,
  Row,
  Col,
  Modal,
  Popover,
} from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AxiosConfig } from "../../../ApiConfig";
import ButtonReuse from "../../../components/Buttons/Button";
import { enumForEditExpenseDetailsSection } from "../../../utils/enums";
import { getToken } from "../../../auth";
import uniqueId from "lodash/uniqueId";
import { ColumnsType } from "antd/es/table";
import {
  EditTwoTone,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import { calculateExpenseAdjustedAmount, convertCurrency } from "../../../utils/util";
import { decimalCheck } from "../../../utils/util";
import warning from "../../../assets/images/WarningOutlined.png";
require("../Customer/AddCustomer.scss");

const { Option } = Select;

interface FinancialMonth {
  id: number;
  month: string;
  year: any;
}

interface IPoType {
  id: number;
  name: string;
  number: string;
  potype: {
    type: string;
  };
}
export interface ExpensePO {
  key: string;
  id: number;
  name: string;
  financialmonth: any;
  month_id: any;
  project_code: string;
  PurchaseOrders: IPoType[];
  po_id: any;
  earned_value: any;
}

const EditExpenseDetailSection = (props) => {
  const {
    onCancelHandler,
    expensePos,
    setExpensePos,
    editable,
    setEditable,
    expenseData,
    expensesId,
    // id,
  } = props;
  let token = getToken();

  const [totalAdjustedAmount, setTotalAdjustedAmount] = useState<string>();
  const [adjustedAmount, setAdjustedAmount] = useState<number>();
  const [pendingAmount, setPendingAmount] = useState<string>();
  const [selectedPOs, setSelectedPOs] = useState<string[]>([]);
  const [allPOs, setAllPOs] = useState<ExpensePO[]>([]);
  const [financialMonth, setFinancialMonth] = useState<FinancialMonth[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const currentEditable = useRef(-1);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<
    string | null
  >(null);
  const [financialOption, setFinancialoption] = useState([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);

  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  useEffect(() => {
    {
      financialMonthExpense(selectedPurchaseOrderId);
    }
  }, [selectedPurchaseOrderId]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response) {
        const id = response.data.data.user.id;

        getPOData(id);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getEarnedValue = async (po_id: any, month_id: any) => {
    try {
      const earnedValue = await AxiosConfig.get("poearnings/expense/" + po_id, {
        headers: { Authorization: `Bearer ${token}` },
        params: { month_id: month_id },
      });

      if (earnedValue) {
        return earnedValue?.data?.data?.po_earn_data;
      } else {
        console.error("Earned value is not found for the purchase order");
      }
    } catch (error) {
      console.error("Error in fetching Earned value", error);
    }
  };
  const ExpenseDetailsTableColumns: ColumnsType<ExpensePO> = [
    {
      title: "Project Code",
      dataIndex: "project_code",
      key: "projectCode",
      render: (_, record, index) => {
        const selectedProject = allPOs?.find(
          (project) => project?.id == expensePos[index]?.project_id
        );
        const generateLabel = (project) => {
          return project
            ? `${project?.project_code} (${project?.name})`
            : "Select Project Code";
        };
        return (
          <Select
            className="select-dropdown"
            placeholder="Select Project Code"
            disabled={!editable[index]}
            value={generateLabel(selectedProject)}
            onChange={(value) => {
              handleExpenseValChange("project_id", value, index, record);
            }}
            showSearch={true}
            filterOption={(input, option) => {
              if (option && option.label) {
                return (
                  option.label
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
              return false;
            }}
          >
            {allPOs.map((po) => (
              <Option
                key={po.id}
                value={po.id}
                label={`${po.project_code} (${po.name})`}
              >
                {po.project_code}({po.name})
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Purchase Order",
      dataIndex: ["po_id"],
      key: "purchaseOrder",
      render: (_, record, index) => {
        const selectedPo = allPOs.find(
          (projects) => projects.id === expensePos[index]?.project_id
        );
        const label = selectedPo
          ? selectedPo.PurchaseOrders.find(
              (po) => po.id === expensePos[index]?.po_id
            )?.name
          : "Select PO";
        return (
          <Select
            className="select-dropdown"
            placeholder="Select PO"
            disabled={!editable[index]}
            id="purchaseOrder"
            value={label}
            showSearch={true}
            onChange={(value) => {
              setSelectedPurchaseOrderId(value);
              handleExpenseValChange("po_id", value, index, record);
            }}
            filterOption={(input, option) => {
              if (option && option.children) {
                return (
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
              return false;
            }}
          >
            {selectedPo
              ? selectedPo.PurchaseOrders.map((po) => (
                  <Option key={po.id} value={po.id}>{`${po.name}`}</Option>
                ))
              : []}
          </Select>
        );
      },
    }
    ,
    {
      title: "Financial Month",
      key: "financialMonth",
      render: (_, record, index) => {
        let financialmonthVal = record?.financialmonth
          ? `${record?.financialmonth?.month} ${record?.financialmonth?.year}`
          : "Select Financial Month";

        const isExpanded = expandedRows.includes(index.toString());
        return (
          <div className="purchase-order-row">
            <div
              className="expand-button"
              onClick={async () => {
                if (!record.earned_value) {
                  let earned_value: any = {
                    notes: "No Description available",
                    earned_value_in_base: "0",
                  };
                  record["earned_value"] = earned_value;
                  let earnedValue: any = await getEarnedValue(
                    record.po_id,
                    record.month_id
                  );
                  if (earnedValue != undefined && earnedValue != null) {
                    record.earned_value.notes = earnedValue.notes
                      ? earnedValue.notes
                      : "No Description available";
                    record.earned_value.earned_value_in_base =
                      earnedValue.earned_value_in_base
                        ? earnedValue.earned_value_in_base
                        : 0;
                  }
                }
                if (isExpanded) {
                  setExpandedRows((prev) =>
                    prev.filter((rowIndex) => rowIndex !== index.toString())
                  );
                } else {
                  setExpandedRows((prev) => [...prev, index.toString()]);
                }
              }}
            >
              {isExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
            </div>
            <Select
              className="select-dropdown-month"
              placeholder="Select Financial Month"
              disabled={!editable[index]}
              defaultValue={financialmonthVal}
              value={financialmonthVal}  
              id="financialMonth"
              onChange={(value) => {
                record.month_id = value;
                handleExpenseValChange("financialMonth", value, index, record);
              }}
              onClick={() => handleFinancialMonthClick(record)}
            >
              {financialOption && financialOption.length > 0 ? (
                financialOption.map((month: any) => (
                  <Option key={month.id} value={month.id}>
                    {`${month.month} ${month.year}`}
                  </Option>
                ))
              ) : (
                <Option value="default">
                  {enumForEditExpenseDetailsSection.labelForNoOption}
                </Option>
              )}
            </Select>
            {isExpanded && record.earned_value && (
              <div className="expanded-details-expense">
                <div>
                  <Row className="details-row">
                    <Col>
                      <p>{enumForEditExpenseDetailsSection.EarnedValue} </p>
                      <p className="light-font-poexpansion">
                        {convertCurrency((record.earned_value.earned_value_in_base || 0),currencyRate)}
                      </p>
                    </Col>
                    <Col>
                      <p>{enumForEditExpenseDetailsSection.Notes}</p>
                      <p className="light-font-poexpansion">
                        &nbsp;
                        {record?.earned_value?.notes
                          ? record.earned_value.notes
                          : "No descriptionnnnn available"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Enter Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record, index) => {
        const formatValue = (value) => {
          const floatValue = parseFloat(value);

          if (isNaN(floatValue)) {
            return "";
          }

          const roundedValue = parseFloat(floatValue.toFixed(2));
          return roundedValue.toString();
        };

        const parseValue = (value) => {
          const floatValue = parseFloat(value.replace(/[^0-9.]/g, ""));

          if (isNaN(floatValue)) {
            return undefined;
          }

          return floatValue;
        };

        return !editable[index] ? (
          <span>
            {expensePos[index]?.amount !== undefined ? convertCurrency(expensePos[index].amount,currencyRate) : ""}
          </span>
        ) : (
          <InputNumber
            value={expensePos[index]?.amount}
            min={0}
            step={0.01}
            formatter={formatValue}
            parser={parseValue}
            onChange={(value) => {
              handleExpenseValChange("amount", value, index, record);
            }}
          />
        );
      },
    },

    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record, index) => {
        return editable[index] ? (
          <Button
            onClick={() => {
              handleDoneButton(record, index);
            }}
          >
            {enumForEditExpenseDetailsSection.labelforDoneButton}
          </Button>
        ) : (
          <EditTwoTone
            onClick={() => {
              setEditable((prevEditable) => {
                const updatedEditable = [...prevEditable];
                updatedEditable[index] = true;
                return updatedEditable;
              });
            }}
          />
        );
      },
    },
  ];

  const isActive = (status: any) => {
    if (status == 0) {
      return "Future";
    } else if (status == 1) {
      return "Active";
    } else {
      return "Past";
    }
  };
  const statusValidation = (status: number) => {
    if (status == 0 || status == 1) {
      return true;
    } else {
      return false;
    }
  };
  const financialMonthExpense = async (po_id: any) => {
    try {
      const response = await AxiosConfig.get(`expense/months/${po_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFinancialoption(
        response.data.data.months.map(
          (row: { id: any; month: any; year: any }) => ({
            id: row.id,
            month: row.month,
            year: row.year,
          })
        )
      );
    } catch (error) {
      console.error("", error);
    }
  };

  const handleDoneButton = async (record, index) => {
    const po_id = expensePos[index]?.po_id;
    const amount = expensePos[index]?.amount;
    const project_id = expensePos[index]?.project_id;
    const month_id = expensePos[index]?.month_id;

    if (
      !po_id ||
      amount === null ||
      amount === "" ||
      !project_id ||
      !month_id
    ) {
      if (amount === "") {
        expensePos[index].amount = 0;
      }
      notification.error({
        message: "Error",
        description:
          "Please select a project code, PO, select a Financial Month, and provide the amount.",
        style: { color: "red" },
      });
      return;
    }

    const earnedValue = record?.earned_value?.earned_value_in_base
      ? record.earned_value.earned_value_in_base
      : 0;
    if (amount > earnedValue) {
      setModalIndex(index);
      setIsModalVisible(true);
    } else {
      setEditable((prevEditable) => {
        const updatedEditable = [...prevEditable];
        updatedEditable[index] = false;
        return updatedEditable;
      });
    }
  };

  const handleModalYes = () => {
    setIsModalVisible(false);

    const modalIndexValue: any = modalIndex;
    if (modalIndexValue !== null) {
      if (modalIndexValue >= 0 && modalIndexValue < expensePos.length) {
        setEditable((prevEditable) => {
          const updatedEditable = [...prevEditable];
          updatedEditable[modalIndexValue] = false;
          return updatedEditable;
        });
      }
    }

    setModalIndex(null);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getFinancialData();
  }, []);

  const getFinancialData = async () => {
    try {
      const res = await AxiosConfig.get(`financialmonths`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.status === 200) {
        setFinancialMonth(
          res.data.data.months.map(
            (row: {
              id: any;
              month: any;
              year: any;
              status: any;
              cost_per_stu: any;
            }) => ({
              id: row.id,
              month: row.month,
              year: row.year,
              status: isActive(row.status),
              Status: row.status,
              key: row.id,
              cost: decimalCheck(row.cost_per_stu, 2),
              action: statusValidation(row.status),
            })
          )
        );
      }
    } catch (error) {
      setIsSaving(false);
      notification.error({
        message: "Error",
        description: "Error in Adjustment Updation",
        style: { color: "red" },
      });
    }
  };

  const openNotificationForPo = () => {
    notification.open({
      message: "Expense adjusted for the PO successfully",
    });
  };

  const openNotificationForRequiredField = () => {
    notification.open({
      message: "Please fill in all the required fields before proceeding.",
    });
  };
  const openNotificationForMonthField = () => {
    notification.open({
      message:
        "Expense PO value already exists for the selected Month.Please select different month",
    });
  };

  const handleFinancialMonthClick = (record) => {
    const po_id = record.po_id;

    if (po_id) {
      financialMonthExpense(po_id);
    } else {
      console.error("No po_id available for this record.");
    }
  };

  const handleExpenseValChange = async (
    fieldName: string,
    value: any,
    index: number,
    record: any
  ) => {
    const expenseaId = expensesId.map((row: { id: any }) => ({
      id: row.id,
    }));
    const selectedPOId = value as string;
    if (fieldName === "project_id") {
      record.po_id = "";
      record.earned_value = null;
      record.financialmonth = null;
    }
    if (fieldName === "po_id") {
      record.financialmonth = null;
      record.earned_value = null;
    }

    let selectedMonth;
    let monthExists = validateMonth(
      expensePos,
      record.month_id,
      record.po_id,
      record.project_id
    );

    if (monthExists === false) {
      selectedMonth = financialMonth.find(
        (month) => month.id == record.month_id
      );
      if (record && record.financialmonth && selectedMonth) {
        record.financialmonth = {
          month: selectedMonth.month,
          year: selectedMonth.year,
        };
      }
    }

    if (monthExists === true) {
      openNotificationForMonthField();
    }
    if (fieldName === "financialMonth") {
      if (record.po_id && record.month_id) {
        let earned_value: any = {
          notes: "No Description available",
          earned_value_in_base: "0",
        };
        record["earned_value"] = earned_value;
        let earnedValue: any = await getEarnedValue(
          record.po_id,
          record.month_id
        );

        if (earnedValue != undefined && earnedValue != null) {
          record.earned_value = {
            notes: earnedValue.notes
              ? earnedValue.notes
              : "No Description available",
            earned_value_in_base: earnedValue.earned_value_in_base
              ? earnedValue.earned_value_in_base
              : 0,
          };
        }
      }
      record.financialmonth = financialMonth.find(
        (month) => month.id == record.month_id
      );
    }

    let updatedExpense;

    if (record.id) {
      const selectedExpenseId = expenseaId[index].id;

      updatedExpense = {
        ...expensePos[index],
        [fieldName]: selectedPOId,
        id: selectedExpenseId,
        month_id: selectedMonth ? selectedMonth.id : null,
      };
    } else {
      updatedExpense = {
        ...expensePos[index],
        [fieldName]: selectedPOId,
        month_id: selectedMonth ? selectedMonth.id : null,
      };
    }

    setExpensePos((prevExpense) => [
      ...prevExpense.slice(0, index),
      updatedExpense,
      ...prevExpense.slice(index + 1),
    ]);
  };

  const validateMonth = (
    expensePos: any,
    month_id: any,
    po_id: any,
    project_id: any
  ) => {
    try {
      const monthData = expensePos.filter(
        (expPo: any) =>
          expPo.month_id == month_id &&
          expPo.po_id == po_id &&
          expPo.project_id == project_id
      );
      let monthDataLength = Object.keys(monthData).length;
      if (monthDataLength >= 2) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setIsSaving(false);
      notification.error({
        message: "Error",
        description: "Error in Adjustment Updation",
        style: { color: "red" },
      });
    }
  };
  const handleAdjustAgainstPO = () => {
    setSelectedPOs([]);

    const expenseval = expensePos.some(
      (eachPo) =>
        eachPo.amount === null || eachPo.amount === "" || !eachPo.po_id
    );
    if (expenseval) {
      openNotificationForRequiredField();
    } else {
      setEditable((prevEditable) => {
        if (Array.isArray(prevEditable)) {
          return [true, ...prevEditable];
        }
        return prevEditable;
      });
      currentEditable.current = 0;

      const newRow = {
        key: uniqueId(),
        po_id: "",
        amount: "",
        project_id: "",
        month_id: "",
      };

      const updatedExpensePos = [newRow, ...expensePos];

      setExpensePos(updatedExpensePos);
    }
  };

  const handleSaveAdjustment = async () => {
    try {
      const isValidData = expensePos.every(
        (item) =>
          item.po_id &&
          item.project_id &&
          item.amount !== null &&
          item.amount !== "" && 
          item.financialmonth!==null
      );

      if (!isValidData) {
        notification.error({
          message: "Error",
          description:
            "Please select a project code, PO, select a Financial Month, and provide the amount.",
          style: { color: "red" },
        });
        return;
      }

      if (pendingAmount !== undefined && parseFloat(pendingAmount) < 0) {
        notification.error({
          message: "Error",
          description:
            "Pending amount cannot be negative. Please Check While adjusting your expenses.",
          style: { color: "red" },
        });
        return;
      }

      const payload = {
        expensePO: expensePos.map((item) => {
          if (item.id)
            return {
              id: item.id,
              po_id: item.po_id,
              amount: item.amount,
              project_id: item.project_id,
              month_id: item.month_id,
              projected_expenses: item.projected_expenses ?? 0,
            };
          else
            return {
              po_id: item.po_id,
              amount: item.amount,
              project_id: item.project_id,
              month_id: item.month_id,
              projected_expenses: item.projected_expenses ?? 0,
            };
        }),
      };

      setIsSaving(true);
      const res = await AxiosConfig.post(
        `expense/po/${expenseData?.id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status === 200) {
        openNotificationForPo();
        window.setTimeout(function () {
          location.reload();
        }, 1000);
      } else {
        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
      notification.error({
        message: "Error",
        description: "Invalid adjustment amount",
        style: { color: "red" },
      });
    }
  };

  // useEffect(() => {
  //   getPOData(id);
  // }, [id]);

  const getPOData = async (id) => {
    try {
      const res = await AxiosConfig.get(`expense/projects/` + id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setEditable(Array(expensePos.length).fill(false));
        setAllPOs(res.data.data.projects);
        const selectedPOIds = selectedPOs.map((poId) => parseInt(poId, 10));
        const filteredPOData = res.data.data.projects.filter(
          (po) => !selectedPOIds.includes(po.id)
        );
        setAllPOs(filteredPOData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const { totalAdjustedAmount, adjustedAmount, pendingAmount } =
      calculateExpenseAdjustedAmount(expensePos, expenseData.amount);

    setTotalAdjustedAmount(totalAdjustedAmount);
    setAdjustedAmount(adjustedAmount);
    setPendingAmount(pendingAmount);
  }, [expensePos, expenseData]);

  const renderSummaryRow = (pageData) => {
    const totalAmount = pageData.reduce(
      (total, item) => total + (item.amount || 0),
      0
    );
    const formattedTotalAmount = totalAmount.toFixed(2);
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} className="totalText">
          {enumForEditExpenseDetailsSection.labelForTotal}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} className="totalText">
          {convertCurrency((formattedTotalAmount || 0), currencyRate)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} />
      </Table.Summary.Row>
    );
  };

  const renderSummary = (pageData) => {
    return <Table.Summary fixed>{renderSummaryRow(pageData)}</Table.Summary>;
  };
  return (
    <>
      <div className="container">
        <div className="toatlpercentcontainer">
          <div className="totalpercent">
            <label className="progress-label">
              {enumForEditExpenseDetailsSection.totalPercent}
            </label>
            <label>
              <span className="Fontchanges">{totalAdjustedAmount}%</span>
            </label>
          </div>
          <div className="progress-bar-container">
            <Progress
              percent={Number(totalAdjustedAmount)}
              strokeWidth={3}
              showInfo={false}
            />
          </div>
        </div>
        <div className="toatlpercentcontainer">
          <label className="progress-label">
            {enumForEditExpenseDetailsSection.calculation}
          </label>
          <div className="AdjustedPending">
            <span className="Fontchanges">
              {adjustedAmount !== undefined
                ? convertCurrency(adjustedAmount, currencyRate) : "N/A"}
              {"  /  "}
            </span>
            {pendingAmount !== undefined  ? convertCurrency((parseFloat(pendingAmount) || 0), currencyRate) : "N/A"}
          </div>
        </div>
        <div className="adjustPobutton">
          <Button
            type="primary"
            className="primary-btn"
            onClick={handleAdjustAgainstPO}
          >
            {enumForEditExpenseDetailsSection.buttonForAdjustAgainstPO}
          </Button>
        </div>
      </div>
      <Modal
        title={
          <span>
            <Popover
              content="The expense amount is greater than the earned value of the selected PO.
        Do you want to continue?"
              trigger="hover"
            >
              <img
                src={warning}
                style={{ cursor: "pointer", marginLeft: 12 }}
              />
            </Popover>
            &nbsp; {enumForEditExpenseDetailsSection.labelForWarningTitle}
          </span>
        }
        visible={isModalVisible}
        onOk={handleModalYes}
        onCancel={handleModalCancel}
        footer={[
          <Button key="yes" type="primary" onClick={handleModalYes}>
            {enumForEditExpenseDetailsSection.labelForYes}
          </Button>,
          <Button key="cancel" onClick={handleModalCancel}>
            {enumForEditExpenseDetailsSection.labelForCancel}
          </Button>,
        ]}
      >
        {enumForEditExpenseDetailsSection.warningMessage}
      </Modal>
      <>
        <div>
          <Table
            className="CreditApplicationTable"
            columns={ExpenseDetailsTableColumns}
            dataSource={expensePos}
            pagination={false}
            summary={renderSummary}
          />
        </div>
        <div>
          <div className="button-container">
            <ButtonReuse
              type="primary"
              className="primary-btn"
              htmlType="submit"
              value="Save"
              onClick={handleSaveAdjustment}
              disabled={isSaving}
            ></ButtonReuse>
            <ButtonReuse
              type="primary"
              className="primary-btn cancel--btn"
              value="Cancel"
              onClick={onCancelHandler}
            ></ButtonReuse>
          </div>
        </div>
      </>
    </>
  );
};

export default EditExpenseDetailSection;
