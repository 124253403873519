import { Bar } from "react-chartjs-2";
import { Row, Col, Checkbox, Tabs, Typography, Card } from "antd";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useSelector } from "react-redux";
import { convertCurrency, convertNumber, formatCurrencyValue } from "../../../utils/util";

require("./buDashboard.scss");

const BuDashboardSalesDetailView = () => {
  const { TabPane } = Tabs;
  const { Text } = Typography;
  function callback(key: any) {
    console.log(key);
  }
  let token = getToken();

  const selectedSkillIndex = useRef(null);
  const [associatedBu, setAssociatedBu] = useState([]);
  const [idArr, setIdArr] = useState<any>([]); //Initital Array
  const [poTypeOne, setPoTypeOne] = useState("");
  const [poTypeTwo, setPoTypeTwo] = useState("");
  const [poTypeThree, setPoTypeThree] = useState("");
  const [isLoad, setLoad] = useState(false);
  const [stusSold, setStusSold] = useState("");
  const [yearlyValue, setYearlyValue] = useState("");
  const [DirectExpense, setDirectExpense] = useState("");
  const [pendingValue, setPendingValue] = useState("");
  const [pendingStus, setPendingStus] = useState("");

  const [msTotalStus, setmsTotalStus] = useState([]);

  const [msfixedPriceValues, setmsfixedPriceValues] = useState([]);
  const [msTimeMaterialValues, setmsTimeMaterialValues] = useState([]);
  const [msRetainerValues, setmsRetainerValues] = useState([]);

  const [msfixedPriceStusSold, setmsfixedPriceStusSold] = useState([]);
  const [msTimeMaterialStusSold, setmsTimeMaterialStusSold] = useState([]);
  const [msRetainerStusSold, setmsRetainerStusSold] = useState([]);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  const [skillType, setSkillType] = useState([]);
  const [skillsData, setskillsData] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);

  const [monthlyAverage, setMonthlyAverage] = useState<any>([]);

  const months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  const fin_year_start =
    localStorage.getItem("selectedFy")?.split("-")[0] ?? "2021";
  const fin_year_end =
    localStorage.getItem("selectedFy")?.split("-")[1] ?? "2022";

  let removedLegend: any = [];

  useEffect(() => {
    getAssociatedBu().then(() => {
      getProjectTypes();
    });
  }, []);

  const getAssociatedBu = async () => {
    await AxiosConfig.get("users/current", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setAssociatedBu(
        res.data.data.user.departments.filter(function (associatedBu: {
          flag: any;
        }) {
          return associatedBu.flag === true;
        })
      );
      if (!localStorage.getItem("associatedBu")) {
        localStorage.setItem(
          "associatedBu",
          JSON.stringify(
            res.data.data.user.departments.filter(function (associatedBu: {
              flag: any;
            }) {
              return associatedBu.flag === true;
            })
          )
        );
      }

      let associatedBuList: any = localStorage.getItem("fixedAssociatedBu");
      associatedBuList = JSON.parse(associatedBuList);
      setAssociatedBu(
        associatedBuList.filter(function (associatedBu: { flag: any }) {
          return associatedBu.flag === true;
        })
      );
    });

    let associatedBuList: any = localStorage.getItem("fixedAssociatedBu");
    associatedBuList = JSON.parse(associatedBuList);
    setAssociatedBu(
      associatedBuList.filter(function (associatedBu: { flag: any }) {
        return associatedBu.flag === true;
      })
    );
  };

  const getProjectTypes = async () => {
    await AxiosConfig.get("potypes", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPoTypeOne(res.data.data.poTypes[0].type);
        setPoTypeTwo(res.data.data.poTypes[1].type);
        setPoTypeThree(res.data.data.poTypes[2].type);
        setLoad(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setId();
  }, [associatedBu]);

  const setId = () => {
    const idArrList: any = [];
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    associatedBuList?.map((row: { id: any }) => idArrList.push(row.id));
    setIdArr(idArrList);
  };

  const convertStus = (number) => {
    if (number === null) {
      return "0";
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const convertInt = (number: any) => {
    return Number(number.replace(/[$,]/g, ""));
  };

  useEffect(() => {
    if (isLoad) {
      getSkills();
      getAllData(allValues);
      getRevenueData(allValues);
    }
  }, [idArr, isLoad]);

  const getAllData = (values: any) => {
    localStorage.setItem("isFYChange", "not changed");
    let tempArray1: any = [];
    let tempArray2: any = [];
    // let tempArray3: any = [];
    AxiosConfig.post("/dashboards/totalstu", values, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const totalStusList: any = res.data.data.skillDetails.map(
        (value: any) => value.totalStus[0].stusCapacity
      );
      setmsTotalStus(totalStusList);
      AxiosConfig.post("/dashboards/sales", values, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res?.data?.data?.yearlySales?.length) {
            setStusSold("0");
            setYearlyValue("0");
          }
          if (!res?.data?.data?.stusOffset?.length) {
            setPendingStus("0");
          }
          if (!res?.data?.data?.futureOffset?.length) {
            setPendingValue("0");
          }
          setDirectExpense(
            res?.data?.data?.yearlySales[0]?.direct_expense + "" || "0"
          );
          console.log(
            "Direct: ",
            res?.data?.data?.yearlySales[0]?.direct_expense
          );
          let convNum: any = "";
          let convStus: any = "";
          res.data.data.yearlySales.map((item: any) => {
            convNum = formatCurrencyValue(
              currencySymb?.currency_code,
              item.$Value
            );
            setYearlyValue(convNum);
            convStus = convertStus(item.stus_sold);
            setStusSold(convStus);
          });
          res.data.data.stusOffset.map((item: any) => {
            const convStusPending = convertStus(
              Number(convStus.replace(/[,₹]/g, "")) - item.stus_offset
            );
            setPendingStus(convStusPending);
          });
          res.data.data.futureOffset.map((item: any) => {
            const convNumPending = formatCurrencyValue(
              currencySymb?.currency_code,
              item.future_offset - Number(convNum.replace(/[,₹]/g, ""))
            );
            setPendingValue(convNumPending);
          });
          res.data.data.monthlySales.map((item: any) => {
            tempArray1.push(item.stus_sold);
            tempArray2.push(
              item.month.slice(0, 3).toUpperCase() +
                " " +
                String(item.year).substring(2, 4)
            );
          });
          const msFixedPriceVal = res.data.data.monthlySales.filter(
            (eachItem: any) => eachItem.type == poTypeOne
          );
          const msTimeMaterialVal = res.data.data.monthlySales.filter(
            (eachItem: any) => eachItem.type == poTypeTwo
          );

          const msRetainerVal = res.data.data.monthlySales.filter(
            (eachItem: any) => eachItem.type == poTypeThree
          );

          let msFixedPriceValueData: any = [];
          let msTimeMaterialValueData: any = [];
          let msRetainerValueData: any = [];

          let msFixedPriceStusData: any = [];
          let msTimeMaterialStusData: any = [];
          let msRetainerStusData: any = [];

          let msTotalValueData: any = [];
          let msAvgData: any = [];

          loop1: for (let i = 0; i < months.length; i++) {
            for (let j = 0; j < msFixedPriceVal.length; j++) {
              if (msFixedPriceVal[j].month == months[i]) {
                msFixedPriceValueData.push(msFixedPriceVal[j].$Value);
                msFixedPriceStusData.push(msFixedPriceVal[j].stus_sold);
                continue loop1;
              }
            }
            msFixedPriceValueData.push(null);
            msFixedPriceStusData.push(null);
          }
          loop2: for (let i = 0; i < months.length; i++) {
            for (let j = 0; j < msTimeMaterialVal.length; j++) {
              if (msTimeMaterialVal[j].month == months[i]) {
                msTimeMaterialValueData.push(msTimeMaterialVal[j].$Value);
                msTimeMaterialStusData.push(msTimeMaterialVal[j].stus_sold);
                continue loop2;
              }
            }
            msTimeMaterialValueData.push(null);
            msTimeMaterialStusData.push(null);
          }
          loop3: for (let i = 0; i < months.length; i++) {
            for (let j = 0; j < msRetainerVal.length; j++) {
              if (msRetainerVal[j].month == months[i]) {
                msRetainerValueData.push(msRetainerVal[j].$Value);
                msRetainerStusData.push(msRetainerVal[j].stus_sold);
                continue loop3;
              }
            }
            msRetainerValueData.push(null);
            msRetainerStusData.push(null);
          }
          setmsfixedPriceValues(msFixedPriceValueData);
          setmsTimeMaterialValues(msTimeMaterialValueData);
          setmsRetainerValues(msRetainerValueData);

          setmsfixedPriceStusSold(msFixedPriceStusData);
          setmsTimeMaterialStusSold(msTimeMaterialStusData);
          setmsRetainerStusSold(msRetainerStusData);

          for (let i = 0; i < months.length; i++) {
            msTotalValueData.push(
              msFixedPriceValueData[i] +
                msTimeMaterialValueData[i] +
                msRetainerValueData[i]
            );
            if (totalStusList[i] != 0) {
              msAvgData.push(msTotalValueData[i] / totalStusList[i]);
            } else if (totalStusList[i] == 0) {
              msAvgData.push(null);
            }
          }
          setMonthlyAverage(msAvgData);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const calculateMonthlyAvg = (chart: any, legendItem: any) => {
    const lastIndex = chart.data.datasets.length - 1;
    let msTotalValueData: any = [];
    // let msTotalStusData: any = [];
    let msAvgData: any = [];
    for (let i = 0; i < months.length; i++) {
      let tempTotalValue: any = null;
      for (let j = 0; j < lastIndex; j++) {
        if (!removedLegend.includes(j))
          tempTotalValue += chart.data.datasets[j].data[i];
      }
      msTotalValueData.push(tempTotalValue);
      if (msTotalStus[i] != 0) {
        msAvgData.push(msTotalValueData[i] / msTotalStus[i]);
      } else if (msTotalStus[i] == 0) {
        msAvgData.push(null);
      }
    }

    chart.data.datasets[lastIndex].data = msAvgData;
    chart.update();
    for (let i = 0; i < lastIndex; i++) {
      if (removedLegend.includes(i)) {
        chart.hide(i);
        legendItem.hidden = true;
      } else {
        chart.show(i);
        legendItem.hidden = false;
      }
    }
  };
  let history = useHistory();

  const getMonthlySales = (monthIndex: any, po_type_id_array: any) => {
    const month = months[monthIndex];
    const departments_id_array: any = idArr;
    const fin_year_start = localStorage.getItem("selectedFy")?.split("-")[0];
    const fin_year_end = localStorage.getItem("selectedFy")?.split("-")[1];

    let year: any = monthIndex >= 9 ? fin_year_end : fin_year_start;
    year = parseInt(year);

    const data: any = { departments_id_array, po_type_id_array, month, year };
    history.push({ pathname: "/monthlysales", state: data });
  };

  const allValues = {
    departments_id_array: idArr,
    fin_year_start: parseInt(fin_year_start),
    fin_year_end: parseInt(fin_year_end),
  };
  localStorage.getItem("isFYChange") === "changed" && getAllData(allValues);

  let skillSetArray: any = [];

  skillType.forEach(() => {
    skillSetArray.push([]);
  });

  const getSkills = () => {
    let tempArray1: any = [];
    AxiosConfig.get("skills", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        res.data.data.skills.map((item: any) => {
          tempArray1.push(item.name);
        });
        setSkillType(tempArray1);
      })
      .catch((err) => console.log(err));
  };

  const getSkillData = (values: any) => {
    AxiosConfig.post("dashboards/skills", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        for (let k = 0; k < skillType.length; k++) {
          loop1: for (let i = 0; i < res.data.data.skillDetails.length; i++) {
            for (
              let j = 0;
              j < res.data.data.skillDetails[i].skillDetail.length;
              j++
            ) {
              if (
                res.data.data.skillDetails[i].skillDetail[j].skill ==
                skillType[k]
              ) {
                skillSetArray[k].push(
                  res.data.data.skillDetails[i].skillDetail[j].stusCapacity
                );
                continue loop1;
              }
            }
            skillSetArray[k].push(null);
          }
        }
        setskillsData(skillSetArray);
      })
      .catch((err) => console.log(err));
  };

  const getRevenueData = (values: any) => {
    // let tempArray1: any = [];
    // let tempArray2: any = [];

    AxiosConfig.post("dashboards/revenue", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const msFixedPriceVal = res.data.data.monthlyRevenue.filter(
          (eachItem: any) => eachItem.type == poTypeOne
        );

        const msTimeMaterialVal = res.data.data.monthlyRevenue.filter(
          (eachItem: any) => eachItem.type == poTypeTwo
        );

        const msRetainerVal = res.data.data.monthlyRevenue.filter(
          (eachItem: any) => eachItem.type == poTypeThree
        );

        let msFixedPriceValueData: any = [];
        let msTimeMaterialValueData: any = [];
        let msRetainerValueData: any = [];

        let msFixedPriceStusData: any = [];
        let msTimeMaterialStusData: any = [];
        let msRetainerStusData: any = [];

        let msRevenueTotalValueData: any = [];
        let msRevenueTotalStusData: any = [];
        let msRevenueAvgData: any = [];

        loop1: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < msFixedPriceVal.length; j++) {
            if (msFixedPriceVal[j].month == months[i]) {
              msFixedPriceValueData.push(msFixedPriceVal[j].Revenue_Value);
              msFixedPriceStusData.push(msFixedPriceVal[j].stus_expended);
              continue loop1;
            }
          }
          msFixedPriceValueData.push(null);
          msFixedPriceStusData.push(null);
        }
        loop2: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < msTimeMaterialVal.length; j++) {
            if (msTimeMaterialVal[j].month == months[i]) {
              msTimeMaterialValueData.push(msTimeMaterialVal[j].Revenue_Value);
              msTimeMaterialStusData.push(msTimeMaterialVal[j].stus_expended);
              continue loop2;
            }
          }
          msTimeMaterialValueData.push(null);
          msTimeMaterialStusData.push(null);
        }
        loop3: for (let i = 0; i < months.length; i++) {
          for (let j = 0; j < msRetainerVal.length; j++) {
            if (msRetainerVal[j].month == months[i]) {
              msRetainerValueData.push(msRetainerVal[j].Revenue_Value);
              msRetainerStusData.push(msRetainerVal[j].stus_expended);
              continue loop3;
            }
          }
          msRetainerValueData.push(null);
          msRetainerStusData.push(null);
        }

        for (let i = 0; i < months.length; i++) {
          msRevenueTotalValueData.push(
            msFixedPriceValueData[i] +
              msTimeMaterialValueData[i] +
              msRetainerValueData[i]
          );
          msRevenueTotalStusData.push(
            msFixedPriceStusData[i] +
              msTimeMaterialStusData[i] +
              msRetainerStusData[i]
          );
          if (msRevenueTotalStusData[i] != 0) {
            msRevenueAvgData.push(
              msRevenueTotalValueData[i] / msRevenueTotalStusData[i]
            );
          } else if (msRevenueTotalStusData[i] == 0) {
            msRevenueAvgData.push(null);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  function onChange(e: any) {
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    let value = e.target.value;
    if (e.target.checked == true) {
      const selectedBu = associatedBu.filter((bu: any) => bu.id === value);
      associatedBuList.push(...selectedBu);
      localStorage.setItem("associatedBus", JSON.stringify(associatedBuList));
      setIdArr([...idArr, value]);
    } else {
      const index = idArr.indexOf(value);
      idArr.splice(index, 1);
      associatedBuList.splice(index, 1);
      localStorage.setItem("associatedBus", JSON.stringify(associatedBuList));
      setIdArr([...idArr]);
    }
  }

  const isChecked = (id: any) => {
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    if (associatedBuList) {
      for (const bu of associatedBuList) {
        if (bu.id === id) return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const crypto = require("crypto");

  function getRandomColor() {
    const randomBytes = crypto.randomBytes(3);
    const red = randomBytes[0];
    const green = randomBytes[1];
    const blue = randomBytes[2];

    const randomColor = `rgb(${red}, ${green}, ${blue})`;
    return randomColor;
  }
  const dataValue = {
    labels: months,
    datasets: [
      {
        label: poTypeOne,
        data: msfixedPriceValues,
        // backgroundColor: "#5C67E5",
        backgroundColor: "rgba(92, 103, 229, 0.6)",
        yAxisID: "y",
      },
      {
        label: poTypeTwo,
        data: msTimeMaterialValues,
        // backgroundColor: "#F8C053",
        backgroundColor: "rgba(248, 192, 83, 0.6)",
        yAxisID: "y",
      },
      {
        label: poTypeThree,
        data: msRetainerValues,
        // backgroundColor: "#36CCED",
        backgroundColor: "rgba(54, 204, 237, 0.6)",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Average sales price",
        data: monthlyAverage,
        borderColor: "#FF7A37",
        backgroundColor: "#fff",
        yAxisID: "y1",
      },
    ],
  };
  const dataStus = {
    labels: months,
    datasets: [
      {
        label: poTypeOne,
        data: msfixedPriceStusSold,
        backgroundColor: "#5C67E5",
      },
      {
        label: poTypeTwo,
        data: msTimeMaterialStusSold,
        backgroundColor: "#F8C053",
      },
      {
        label: poTypeThree,
        data: msRetainerStusSold,
        backgroundColor: "#36CCED",
      },
    ],
  };

  const valueConfig = {
    type: "bar",
    data: dataValue,
    options: {
      onClick: function (e: any, chartItem: any) {
        const po_type_id_array: any = [];
        const monthIndex = chartItem[0]?.index;
        chartItem.map((value: any) => {
          if (value.datasetIndex != 3) {
            po_type_id_array.push(value.datasetIndex + 1);
          }
        });
        monthIndex != undefined &&
          getMonthlySales(monthIndex, po_type_id_array);
      },
      plugins: {
        title: {
          display: true,
        },
        legend: {
          onClick: function (e: any, legendItem: any, legend: any) {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;

            if (index != 3) {
              if (ci.isDatasetVisible(index)) {
                removedLegend.push(index);
              } else {
                removedLegend = removedLegend.filter(
                  (value: any) => value != index
                );
              }
              calculateMonthlyAvg(ci, legendItem);
            } else {
              if (ci.isDatasetVisible(index)) {
                ci.hide(index);
                legendItem.hidden = true;
              } else {
                ci.show(index);
                legendItem.hidden = false;
              }
            }
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Sales",
          },
        },
        y1: {
          type: "linear",
          position: "right",
          title: {
            display: true,
            text: "Avg Sales",
          },
        },
      },
    },
  };
  const stusConfig = {
    type: "bar",
    data: dataStus,
    options: {
      onClick: function (e: any, chartItem: any) {
        const po_type_id_array: any = [];
        const monthIndex = chartItem[0]?.index;
        chartItem.map((value: any) => {
          if (value.datasetIndex != 3) {
            po_type_id_array.push(value.datasetIndex + 1);
          }
        });
        monthIndex != undefined &&
          getMonthlySales(monthIndex, po_type_id_array);
      },
      plugins: {
        title: {
          display: true,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Stus Sold",
          },
        },
      },
    },
  };
  const skillSetData = {
    labels: months,
    datasets: skillType.map((value: any, index: any) => ({
      label: value,
      data: skillsData[index],
      backgroundColor: getRandomColor(),
    })),
  };
  const skillSetDataConfig = {
    type: "bar",
    data: skillSetData,
    options: {
      plugins: {
        title: {
          display: true,
        },
        legend: {
          onClick: function (e: any, legendItem: any, legend: any) {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            if (selectedSkillIndex.current != index) {
              selectedSkillIndex.current = index;
              legend.legendItems.map((value: any, i: any) => {
                if (i != index) {
                  ci.hide(i);
                  legendItem.hidden = true;
                } else {
                  ci.show(index);
                  legendItem.hidden = false;
                }
              });
            } else {
              legend.legendItems.map((value: any, i: any) => {
                ci.show(i);
                legendItem.hidden = false;
              });
            }
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Available STU’s",
          },
        },
      },
    },
  };

  useEffect(() => {
    getSkillData(allValues);
  }, [skillType, idArr]);

  function removeCurrencySymbolAndParseToInt(currencyString) {
    const strippedString = currencyString.replace(/[₹,]/g, '');
    const convertedCurrencyValue = convertCurrency(strippedString,currencyRate)
    return convertedCurrencyValue;
}

  return (
    <>
      <div className="dasboardTabsOut">
        <div className="tabs-content tab-contenets-top">
          <Row style={{ paddingBlock: "0" }}>
            <Col md={24} xs={24}>
              <Card style={{ backgroundColor: "#f0f2f5" }}>
                <ul className="buList">
                  {associatedBu.map((row: { name: any; id: any }) => {
                    return (
                      <li key={row.id}>
                        <Checkbox
                          onChange={onChange}
                          name={row.id}
                          value={row.id}
                          defaultChecked={isChecked(row.id)}
                        >
                          {row.name}
                        </Checkbox>
                      </li>
                    );
                  })}
                </ul>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24} xs={24}>
              <div className="tabsOut">
                <div className="white-bg-widget mb20">
                  <div className="tab-contents">
                    <Row gutter={16}>
                      <Col md={8} xs={24}>
                        <Card className="white-bg-widget" bordered={false}>
                          <Row>
                            <Col md={12} xs={12}>
                              <Text className="textHeadingBu">SOLD</Text>
                            </Col>
                            <Col className="textAlignRight" md={12} xs={12}>
                              <Text className="textValueBu soldValueColor">
                                {removeCurrencySymbolAndParseToInt(yearlyValue) || "0"}
                              </Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} xs={12}>
                              <Text className="textHeadingBu">STUs Sold</Text>
                            </Col>
                            <Col className="textAlignRight" md={12} xs={12}>
                              <Text className="textValueBu soldValueColor">
                                {stusSold} STUs
                              </Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} xs={12}>
                              <Text className="textHeadingBu">Avg/STU</Text>
                            </Col>
                            <Col className="textAlignRight" md={12} xs={12}>
                              <Text className="textValueBu soldValueColor">
                                Avg{" "}
                                {convertNumber(
                                  (convertInt(
                                    yearlyValue.replace(/[,₹]/g, "")
                                  ) -
                                    parseFloat(DirectExpense)) /
                                    convertInt(stusSold),
                                  0
                                )}
                                /STUs
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col md={8} xs={24}>
                        <Card className="white-bg-widget" bordered={false}>
                          <Row>
                            <Col md={12} xs={12}>
                              <Text className="textHeadingBu">PENDING</Text>
                            </Col>
                            <Col className="textAlignRight" md={12} xs={12}>
                              <Text className="textValueBu pendingValueColor">
                                {removeCurrencySymbolAndParseToInt(pendingValue)|| "0"}
                              </Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} xs={12}>
                              <Text className="textHeadingBu">
                                STUs Pending
                              </Text>
                            </Col>
                            <Col className="textAlignRight" md={12} xs={12}>
                              <Text className="textValueBu pendingValueColor">
                                {pendingStus} STUs
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <div className="capacitySalesOut">
                      <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Sales" key="1">
                          <Bar data={dataValue} options={valueConfig.options} />
                        </TabPane>
                        <TabPane tab="Capacity view" key="2">
                          <Bar data={dataStus} options={stusConfig.options} />
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
                <div className="white-bg-widget mb20">
                  <div className="tab-contents">
                    <h5>
                      Skill set capacity report{" "}
                      {/* <Button type="primary" onClick={() => {}}>
                            Hide All
                          </Button> */}
                    </h5>
                    <Bar
                      id="skillSetGraph"
                      data={skillSetData}
                      options={skillSetDataConfig.options}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default BuDashboardSalesDetailView;
