import "./DeletePurchaseOrderModal.scss";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { notification } from "antd";
import { useState } from "react";
import { deletepurchaseOrderModal } from "../../../utils/enums";

const DeletePoModal = (props: {  onClose: () => void; show: any; rowData: any }) => {
  let token = getToken();

  const [getMessage, setMessage] = useState("PO cannot be deleted");
  const notify = () => {
    notification.open({
      message: getMessage,
    });
  };
  const openNotification = () => {
    notification.open({
      message: "Purchase Order Deleted Successfully",
    });
  };
  const deleteHandler = () => {
    let id = props.rowData;
    AxiosConfig.delete("purchaseorders/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log(res);
      if (
        res.data.status.code === 200 &&
        res.data.status.message === "PO deleted"
      ) {
        setMessage(res.data.status.message);
        {
          openNotification();
        }
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        setMessage("PO cannot be deleted");

        {
          notify();
        }
      }
    });
  };

  if (!props.show) {
    return null;
  }
  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-body">
          <p className="modal-text">{deletepurchaseOrderModal.warningMsgForDelete}</p>
          <div className="button-div">
            <button className="modal-body__button" onClick={deleteHandler}>
              {deletepurchaseOrderModal.buttonForDelete}
            </button>
            <button
              className="modal-body__button cancel--button"
              onClick={props.onClose}
            >
              {deletepurchaseOrderModal.buttonForCancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePoModal;
