import { Table, Input, Row, Col, Button } from "antd";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useHistory } from "react-router-dom";
import CsvDownloader from "react-csv-downloader";
import { ExportOutlined } from "@ant-design/icons";
import AddTalent from "./AddTalent";
import { checkTalentStatus } from "../../../utils/util";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";

require("./Talent.scss");

type AppliedFilters = {
  practice?: string[];
  band?: string[];
  bu?: string[];
  skill_set?: string[];
  status?: string[];
  secondarySkills_set?: string[];
};

const Talent = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [bands, setBands] = useState([]);
  const [practices, setPractices] = useState([]);
  const [department, setDepartments] = useState([]);
  const [skills, setSkills] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const token = getToken();
  const departmentData = useSelector(
    (state: any) => state.departments.departments
  );
  const practiceData = useSelector((state: any) => state.practices.practices);
  const bandData = useSelector((state: any) => state.bands.bands);
  const skillsData = useSelector((state: any) => state.skills.skills);
  const secondarySkillsData = useSelector((state: any) => {
    return state.secondarySkills.secondary_skills;
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>({
    status: ["Active", "Serving Notice period"],
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    searchAndFilterData();
  }, [filterTotalCount]);

  useEffect(() => {
    getTableData();
  }, [skillsData, departmentData, bandData, practiceData, secondarySkillsData]);

  useEffect(() => {
    searchAndFilterData();
  }, [nameSearch, data, appliedFilters]);

  const getTableData = async () => {
    if (departmentData.length) {
      let dept = departmentData;
      dept = dept.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setDepartments(
        dept.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    }
    if (bandData.length) {
      let band = bandData;
      band = band.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setBands(
        band.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    }
    if (practiceData.length) {
      let practice = practiceData;
      practice.push({ name: "No Practice" });
      practice = practice.filter(
        (value, index, self) =>
          index === self.findIndex((item) => item.name === value.name)
      );
      practice = practice.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setPractices(
        practice.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    }
    if (skillsData.length) {
      let skill = skillsData;
      skill = skill.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setSkills(
        skill.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
      setIsLoading(false);
    }
    if (secondarySkillsData.length) {
      let secondarySkill = secondarySkillsData;
      secondarySkill.push({ name: "No Secondary skills" });
      secondarySkill = secondarySkill.filter(
        (value, index, self) =>
          index === self.findIndex((item) => item.name === value.name)
      );
      secondarySkill = secondarySkill.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      setSecondarySkills(
        secondarySkill.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
      setIsLoading(false);
    }
  };

  const getData = async () => {
    await AxiosConfig.get("/talents", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setData(
        res.data.data.talents.map(
          (row: {
            emp_id: any;
            firstName: any;
            lastName: any;
            id: any;
            date_of_seperation: any;
            department: any;
            band: any;
            skill: any;
            secondary_skills: any;
            talent_skills: any;
            practices: any;
          }) => ({
            Emp_id: row?.emp_id,
            First_name: row?.firstName,
            Last_name: row?.lastName,
            Talent_id: row.id,
            Active_status: checkTalentStatus(row?.date_of_seperation),
            Name: `${row?.firstName} ${row?.lastName}`,
            Department: row?.department?.name,
            Band: row?.band?.name,
            Practice: row?.practices?.name
              ? row?.practices?.name
              : "No Practice",
            Skill_set: row?.skill?.name,
            Secondary_skills:
              row?.talent_skills?.length > 0
                ? row?.talent_skills?.map((skill: any) => skill.name).join(", ")
                : "No Secondary skills",
            key: row?.id,
            Full_name: `${row.firstName} ${row.lastName}`,
            Date_of_seperation: row?.date_of_seperation,
          })
        )
      );

      const tableData = res.data.data.talents.map(
        (row: {
          emp_id: any;
          firstName: any;
          lastName: any;
          id: any;
          date_of_seperation: any;
          department: any;
          band: any;
          skill: any;
          secondary_skills: any;
          talent_skills: any;
          practices: any;
        }) => ({
          Emp_id: row?.emp_id,
          First_name: row?.firstName,
          Last_name: row?.lastName,
          Talent_id: row?.id,
          Active_status: checkTalentStatus(row?.date_of_seperation),
          Department: row?.department?.name,
          Band: row?.band?.name,
          Practice: row?.practices.name,
          Skill_set: row?.skill?.name,
          Secondary_skills:
            row?.talent_skills?.length > 0
              ? row?.talent_skills?.map((skill: any) => skill.name).join(", ")
              : "No Secondary skills",
          key: row?.id,
          Full_name: `${row?.firstName} ${row?.lastName}`,
          Date_of_seperation: row?.date_of_seperation,
        })
      );
      setFilterData(tableData);
      setFilterTotalCount(tableData?.length);
    });
  };

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  let history = useHistory();

  const handleView = (record: any) => {
    history.push("/talent/" + record.Talent_id);
    localStorage.setItem("talentid", JSON.stringify(record));
  };

  const lastWorkingDay = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date);
    const day = formattedDate.getDate();
    const month = formattedDate.toLocaleString("default", { month: "long" });
    const year = formattedDate.getFullYear();

    let daySuffix;
    if (day % 10 === 1 && day !== 11) {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    return `${day}${daySuffix} ${month} ${year}`;
  };

  const columns: {
    title: string;
    dataIndex: string;
    key: string;
    width: number;
    sorter?: any;
    render?: any;
    filteredValue?: any;
    filters?: any;
    filterMultiple?: any;
    onFilter?: any;
    className?: any;
  }[] = [
    {
      title: "EMPLOYEE ID",
      dataIndex: "Emp_id",
      key: "Emp_id",
      width: 200,
      sorter: (a: any, b: any) => a.Emp_id.localeCompare(b.Emp_id),
      render: (text: any, record: any) => (
        <div
          onClick={() => handleView(record)}
          style={{ color: "#5c67e5", cursor: "pointer" }}
          className="nameTable"
        >
          {record["Emp_id"]}
        </div>
      ),
    },
    {
      title: "NAME",
      dataIndex: "Full_name",
      key: "Full_name",
      width: 400,
      render: (text: any, record: any) => (
        <div>
          <p className="nameTable">
            {record["First_name"] + " " + record["Last_name"]}
          </p>
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.Full_name.trim()
          .toLowerCase()
          .localeCompare(b.Full_name.trim().toLowerCase()),
    },
    {
      title: "BU",
      dataIndex: "Department",
      key: "bu",
      width: 200,
      filteredValue: appliedFilters.bu || null,
      filters: department,
      filterMultiple: true,
      onFilter: (value: any, record: any) => record.Department.includes(value),
    },
    {
      title: "BAND",
      dataIndex: "Band",
      key: "band",
      width: 200,
      filterMultiple: true,
      filteredValue: appliedFilters.band || null,
      filters: bands,
      onFilter: (value: any, record: any) => record.Band.includes(value),
      sorter: (a: any, b: any) =>
        a.Band.trim().toLowerCase().localeCompare(b.Band.trim().toLowerCase()),
    },
    {
      title: "Primary Skills",
      dataIndex: "Skill_set",
      key: "skill_set",
      filteredValue: appliedFilters.skill_set || null,
      filters: skills,
      width: 400,
      filterMultiple: true,
      onFilter: (value, record) => record.Skill_set.indexOf(value) === 0,
      render: (text, record) => (
        <div>
          <div className="skillsTalent">{record.Skill_set}</div>
        </div>
      ),
    },
    {
      title: "Secondary Skills",
      dataIndex: "Secondary_skills",
      key: "secondarySkills_set",
      filteredValue: appliedFilters.secondarySkills_set || null,
      filters: secondarySkills,
      width: 400,
      filterMultiple: true,
      render: (text, record) => (
        <div>
          <div className="skillsTalent">{record.Secondary_skills}</div>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "Active_status",
      key: "status",
      className: "Active",
      width: 200,
      filteredValue: appliedFilters.status || null,
      render(text: any, record: any) {
        return {
          props: {
            style: {
              color:
                text === "Active"
                  ? "green"
                  : text === "Resigned"
                  ? "red"
                  : text === "Serving Notice period"
                  ? "orange"
                  : "red",
              cursor: text === "Serving Notice period" ? "pointer" : "default",
            },
          },
          children: (
            <div>
              {text}
              {text == "Serving Notice period" && (
                <p id="statusHover">
                  <ul>
                    <p>{`Last working day: ${lastWorkingDay(
                      record.Date_of_seperation
                    )}`}</p>
                  </ul>
                </p>
              )}
            </div>
          ),
        };
      },
      filters: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "Resigned", value: "Resigned" },
        { text: "Serving Notice period", value: "Serving Notice period" },
      ],
      onFilter: (value: any, record: any) =>
        record.Active_status.includes(value),
    },
    {
      title: "Practice",
      dataIndex: "Practice",
      key: "practice",
      width: 200,
      filterMultiple: true,
      filteredValue: appliedFilters.practice || null,
      filters: practices,
      onFilter: (value: any, record: any) => {
        if (record.Practice) {
          return record.Practice.trim() === value.trim();
        }
      },
    },
  ];

  const talentHeaders = [
    { displayName: "EMPLOYEE ID", id: "Emp_id" },
    { displayName: "NAME", id: "Full_name" },
    { displayName: "BU", id: "Department" },
    { displayName: "BAND", id: "Band" },
    { displayName: "PRIMARY SKILLS", id: "Skill_set" },
    { displayName: "SECONDARY SKILLS", id: "Secondary_skills" },
    { displayName: "STATUS", id: "Active_status" },
    { displayName: "PRACTICE", id: "Practice" },
  ];

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  const handleTableChange = (pagination, filters) => {
    const { current, pageSize } = pagination;
    setAppliedFilters(filters);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const cleanAndConvertEmpId = (empId) => {
    // Remove any character that is not a digit
    const cleanedEmpId = empId.replace(/[^0-9]/g, "");
    // Convert the cleaned string to a number
    return parseInt(cleanedEmpId, 10);
  };

  const sortedFilterData = filterData.sort((a: any, b: any) => {
    const aEmpId = cleanAndConvertEmpId(a.Emp_id);
    const bEmpId = cleanAndConvertEmpId(b.Emp_id);
    return aEmpId - bEmpId;
  });

  const searchAndFilterData = () => {
    if (data) {
      const normalizedSearch = nameSearch
        ?.trim()
        ?.toLowerCase()
        ?.replace(/\s+/g, " ");
      // Split search query into individual terms
      const searchTerms = normalizedSearch.split(" ");
      const allData = data;
      const searchedData = allData?.filter((item: any) => {
        const fullName = `${item?.First_name?.toLowerCase()} ${item?.Last_name?.toLowerCase()}`;
        const searchFields = [
          item?.First_name?.toLowerCase() || "",
          item?.Last_name?.toLowerCase() || "",
          item?.Emp_id?.toLowerCase() || "",
          item?.Band?.toLowerCase() || "",
          item?.Skill_set?.toLowerCase() || "",
          item?.Department?.toLowerCase() || "",
          item?.Active_status?.toLowerCase() || "",
          item?.Secondary_skills?.toLowerCase() || "",
          item?.Practice.toLowerCase() || "",
        ];
        return searchTerms.every(
          (term) =>
            fullName?.includes(term) ||
            searchFields?.some((field) => field?.includes(term))
        );
      });
      const results = searchedData?.filter((item: any) => {
        const bandFilter =
          !appliedFilters?.band ||
          appliedFilters?.band?.length === 0 ||
          appliedFilters?.band.includes(item.Band);
        const buFilter =
          !appliedFilters?.bu ||
          appliedFilters?.bu?.length === 0 ||
          appliedFilters?.bu.includes(item.Department);
        const skillSetFilter =
          !appliedFilters?.skill_set ||
          appliedFilters?.skill_set?.length === 0 ||
          appliedFilters?.skill_set.includes(item.Skill_set);

        const secondarySkillsFilter =
          !appliedFilters?.secondarySkills_set ||
          appliedFilters?.secondarySkills_set?.length === 0 ||
          appliedFilters?.secondarySkills_set?.some((substring) =>
            item.Secondary_skills.includes(substring)
          );
        const practiceFilter =
          !appliedFilters?.practice ||
          appliedFilters?.practice?.length === 0 ||
          appliedFilters?.practice.includes(item.Practice);

        const statusFilter =
          !appliedFilters?.status ||
          appliedFilters?.status?.length === 0 ||
          appliedFilters?.status?.includes(item.Active_status);
        return (
          bandFilter &&
          buFilter &&
          skillSetFilter &&
          statusFilter &&
          secondarySkillsFilter &&
          practiceFilter
        );
      });
      setFilterData(results);
      setFilterTotalCount(results?.length);
      setPagination({ ...pagination, total: results.length });
    }
  };

  return (
    <div className="table-card">
      <Row>
        <Col md={8} xs={24}>
          <div className="searchForm">
            <Input.Search
              allowClear
              value={nameSearch}
              onChange={handleOnChange}
              placeholder="Search by Name / Band / Skillset / BU / Status /Practice"
            />
          </div>
        </Col>
        <Col md={8} xs={24}>
          <label className="totalLabel">Total #: {filterTotalCount}</label>
        </Col>
        <Col md={8} xs={24} className="text-end">
          <div className="addTalentBtn">
            <Button
              type="primary"
              onClick={visibleHandler}
              className="primary-btn"
            >
              Add Talent
            </Button>
          </div>
          <CsvDownloader
            datas={
              appliedFilters && Object.keys(appliedFilters).length > 0
                ? filterData
                    .map((item: any) => ({
                      ...item,
                      Secondary_skills: `"${item.Secondary_skills}"`,
                    }))
                    .filter((item: any) => {
                      let matchFilters = true;
                      if (
                        appliedFilters.bu &&
                        !appliedFilters.bu.includes(item.Department)
                      ) {
                        matchFilters = false;
                      }
                      if (
                        appliedFilters.band &&
                        !appliedFilters.band.includes(item.Band)
                      ) {
                        matchFilters = false;
                      }
                      if (
                        appliedFilters.skill_set &&
                        !appliedFilters.skill_set.includes(item.Skill_set)
                      ) {
                        matchFilters = false;
                      }
                      if (
                        appliedFilters.secondarySkills_set &&
                        !appliedFilters?.secondarySkills_set?.some(
                          (substring) =>
                            item.Secondary_skills.includes(substring)
                        )
                      ) {
                        matchFilters = false;
                      }
                      if (
                        appliedFilters.status &&
                        !appliedFilters.status.includes(item.Active_status)
                      ) {
                        matchFilters = false;
                      }
                      if (
                        appliedFilters.practice &&
                        !appliedFilters.practice.includes(item.Practice)
                      ) {
                        matchFilters = false;
                      }

                      return matchFilters;
                    })
                    .sort((a: any, b: any) => a.emp_id - b.emp_id)
                : sortedFilterData
            }
            filename="talents.csv"
            columns={talentHeaders}
            className="exportTableButton"
            title="Export Table"
          >
            <ExportOutlined className="exportOultineIcon" />
          </CsvDownloader>
        </Col>
      </Row>
      <AddTalent
        visible={isVisible}
        onClose={closeHandler}
        onCancelButton={closeHandler}
      />
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="tableOut noWordBreak">
          <Table
            columns={columns}
            dataSource={filterData}
            loading={isLoading}
            bordered
            className="talentTable"
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: filterTotalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"],
              showTotal: () => `Total ${filterTotalCount} items`,
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </div>
  );
};

export default Talent;
