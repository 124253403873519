import "antd/dist/antd.css";
import { Drawer } from "antd";
import Select from "react-select";
import { Formik } from "formik";
import { AxiosConfig } from "../../../../ApiConfig";
import ButtonReuse from "../../../../components/Buttons/Button";
import { useState, useEffect, useRef } from "react";
import { getToken } from "../../../../auth";
import TableInfo from "./TableInfo";
import Switch from "react-switch";
import Loader from "../../../../components/Loader/Loader";
import { checkTalentStatus } from "../../../../utils/util";
import { manageTalent } from "../../../../utils/enums";
import EditAllocation from "../../Project/AllocationDetails/Edit_Allocation";


require("./ManageTalent.scss");

const data1 = new FormData();

const AddOverlay = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  projectName?: any;
  projectId?: any;
  projectCode?: any;
  emp_id?: any;
  id?: any;
  projectMonth?:any;
  currentMonthId:any
  names?: any;
  talentData: any;
  monthid: any;
  projectDeptId:any;
  projectOwners:any;
  managersParent: any;
  empId?: any;
  monthId1? : any;
  monthId2? : any;
  monthId3? : any;

}) => {
  const onCancelHandler = () => {
    props.onCancelButton();
  };
  const token = getToken();
  const projectMonth = props.projectMonth;
  const project_id = props.projectId;
  const { currentMonthId } = props;

  const [gettalent, settalent] = useState([]);
  const [month_id, getMonthid] = useState(0);
  const [getEmpId, setEmpId] = useState();
  const [getthisMonthtotalPercentage, setthisMonthtotalPercentage] =
    useState(0);
  const [getnextMonthtotalPercentage, setnextMonthtotalPercentage] =
    useState(0);
  const [getfutureMonthtotalPercentage, setfutureMonthtotalPercentage] =
    useState(0);

  const [getthisMonthtotalHour, setthisMonthtotalHour] = useState("");
  const [getnextMonthtotalHour, setnextMonthtotalHour] = useState("");
  const [getfutureMonthtotalHour, setfutureMonthtotalHour] = useState("");
  const [getThismonthlHour, setThismonthlHour] = useState(0);
  const [getnextMonthHour, setnextMonthHour] = useState(0);
  const [getfutureMonthHour, setfutureMonthHour] = useState(0);
  const [getThisallocId, setThisallocId] = useState(0);
  const [getNextallocId, setNextallocId] = useState(0);
  const [getFutureallocId, setFutureallocId] = useState(0);
  const [talentData, setTalentData] = useState<any>({});
  const [Hour, setHour] = useState(false);
  const [temparr1, settemparr1] = useState<any[]>([]);
  const [temparr2, settemparr2] = useState<any[]>([]);
  const [temparr3, settemparr3] = useState<any[]>([]);

  const user: any = localStorage.getItem("user");
  const users = JSON.parse(user);
  const superAdmin = users.superAdmin
  const [showEditAllocation, setShowEditAllocation] = useState(false);
  const [TallocationId, setTAllocationId] = useState({});
  //Using to store month details
  const [thisMonthDetails, setthisMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      allocated_hours: "", 
      project_id: "",
    },
  ]);
  const [nextMonthDetails, setnextMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      allocated_hours: "", 
      project_id: "",
    },
  ]);
  const [futureMonthDetails, setfutureMonthDetails] = useState([
    {
      project_name: "",
      allocated_percentage: "",
      allocated_hours: "", 
      project_id: "",
    },
  ]);
  

  const thisMonthAllocatedPercent = useRef("");
  const nextMonthAllocatedPercent = useRef("");
  const futureMonthAllocatedPercent = useRef("");

  const handleSwitchChange = () => {
    setHour(!Hour);
  };

  

  useEffect(() => {
    getMonthid(currentMonthId);
    getCustomers(currentMonthId);
}, [currentMonthId]);

 
  const [tableData, setTableData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  

  const getCustomers = async (monthid: any) => {
    if(monthid){
    await AxiosConfig.get("allocations/alltalents?month_id=" + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let result: any = [];

      res.data.data.talents.map(async (talent: any) => {
        if (
          checkTalentStatus(talent.date_of_seperation) != "Inactive" &&
          checkTalentStatus(talent.date_of_seperation) != "Resigned"
        ) {
          result.push(talent);
        }
      });
      settalent(result);
    });
    }
  };

  const customerOptions = gettalent.map(function (customer: {
    firstName: any;
    lastName: any;
    emp_id: any;
    label: any;
    id: any;
  }) {
    return {
      value: customer.emp_id,
      label:
        customer.firstName +
        " " +
        customer.lastName +
        "  " +
        "(" +
        customer.emp_id +
        ")",
    };
  });
  
  function handleInputChange(params: any) {
    thisMonthAllocatedPercent.current = "";
    nextMonthAllocatedPercent.current = "";
    futureMonthAllocatedPercent.current = "";
    setIsLoading(true);

    setEmpId(params.value);
const trimmedLabel1 = params.label.split('(')[1].split(')')[0];
 const trimmedLabel = params.label.split('(')[0].trim();
const names = trimmedLabel.split(' ');

let firstName = '';
let lastName = '';

if (names.length > 0) {
  firstName = names[0];
}

if (names.length > 1) {
  lastName = names.slice(1).join(' ');
}
 const talentId = params.value;
 const monthId = month_id; 

 const talentData = {
     "First_Name": firstName,
     "Last_Name": lastName,
     "Talent_id": talentId,
     "month_id": monthId
 };
 
    if (props.empId.includes(trimmedLabel1)) {
      
      setShowEditAllocation(true);
      setTalentData(talentData);
  return; 
    }

    let emp_id = params.value;
    let user_id: any = localStorage.getItem("userId");
    AxiosConfig.get("allocations/talents", {
      headers: { Authorization: `Bearer ${token}` },
      params: { emp_id, month_id,user_id, project_id, superAdmin},
    }).then((res) => {
      let Projects = res?.data?.data?.TalentAllocations
        ? res?.data?.data?.TalentAllocations
        : [];
        const projectList = res?.data?.data?.projectList ? res?.data?.data?.projectList : [];
        const BuHead = res?.data?.data?.isBUHead
        
        const projectIdsByMonth: { project_id: number; month_id: number; id: number }[] = [];

        Projects.forEach(allocation => {
          const projectId = allocation.project_id;
          const monthId = allocation.financialmonth_id;
          const allocationId = allocation.id;
          projectIdsByMonth.push({ project_id: projectId, month_id: monthId, id: allocationId });
        });
  
        
        setTAllocationId(projectIdsByMonth);

        projectList.forEach(project => {
          const matchingData = Projects.find(item => item.project_id === project.id && item.financialmonth.month === projectMonth.ThisMonth);
          if (matchingData) {
            project.allocated_percentage = matchingData.allocated_percentage;
            project.allocated_hours = matchingData.allocated_hours;
          }
        });
  
  
        projectList.forEach(project => {
          const matchingDataNextMonth = Projects.find(item => item.project_id === project.id && item.financialmonth.month === projectMonth.NextMonth);
          if (matchingDataNextMonth) {
              project.allocated_percentage_next_month = matchingDataNextMonth.allocated_percentage;
              project.allocated_hours_next_month = matchingDataNextMonth.allocated_hours;
          }
      
          const matchingDataFutureMonth = Projects.find(item => item.project_id === project.id && item.financialmonth.month === projectMonth.FutureMonth);
          if (matchingDataFutureMonth) {
              project.allocated_percentage_future_month = matchingDataFutureMonth.allocated_percentage;
              project.allocated_hours_future_month = matchingDataFutureMonth.allocated_hours;
          }
      });

      if (Projects.length >= 0) {
        setTableData(false);
        setIsLoading(false);
      }
      const temparr1: any = [];
    
      const temparr2: any = [];
     
      const temparr3: any = [];
    


      Projects.forEach((row: any) => {
        if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.ThisMonth
        ) {
          setThisallocId(row.id);
        } else if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.NextMonth
        ) {
          setNextallocId(row.id);
        } else if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.FutureMonth
        ) {
          setFutureallocId(row.id);
        }
      });
      if (Projects.length > 0) {
        Projects.map((row: any) => {
          if (row?.project?.name) {
            if (row?.financialmonth?.month == projectMonth.ThisMonth) {
              temparr1.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                thisMonthAllocatedPercent.current = row?.allocated_percentage;
                setthisMonthtotalHour(row?.allocated_hours);
               
              }
            } else if (row?.financialmonth.month == projectMonth.NextMonth) {
              temparr2.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                nextMonthAllocatedPercent.current = row?.allocated_percentage;
                setnextMonthtotalHour(row?.allocated_hours);
                
              }
            } else if (row?.financialmonth?.month == projectMonth.FutureMonth) {
              temparr3.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                futureMonthAllocatedPercent.current = row?.allocated_percentage;
                setfutureMonthtotalHour(row?.allocated_hours);
                
              }
            }
          }
        });
      }

      settemparr1([...temparr1])
      
      setthisMonthDetails(
       
          [
            
            ...projectList.map(project => ({
              project_name: project.name,
              allocated_percentage: project.allocated_percentage||"",
              allocated_hours:project.allocated_hours|| "",
              project_id: project.id,
            })),
          
           
          ]
      );


      if (superAdmin) {
        const defaultAllocation = {
          project_name: props.projectName,
          allocated_percentage: "", 
          allocated_hours: "", 
          project_id: props.id,
        };
      
        const project = Projects.find(item => item.project_id === props.projectId && item.financialmonth.month === projectMonth.ThisMonth);
        
        if (project && project.allocated_percentage !== undefined && project.allocated_hours !== undefined) {
          
          setthisMonthDetails([
            {
              ...defaultAllocation,
              allocated_percentage: project.allocated_percentage,
              allocated_hours: project.allocated_hours,
            }
          ]);
        } else {

          setthisMonthDetails([defaultAllocation]);
        }
      }
      
      if (BuHead ) {

        setthisMonthDetails(
         
            [
             
              ...projectList.map(project => ({
                project_name: project.name,
                allocated_percentage: project.allocated_percentage||"",
                allocated_hours:project.allocated_hours|| "",
                project_id: project.id,
              })),
             
          
            ]
        );
        }
      
      
      let thisMonthtotalPercentage = 0;
      for (let i = 0; i < temparr1.length; i++) {
        thisMonthtotalPercentage =
          thisMonthtotalPercentage + temparr1[i].allocated_percentage;
      }
      setthisMonthtotalPercentage(thisMonthtotalPercentage);
      setThismonthlHour(res.data.data.thisMonthHour);
      setnextMonthHour(res.data.data.nextMonthHour);
      setfutureMonthHour(res.data.data.futureMonthHour);

      settemparr2([...temparr2])

   
      
      setnextMonthDetails(

          [
        
              ...projectList.map(project => ({
                project_name: project.name,
                allocated_percentage: project.allocated_percentage_next_month || "",
                allocated_hours: project.allocated_hours_next_month || "",
                project_id: project.id,

            })),
           
            
            ]
      );

      if (superAdmin ) {
        const defaultAllocation = {
          project_name: props.projectName,
          allocated_percentage: "", 
          allocated_hours: "", 
          project_id: props.id,
        };
      
        const project = Projects.find(item => item.project_id === props.projectId && item.financialmonth.month === projectMonth.NextMonth);
        
        if (project && project.allocated_percentage !== undefined && project.allocated_hours !== undefined) {

          setnextMonthDetails([
            {
              ...defaultAllocation,
              allocated_percentage: project.allocated_percentage,
              allocated_hours: project.allocated_hours,
            }
          ]);
        } else {
        
          setnextMonthDetails([defaultAllocation]);
        }
      }
      
       
      if (BuHead ) {

        setnextMonthDetails(
        
            [
              
                ...projectList.map(project => ({
                  project_name: project.name,
                  allocated_percentage: project.allocated_percentage_next_month || "",
                  allocated_hours: project.allocated_hours_next_month || "",
                  project_id: project.id,
              })),
            
              
              ]
        );
        }
      
    
      let nextMonthtotalPercentage = 0;
      for (let i = 0; i < temparr2.length; i++) {
        nextMonthtotalPercentage =
          nextMonthtotalPercentage + temparr2[i].allocated_percentage;
      }

      setnextMonthtotalPercentage(nextMonthtotalPercentage);

      settemparr3([...temparr3])

      setfutureMonthDetails(
       
          [
              
              ...projectList.map(project => ({
                project_name: project.name,
                allocated_percentage: project.allocated_percentage_future_month || "",
                allocated_hours: project.allocated_hours_future_month || "",
                project_id: project.id,
            })),
           
           
             
            ]

            
      );

      if (superAdmin) {
        const defaultAllocation = {
          project_name: props.projectName,
          allocated_percentage: "", 
          allocated_hours: "", 
          project_id: props.id,
        };
      
        const project = Projects.find(item => item.project_id === props.projectId && item.financialmonth.month === projectMonth.FutureMonth);
        
        if (project && project.allocated_percentage !== undefined && project.allocated_hours !== undefined) {
          
          setfutureMonthDetails([
            {
              ...defaultAllocation,
              allocated_percentage: project.allocated_percentage,
              allocated_hours: project.allocated_hours,
            }
          ]);
        } else {
          setfutureMonthDetails([defaultAllocation]);
        }
      }
      
      if (BuHead ) {

        setfutureMonthDetails(
       
            [
               
                ...projectList.map(project => ({
                  project_name: project.name,
                  allocated_percentage: project.allocated_percentage_future_month || "",
                  allocated_hours: project.allocated_hours_future_month || "",
                  project_id: project.id,
              })),
              
              
              ]
        );
        }
      
      let futureMonthtotalPercentage = 0;
      for (let i = 0; i < temparr3.length; i++) {
        futureMonthtotalPercentage =
          futureMonthtotalPercentage + temparr3[i].allocated_percentage;
      }
      setfutureMonthtotalPercentage(futureMonthtotalPercentage);
    });
  }
 
  const updateIdsAfterSave = () => {
    let emp_id = getEmpId;
   
    let user_id: any = localStorage.getItem("userId");
   
    AxiosConfig.get("allocations/talents", {
      headers: { Authorization: `Bearer ${token}` },
      params: { emp_id, month_id,user_id, project_id,superAdmin },
    }).then((res) => {
      let Projects = res?.data?.data?.TalentAllocations
        ? res?.data?.data?.TalentAllocations
        : [];
        const projectList = res?.data?.data?.projectList ? res?.data?.data?.projectList : [];
        
        const projectIdsByMonth: { project_id: number; month_id: number; id: number }[] = [];

   
        Projects.forEach(allocation => {
          const projectId = allocation.project_id;
          const monthId = allocation.financialmonth_id;
          const allocationId = allocation.id;
          projectIdsByMonth.push({ project_id: projectId, month_id: monthId, id: allocationId });
        });
  
        setTAllocationId(projectIdsByMonth);
        projectList.forEach(project => {
          const matchingData = Projects.find(item => item.project_id === project.id && item.financialmonth.month === projectMonth.ThisMonth);
          if (matchingData) {
            project.allocated_percentage = matchingData.allocated_percentage;
            project.allocated_hours = matchingData.allocated_hours;
          }
        });
  
  
        projectList.forEach(project => {
          const matchingDataNextMonth = Projects.find(item => item.project_id === project.id && item.financialmonth.month === projectMonth.NextMonth);
          if (matchingDataNextMonth) {
              project.allocated_percentage_next_month = matchingDataNextMonth.allocated_percentage;
              project.allocated_hours_next_month = matchingDataNextMonth.allocated_hours;
          }
      
          const matchingDataFutureMonth = Projects.find(item => item.project_id === project.id && item.financialmonth.month === projectMonth.FutureMonth);
          if (matchingDataFutureMonth) {
              project.allocated_percentage_future_month = matchingDataFutureMonth.allocated_percentage;
              project.allocated_hours_future_month = matchingDataFutureMonth.allocated_hours;
          }
      });

      if (Projects.length >= 0) {
        setTableData(false);
        setIsLoading(false);
      }
      const temparr1: any = [];
    
      const temparr2: any = [];
     
      const temparr3: any = [];
    


      Projects.forEach((row: any) => {
        if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.ThisMonth
        ) {
          setThisallocId(row.id);
        } else if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.NextMonth
        ) {
          setNextallocId(row.id);
        } else if (
          row.project_id == props.id &&
          row.financialmonth.month == projectMonth.FutureMonth
        ) {
          setFutureallocId(row.id);
        }
      });
      if (Projects.length > 0) {
        Projects.map((row: any) => {
          if (row?.project?.name) {
            if (row?.financialmonth?.month == projectMonth.ThisMonth) {
              temparr1.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                thisMonthAllocatedPercent.current = row?.allocated_percentage;
                setthisMonthtotalHour(row?.allocated_hours);
               
              }
            } else if (row?.financialmonth.month == projectMonth.NextMonth) {
              temparr2.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                nextMonthAllocatedPercent.current = row?.allocated_percentage;
                setnextMonthtotalHour(row?.allocated_hours);
                
              }
            } else if (row?.financialmonth?.month == projectMonth.FutureMonth) {
              temparr3.push({
                project_name: row?.project?.name,
                allocated_percentage: row?.allocated_percentage,
                allocated_hours: row?.allocated_hours,
                project_id: row?.project_id,
              });
              if (row?.project?.name === props.projectName) {
                futureMonthAllocatedPercent.current = row?.allocated_percentage;
                setfutureMonthtotalHour(row?.allocated_hours);
                
              }
            }
          }
        });
      }

      settemparr1([...temparr1])
      
     
      
      
      let thisMonthtotalPercentage = 0;
      for (let i = 0; i < temparr1.length; i++) {
        thisMonthtotalPercentage =
          thisMonthtotalPercentage + temparr1[i].allocated_percentage;
      }
      setthisMonthtotalPercentage(thisMonthtotalPercentage);
      setThismonthlHour(res.data.data.thisMonthHour);
      setnextMonthHour(res.data.data.nextMonthHour);
      setfutureMonthHour(res.data.data.futureMonthHour);

      settemparr2([...temparr2])
     

     
      
    
      let nextMonthtotalPercentage = 0;
      for (let i = 0; i < temparr2.length; i++) {
        nextMonthtotalPercentage =
          nextMonthtotalPercentage + temparr2[i].allocated_percentage;
      }

      setnextMonthtotalPercentage(nextMonthtotalPercentage);

      settemparr3([...temparr3])

    
      
      let futureMonthtotalPercentage = 0;
      for (let i = 0; i < temparr3.length; i++) {
        futureMonthtotalPercentage =
          futureMonthtotalPercentage + temparr3[i].allocated_percentage;
      }
      setfutureMonthtotalPercentage(futureMonthtotalPercentage);
  
       
        
      })
      .catch((error) => {
        console.error("Error fetching IDs:", error);
      });
  };


  return (
    <>


{showEditAllocation && (
  <EditAllocation
  projectDeptId={props.projectDeptId}
  projectOwners={props.projectOwners}
  managersParent={props.managersParent}
  visible={props.visible}
  onClose={props.onClose}
  onCancelButton={props.onCancelButton}
  projectName={props.projectName}
  projectId={props.projectId}
  projectCode={props.projectCode}
  id={props.id}
  talentData={talentData} 
  monthid={props.monthid}
  
/>
)}

      <Drawer
        title="Manage Talent"
        width={525}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="manage_talent"
        closable={true}
        maskClosable={true}
      >
        <Formik
          initialValues={{
            talent_id: "",
          }}
          onSubmit={(values) => {
            data1.append("talent_id", values.talent_id);
          }}
        >
          {({
            handleSubmit,

            errors,

            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div>
                  <label>{manageTalent.labelForTalentName} </label>
                </div>
                <div>
                  <label className="percent">{manageTalent.labelForpercent}</label>
                  <Switch
                    className="percent"
                    onChange={handleSwitchChange}
                    checked={Hour}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    width={36}
                    height={20}
                    handleDiameter={16}
                    onColor="#5389F8"
                    offColor="#BFCDDA"
                  />
                  <label className="percent">
                  {manageTalent.labelForHrs}
                  </label>
                </div>
              </div>

              <Select
                options={customerOptions}
                onChange={(event) => handleInputChange(event)}
                name="talent_id"
                placeholder="Select talent name"
                className="select--field"
              />
              <p className="display_error">{errors.talent_id}</p>
              {tableData === true ? (
                <>
                  <div className="edit_Loader" />
                  {isLoading === true ? <Loader className="edit_Loader" /> : ""}
                </>
              ) : (
                <>
                  <TableInfo
                    monthDetails={thisMonthDetails}
                    monthName={
                      String(projectMonth.ThisMonth).substring(0, 3) +
                      " " +
                      projectMonth.ThisMonthYear
                    }
                    projectName={props.projectName}
                    monthId={month_id}
                    projectId={props.id}
                    empId={getEmpId}
                    allocatedPercent={thisMonthAllocatedPercent.current}
                    allocatedHour={getthisMonthtotalHour}
                    totalpercentage={getthisMonthtotalPercentage}
                    TotalHour={getThismonthlHour}
                    Hour={Hour}
                    temparr1={temparr1} 
                    allocationId = {getThisallocId}
                    monthId1 = {props.monthId1}
                    TallocationId = {TallocationId}
                    updateIdsAfterSave = {updateIdsAfterSave}
                   
                  />
                  <TableInfo
                    monthDetails={nextMonthDetails}
                    monthName={
                      String(projectMonth.NextMonth).substring(0, 3) +
                      " " +
                      projectMonth.NextMonthYear
                    }
                    projectName={props.projectName}
                    monthId={month_id + 1}
                    projectId={props.id}
                    empId={getEmpId}
                    allocatedPercent={nextMonthAllocatedPercent.current}
                    allocatedHour={getnextMonthtotalHour}
                    totalpercentage={getnextMonthtotalPercentage}
                    TotalHour={getnextMonthHour}
                    Hour={Hour}
                    temparr2={temparr2} 
                    allocationId = {getNextallocId}
                    monthId2 = {props.monthId2}
                    TallocationId = {TallocationId}
                    updateIdsAfterSave = {updateIdsAfterSave}
                   
                  />
                  <TableInfo
                    monthDetails={futureMonthDetails}
                    monthName={
                      String(projectMonth.FutureMonth).substring(0, 3) +
                      " " +
                      projectMonth.FutureMonthYear
                    }
                    projectName={props.projectName}
                    monthId={month_id + 2}
                    projectId={props.id}
                    empId={getEmpId}
                    allocatedPercent={futureMonthAllocatedPercent.current}
                    allocatedHour={getfutureMonthtotalHour}
                    totalpercentage={getfutureMonthtotalPercentage}
                    TotalHour={getfutureMonthHour}
                    Hour={Hour}
                    temparr3={temparr3} 
                    allocationId = {getFutureallocId}
                    monthId3 = {props.monthId3}
                    TallocationId = {TallocationId}
                    updateIdsAfterSave = {updateIdsAfterSave}
                    
                  />
                  <div className="button-container">
                    <ButtonReuse
                      type="primary"
                      className="primary-btn cancel--btn"
                      value="Finish"
                      onClick={() => {
                        onCancelHandler();
                        resetForm();
                        window.location.reload();
                      }}
                    ></ButtonReuse>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
